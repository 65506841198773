
import Auth from '@aws-amplify/auth';
import {
  createTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';
import NavigationBar from './NavigationBar';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Explorer from './Explorer';
import Editor from './Editor';
import { Dashboard } from './dashboards/Dashboard';
import SeriesBuilder from './builders/SeriesBuilder';
import { useMediaQuery, useTheme } from '@mui/material';
import { Profile } from './Profile';
import SeriesDetailPage from './SeriesDetailPage';
import { connect } from 'react-redux'
import ExploreMore from './ExploreMore';
import CredentialDrawer from './CredentialDrawer';
import Subscriber from './Subscriber';
import { useEffect, useState } from 'react';
import CampaignBuilder from './builders/CampaignBuilder';
import PrivacyPolicy from './PrivacyPolicy';
import Settings from './Settings';
import LandingPage from './LandingPage';
import IntegrationHome from './public-api-tutorials/IntegrationHome';

export function Home({ user }) {

  const isLoggedIn = (null !== user.userId);
  
  // Calculate dynamic AppBar height
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();

  const [currentToolbarMinHeight, setCurrentToolbarMinHeight] = useState(null)
  const toolbarDesktopQuery = breakpoints.up('sm');
  const toolbarLandscapeQuery = `${breakpoints.up('xs')} and (orientation: landscape)`;
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);

  useEffect(() => {
    let currentToolbarMinHeight;
    if (isDesktop) {
      currentToolbarMinHeight = toolbar[toolbarDesktopQuery];
    } else if (isLandscape) {
      currentToolbarMinHeight = toolbar[toolbarLandscapeQuery];
    } else {
      currentToolbarMinHeight = toolbar;
    }
    setCurrentToolbarMinHeight(currentToolbarMinHeight)
  }, [isDesktop, isLandscape]); 

  const appBarHeight = currentToolbarMinHeight && currentToolbarMinHeight.minHeight;

  const signOut = async () => {
    await Auth.signOut();
  }

  const createAppTheme = () => {
    return createTheme({
      palette: {
        primary: {
          main: "#819EF0"
        },
        secondary: {
          main: "#819EF0"
        }
      },
      components: {
        MuiTypography: {
          defaultProps: {
            fontFamily: 'M PLUS Rounded 1c',
          }
        },
      },
      overrides: {
          // Style sheet name ⚛️
          MuiButton: {
            // Name of the rule
            text: {
              // Some CSS
              background: 'linear-gradient(45deg, #FDB750 30%, #FD7F20 90%)',
              border: 0,
              color: 'white',
              height: 50,
              padding: '0 15px',
              boxShadow: '0 3px 5px 2px rgba(0,0,0,0.3)',
              fontFamily: "Open Sans",
              fontWeight: "bold"
            },
          },
          MuiAppBar: {
            colorPrimary: {
              background: "linear-gradient(45deg, #FD7F20 30%, #FDB750 90%)"
            },
          },
          MuiCard: {
            root: {
              boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
              "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
              }
            }
          },
          MuiTypography: {
            h3: {
              fontWeight: "bold"
            },
            h5: {
              fontWeight: "bold"
            },
            h6: {
              fontWeight: "bold"
            },
            body2:{
              fontWeight: "bold",
              fontSize: 8
            }
          },
          MuiOutlinedInput: {
            input: {
              '&:-webkit-autofill': {
                WebkitBackgroundClip: 'text'
              },
            },
          }
        },
    });
  }

  return (
    <MuiThemeProvider theme={createAppTheme()}> 
      <BrowserRouter>
        <NavigationBar signOut={signOut} isLoggedIn={isLoggedIn} user={user}/> 
        <Routes>
          <Route
            path="*"
            element={<Navigate to="/explorer"/>}
          />
          <Route exact path="" element={<LandingPage user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/explorer" element={<Explorer appBarHeight={appBarHeight}/>}/>
          <Route exact path="/explorer/" element={<Explorer appBarHeight={appBarHeight}/>}/> // For google login redirect
          <Route exact path="/explorer/:category" element={<ExploreMore appBarHeight={appBarHeight}/>}/>
          <Route exact path="/editor" element={<Editor user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/series-builder" element={<SeriesBuilder user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/dashboard" element={<Dashboard user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/profile" element={<Profile user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/series/:id" element={<SeriesDetailPage user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/subscription-plan" element={<Subscriber user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/campaign-builder" element={<CampaignBuilder user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route exact path="/settings" element={<Settings user={user} appBarHeight={appBarHeight}/>}/>
          <Route exact path="/public-api/tutorials" element={<IntegrationHome user={user} appBarHeight={appBarHeight} isLoggedIn={isLoggedIn}/>}/>
        </Routes>
        <CredentialDrawer/>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

const mapStateToProps = state => {
  return { user: state.user }
}

export default connect(mapStateToProps)(Home)
