import { useEffect, useState, useRef} from "react"
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Card } from '@aws-amplify/ui-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SeriesDetailCard from "./SeriesDetailDialog";
import LoadingIndicator from "./LoadingIndicator";
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import ProgressiveImage from "./ProgressiveImage";
import {isMobile} from 'react-device-detect';
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    cardSelect: {
    borderRadius: "12px",
      height: 400,
      width: 300,
      marginTop: "16px",
      marginBottom: "20px",
      borderColor: "#979797",
      transition: "transform 0.15s ease-in-out",
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)",
      "&:hover": { 
        borderColor: "#000",
        transform: "scale3d(1.08, 1.08, 1)"
      }
    },
    colorIconAdd: {
        borderRadius: "14px", 
        color: "#000",
        backgroundColor: "#819EF0"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: "#fff",
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        '&::-webkit-scrollbar': {
            width: "12px"
          },
          '&::-webkit-scrollbar-track':{
            borderRadius: "4px",
            marginLeft: "42px",
            marginRight: "42px"
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#819EF0",
            boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "12px",
            border: "3px white solid"
          },
          /* Opera doesn't support this in the shorthand */
          backgroundAttachment: "local, local, scroll, scroll" 
    },
    title: {
        color: theme.palette.primary.light,
    }
  }));

const ExploreMore = (props) => {
    const { user, appBarHeight } = props
    const classes = useStyles()
    const [seriesCardState, setSeriesCardState] = useState(false)
    const [selectedSeries, setSelectedSeries] = useState(null)
    const [loadingExploreMorePage, setLoadingExploreMorePage] = useState(false)
    const [paginationStart, setPaginationStart] = useState(0)
    const [searchedSeries, setSearchedSeries] = useState(null)
    const [paginate, setPaginate] = useState(false);
    const [stopPagination, setStopPagination] = useState(false);
    const navigate = useNavigate()
    const { category } = useParams();

    const openSeriesDetailPage = (series) => {
        const { seriesId, seriesid } = series
        const id = seriesId ? seriesId : seriesid
        navigate(`/series/${id}`)
    }

    //Need to call getSeries endpoint and do pagination calculation
    useEffect(async () => {
        try {
            setLoadingExploreMorePage(true)
            await searchCategory()
            setLoadingExploreMorePage(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingExploreMorePage(false)
        }
    }, []);

    const searchCategory = async () => {
        const numberOfSeries = isMobile ? 5 : 10
        //paginted calls don't call this function with search term, so we used the save searchValue in the following lines
        const allSeriesResult = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-all-series?categoryId==${category}&start=${paginationStart}&numberOfSeries=${numberOfSeries}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        const { data: { searchedSeries: allSeries, found }} = allSeriesResult

        if(searchedSeries) {
            setSearchedSeries(prevSearchedResults => [...prevSearchedResults, ...allSeries])
        } else {
            setSearchedSeries(allSeries)
        }
        
        if(paginationStart+numberOfSeries < found) { // if there are less hits left than the number we search for we should end pagination
            setPaginationStart(paginationStart+numberOfSeries)
        } else {
            setStopPagination(true)
        }
        setPaginate(false)
    }

    const openSeriesDetailDialog = (series) => {
        setSelectedSeries(series)
        setSeriesCardState(true)
    }

    const closeSeriesDetailDialog = () => {
        setSelectedSeries(null)
        setSeriesCardState(false)
    }

    const scrollHandler = (e) => {
        if(searchedSeries && !stopPagination) {
            if(e.target.clientHeight === (e.target.scrollHeight - Math.round(e.target.scrollTop))) {
                setPaginate(true)
            }
        }
    }

    useEffect(() => {
        if(paginate) {
            searchCategory()
        }
    },[paginate])

    return (
        <Box onScroll={scrollHandler} className={classes.gridList} style={{height: `calc(100vh - ${appBarHeight}px)`, overflowY: "auto", overflowX: "hidden", display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
            <Box>
            {selectedSeries && <SeriesDetailCard 
                user={user}
                close={closeSeriesDetailDialog}
                seriesCardState={seriesCardState}
                series={selectedSeries}
                openSeriesDetailPage={openSeriesDetailPage}
            />}
            { loadingExploreMorePage ? 
                <Box style={{
                    background: "#fff",
                    paddingTop: "12px",
                    alignItems: "center",
                    height: `calc(100vh - ${appBarHeight}px)`,
                    justifyContent : "center", 
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <LoadingIndicator loading={loadingExploreMorePage}/>
                </Box> :
                <Grid 
                    spacing={2}
                    container
                    className={classes.container} 
                    style={{
                        padding: "24px",
                        marginBottom: "32px",
                        marginTop: "16px",
                        width: "100%",
                        height: "100%",
                        background: "#fff",
                        paddingTop: "4px",
                        paddingBottom: "16px",
                        justifyContent: "start",  
                        flexDirection: "row",
                        display: "flex",
                        overflowY: "auto"       
                    }}>
                    <Grid item xs={12}>
                        <Typography style={{textAlign:"center", fontWeight: "bold", fontSize: "36px"}}>{category}</Typography>
                    </Grid>
                    {searchedSeries && <SearchedResults searchedSeries={searchedSeries} openSeriesDetailDialog={openSeriesDetailDialog}/>}
                </Grid>
            }
            </Box>
            <Footer/>
        </Box>
    )
}

function SearchedResults(props) {
    const { searchedSeries, openSeriesDetailDialog } = props
    const classes = useStyles()
    const seriesCard = [classes.cardSelect] 
    
    const mappedSeries = searchedSeries.map((series) => {
        const { fields } = series
        const seriesObject ={}
        for (const [key, value] of Object.entries(fields)) {
            if(key === "backgroundcolors" ){
                seriesObject[key] = value
            } else {
                seriesObject[key] = value[0]
            }
        }
        return seriesObject;
    })

    return mappedSeries.map((series) => {
        const { imageurl, seriesid, seriesname, description } = series
        const cardStyleOverride = { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #fff 30%, #819EF0 90%)'}
        const shorthandSeriesName = seriesname.length > 25 ? seriesname.substring(0,25) + "..." : seriesname
        const shorthandSeriesDescription = description.length > 60 ? description.substring(0,60)+ "..." : description
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={seriesid} >
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center"}}>
                    <Card className={seriesCard} style={{...cardStyleOverride, margin: "12px", padding: "0px"}} onClick={() => openSeriesDetailDialog(series)}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                            <div style={{borderRadius: "12px", overflow: "hidden"}}>
                                <ProgressiveImage
                                    width="300px"
                                    height="300px"
                                    src={imageurl}
                                />
                            </div>
                            <Typography component="div" style={{ fontWeight: "bold", fontSize: "20px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                {shorthandSeriesName}
                            </Typography>
                            <Typography component="div" style={{fontSize: "16px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                {shorthandSeriesDescription}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        )
    })
}

  const mapStateToProps = state => {
    return { 
        user: state.user
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ExploreMore)