import { Grid, Box, Card, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SquareSpace1 from '../../images/tutorials/squarespace/square-space-1.png';
import SquareSpace2 from '../../images/tutorials/squarespace/square-space-2.png';
import SquareSpace3 from '../../images/tutorials/squarespace/square-space-3.png';
import SquareSpace4 from '../../images/tutorials/squarespace/square-space-4.png';
import SquareSpace5 from '../../images/tutorials/squarespace/square-space-5.png';
import SquareSpace6 from '../../images/tutorials/squarespace/square-space-6.png';
import SquareSpace7 from '../../images/tutorials/squarespace/square-space-7.png';
import SquareSpace8 from '../../images/tutorials/squarespace/square-space-8.png';
import SquareSpace9 from '../../images/tutorials/squarespace/square-space-9.png';
import SquareSpace10 from '../../images/tutorials/squarespace/square-space-10.png';
import SquareSpace11 from '../../images/tutorials/squarespace/square-space-11.png';
import SquareSpace12 from '../../images/tutorials/squarespace/square-space-12.png';
import SquareSpace13 from '../../images/tutorials/squarespace/square-space-13.png';
import SquareSpace14 from '../../images/tutorials/squarespace/square-space-14.png';
import SquareSpace15 from '../../images/tutorials/squarespace/square-space-15.png';
import SquareSpace16 from '../../images/tutorials/squarespace/square-space-16.png';
import SquareSpace17 from '../../images/tutorials/squarespace/square-space-17.png';
import SquareSpace18 from '../../images/tutorials/squarespace/square-space-18.png';
import SquareSpace19 from '../../images/tutorials/squarespace/square-space-19.png';
import SquareSpace20 from '../../images/tutorials/squarespace/square-space-20.png';
import SquareSpace21 from '../../images/tutorials/squarespace/square-space-21.png';
import SquareSpace22 from '../../images/tutorials/squarespace/square-space-22.png';
import SquareSpace23 from '../../images/tutorials/squarespace/square-space-23.png';
import Footer from '../Footer';
import SEO from '../SEO';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "75%", 
      justifyContent: "center",
      alignItems: "center",
      "&:hover": { 
          borderColor: "#000",
          transform: "scale3d(1, 1, 1)"
      },
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#819EF0",
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "4px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

export const SquareSpaceTutorial = ({ user, isLoggedIn, appBarHeight, dispatch}) => {
    const classes = useStyles()
    const seriesCard = classes.cardSelect
    return (
        <Grid container style={{ overflowX: "hidden",  display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
            <Grid container style={{background: 'linear-gradient(180deg, #fff 10%, #819EF0 90%)', padding: "0px 48px 24px 48px", backgroundColor: "#fff",flexDirection:"row", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                <Grid item xs={12} style={{marginTop: "48px", display: "flex", justifyContent: "flex-start"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "24px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>This tutorial explains step by step how to connect your Squarspace webpage with your Pikkaboo account. This allows your users to easily subscribe to your newsletter series from your own website.</Typography><br/>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{marginTop: "24px", display: "flex", justifyContent: "flex-start"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                        <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "28px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>Step 1</Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>Click the `ADD BLOCK` button on one of your squarespace pages that you're editing.</Typography><br/>
                    </Box>
                </Grid>
                {getImageSection(seriesCard, SquareSpace1, "375px")}

                {getTextSection("Step 2", "Select the Newsletter option.")}
                {getImageSection(seriesCard, SquareSpace2, "500px")}

                {getTextSection("Step 3", "Select the edit button in the toolbar.")}
                {getImageSection(seriesCard, SquareSpace3, "500px")}

                {getTextSection("Step 4", "On the Content tab, give your form a name. You'll need this later when hooking things up in Zapier. In this example we named our form `Newsletter Form`")}
                {getImageSection(seriesCard, SquareSpace4, "500px")}

                {getTextSection("Step 5", "Navigate to the Storage tab and select `Other Storage Options`. A Zapier option will be present, click CONNECT and you should see `Awaiting Connection`")}
                {getImageSection(seriesCard, SquareSpace5, "500px")}

                {getTextSection("Step 6", "If you don't already have a Zapier account, go to zapier.com and create one using the sign-up sheet. Once you are logged in, hover over the left navigation bar and select `Create Zap`")}
                {getImageSection(seriesCard, SquareSpace6, "250px")}

                {getTextSection("Step 7", "You'll see a screen similar to this one. Select the Trigger box.")}
                {getImageSection(seriesCard, SquareSpace7, "750px")}

                {getTextSection("Step 8", "Search for Squarespace and select the option that pops up.")}
                {getImageSection(seriesCard, SquareSpace8, "750px")}

                {getTextSection("Step 9", "You'll be presented with a few configuration options. The first will be `App & event`. For this option we'll select `New Form Submission`.")}
                {getImageSection(seriesCard, SquareSpace9, "500px")}

                {getTextSection("Step 10", "The next configuration will be `Account`. Here you can connect or change your Squarespace account. Enter your credentials and connect your account.")}
                {getImageSection(seriesCard, SquareSpace10, "500px")}

                {getTextSection("Step 11", "The third configuration will be `Trigger`. Here we can select the newsletter form we created in Steps 2-4. Search for your form in the input box and select it.")}
                {getImageSection(seriesCard, SquareSpace11, "500px")}


                {getTextSection("Step 12", "The final step is to test your Squarespace connection. If successful you should see something the following result. Select `Continue with selected record` to wrap up this task.")}
                {getImageSection(seriesCard, SquareSpace12, "500px")}


                {getTextSection("Step 13", "You'll be redirected to this screen again. Next we'll set up the action to be performed once a user submits their email on your Squarespace site.")}
                {getImageSection(seriesCard, SquareSpace13, "500px")}


                {getTextSection("Step 14", "In the search bar, search for Pikkaboo and select it to continue.")}
                {getImageSection(seriesCard, SquareSpace14, "500px")}


                {getTextSection("Step 15", "There should be a dropdown list where you can pick the event that needs to occur. For this integration we'll select `Subscribe User to Email Series`.")}
                {getImageSection(seriesCard, SquareSpace15, "500px")}

                {getTextSection("Step 16", "Before we proceed go to your Pikkaboo acount and note down your secrete key. You'll be able to find this in the settings page if you're subscribed as a premium user.")}
                {getImageSection(seriesCard, SquareSpace17, "750px")}
                

                {getTextSection("Step 17", "We also need to note down the series id. To find this go to your series detail page on mypikkaboo.com and grab the last part of the url in the search bar (056193c5-6b0e-46a1-95b8-b9936e708947)")}
                {getImageSection(seriesCard, SquareSpace18, "500px")}

                {getTextSection("Step 18", "Going back to our Zapier integration. Add/Change your Pikkaboo account you'll be prompted to add the details we noted down earlier. Enter any email for the email field, this is only needed for testing.")}
                {getImageSection(seriesCard, SquareSpace16, "500px")}

                {getTextSection("Step 19", "In the next screen select `Email Address` for the `Email` field. Also enter your `Series ID` and `API Key` you noted earlier. Select continue once you are finished.")}
                {getImageSection(seriesCard, SquareSpace20, "500px")}

                {getTextSection("Step 20", "You'll then be able to test your integration by clicking the test button. If succesful you'll see the following screen. Select the publish button to finish.")}
                {getImageSection(seriesCard, SquareSpace19, "500px")}

                {getTextSection("Step 21", "Now when you head to your `Zaps` home page, you'll be able to activate your integration by selecting `On`.")}
                {getImageSection(seriesCard, SquareSpace22, "1000px")}

                {getTextSection("Step 22", "When you check your squarspace page you're Zapier connection will show up as connected. You can now accept email subscriptions through your squarspace webpage, and manage all of your email content on Pikkaboo!")}
                {getImageSection(seriesCard, SquareSpace23, "750px")}

            </Grid>
        
    </Grid>
    )
  }

  const getImageSection = (seriesCardClass, imageSrc, maximumWidth) => {
    return (
        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start"}}>
            <Card className={seriesCardClass} style={{borderRadius: "8px", maxWidth: maximumWidth}}>
                <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                    <img src={imageSrc} width="100%" height="100%"   />
                </CardContent>
            </Card>
        </Grid>
    )
  }

  const getTextSection = (stepText, descriptionText) => {
    return (
        <Grid item xs={12} style={{marginTop: "48px", display: "flex", justifyContent: "flex-start"}}>
            <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "28px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>{stepText}</Typography><br/>
                <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>{descriptionText}</Typography><br/>
            </Box>
        </Grid>
    )
  }

  const mapStateToProps = state => {
    return { 
        user: state.user
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SquareSpaceTutorial)