/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:731a39f7-a6f1-4e1f-af70-215be923c4f8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_E0RDPdDtV",
    "aws_user_pools_web_client_id": "4g2t1gsn8tnspvs0ega8g3jgqm",
    "oauth": {
        "domain": "mypikkaboo.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.mypikkaboo.com/explorer/",
        "redirectSignOut": "https://www.mypikkaboo.com/explorer/",
        "responseType": "code",
        "client_id": "511315590964-pp8ahupurmm28tbq0p1gsioc0tao2d0v.apps.googleusercontent.com",
    },
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
