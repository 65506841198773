import * as React from 'react';
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { TextAreaField } from '@aws-amplify/ui-react';
import LoadingIndicator from './LoadingIndicator';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "12px",
          margin: "12px"
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: ({activeColor})=> activeColor,
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "12px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll",
        color: "#000",
        borderRadius: "20px",
        borderColor: "#F5F5F5",
        borderRadius: "20px",
        boxShadow: '-2px 2px 8px 1px #819EF0',
    },
  })); 

export function SeriesDescriptionInputBox({seriesDescriptionRef, activeColor, onChangeDescription, description, loadingSeriesManager, overrideHeight}) {
  const classes = useStyles({activeColor})
  const { container, inputColor } = classes
  return (
    <Box className={container} style={{marginTop: "20px", height: overrideHeight || 156, justifyContent: "center", alignContent:"center", display: 'flex', background: "#fff" }}>
     { loadingSeriesManager ? <LoadingIndicator loading={loadingSeriesManager}/> : 
      <TextareaAutosize
        ref={seriesDescriptionRef}
        onChange={onChangeDescription}
        value={description}
        className={container} 
        maxRows="infinite"
        style={{width: "100%", padding: "8px 16px 8px 16px", borderRadius: "20px", height: overrideHeight || 156, backgroundColor: "#fff", resize: "none"}}
        variant="outlined"
        size="small"
      />
    }
    </Box>
    );
  }