import { useEffect, useState} from "react"
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import { Card } from '@aws-amplify/ui-react';
import axios from 'axios';
import ImageListItem from '@material-ui/core/ImageListItem';
import LoadingIndicator from "../LoadingIndicator";
import MetricsGraph from "../MetricsGraph";
import SeriesStatsWidget from "../SeriesStatsWidget";
import moment from "moment";
import { List, ListItemText, styled, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box, Grid, InputLabel, MenuItem, Paper, Typography } from "@material-ui/core";
import ProgressiveImage from "../ProgressiveImage";
import TimelineIcon from '@mui/icons-material/Timeline';
import Footer from "../Footer";
import SEO from "../SEO";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import CampaignIcon from '@mui/icons-material/Campaign';


const useStyles = makeStyles((theme) => ({
    cardSelect: {
        borderRadius: "12px",
        height: 250,
        width: 200,
        marginTop: "12px",
        marginBottom: "20px",
        borderColor: "#979797",
        transform: "scale(0.9)",
        transition: "transform 0.15s ease-in-out",
        boxShadow: "0 8px 8px rgba(0,0,0,0.25)",
        "&:hover": { 
            borderColor: "#000",
            transform: "scale3d(1.00, 1.00, 1.00)"
        }
        },
        gridList: {
        '&::-webkit-scrollbar': {
            width: "12px",
            height: "12px"
            },
            '&::-webkit-scrollbar-track':{
            borderRadius: "4px",
            marginLeft: 36,
            marginRight: 36
            },
            '&::-webkit-scrollbar-thumb': {
            backgroundColor: ({activeColor, selectedMetric})=> selectedMetric === "0" ? activeColor : "#819EF0",
            boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "12px",
            border: "3px white solid",
            marginLeft: "12px",
            marginRight: "12px"
            },
            /* Opera doesn't support this in the shorthand */
            backgroundAttachment: "local, local, scroll, scroll" 
        },
        selectOptions: {
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#fff",
              color: "#000",
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F5F5F5",
              borderRadius: "10px",
              boxShadow: '-2px 2px 8px 1px #819EF0',
            },
            "&:hover .MuiOutlinedInput-input": {
              color: "#000"
            },
            "&:hover .MuiInputLabel-root": {
              color: "#000"
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F5F5F5"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
              color: "#000"
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#F5F5F5",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F5F5F5"
            },
            '& .MuiMenu-paper': {
                borderRadius: '20px',
              },
          },
        container: {
            '&::-webkit-scrollbar': {
            width: "12px"
            },
            '&::-webkit-scrollbar-track':{
            borderRadius: "4px",
            },
            '&::-webkit-scrollbar-thumb': {
            backgroundColor: ({activeColor, selectedMetric})=> selectedMetric === "0" ? activeColor : "#819EF0",
            boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "4px",
            border: "3px white solid"
            },
            /* Opera doesn't support this in the shorthand */
            backgroundAttachment: "local, local, scroll, scroll" 
        }
}))

const CarouselContent = (props) => {
  const classes = useStyles()
  const seriesCard = [classes.cardSelect]
  const { handleSeriesClick, items, selectedSeries } = props
  return items.map((seriesItem, index) => {
      const firstMargin = index === 0 ? { paddingLeft: "16px" } : { paddingLeft: "0px" }
      const lastMargin = index === items.length-1 ? { paddingRight: "16px" } : { paddingRight: "0px" }
      const { imageUrl, seriesId, seriesName, backgroundColors } = seriesItem
      const cardStyleOverride = { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #fff 30%, #819EF0 90%)'}
      const selectedBorder = selectedSeries && selectedSeries.seriesId === seriesId && {borderWidth: "3px", borderColor: "#000"} 
      const shorthandSeriesName = seriesName.length > 25 ? seriesName.substring(0,25) + "..." : seriesName
      return ( 
            <ImageListItem key={seriesId} style={{...firstMargin, ...lastMargin, height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex", flexDirection: "column"}} >
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center"}}>
                    <Card className={seriesCard} style={{...selectedBorder, ...cardStyleOverride, margin: "12px", padding: "0px"}} onClick={() => handleSeriesClick(seriesItem)}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                                <div style={{borderRadius: "12px", overflow: "hidden"}}>
                                    <ProgressiveImage
                                        width="100%"
                                        height="200px"
                                        src={imageUrl}
                                    />
                                </div>
                            <Typography component="div" style={{fontWeight: "bold", fontSize: "14px", width: "200px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", justifyContent: "start"}}>
                                {shorthandSeriesName}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </ImageListItem>
            )
          })
}

const getGraphDataNewsletters = (dailySubscribe, dailyUnsubscribe, seenSeries, daysInPast) => {
    const data = []
    for (var i = daysInPast; i >= 0; i--) {
        const daysInMs = i * 24 * 60 * 60 * 1000;
        const date = new Date().getTime() - daysInMs;
        const formattedDate = moment(date).format("MMM Do");
        const dataPoint = {
            name: `${formattedDate}`,
            "Total Subscribed": dailySubscribe[formattedDate] || 0,
            "Total Unsubscribed": dailyUnsubscribe[formattedDate] || 0,
            "Total Views": seenSeries[formattedDate] || 0,
        }
        data.push(dataPoint)
    } 
    return data;
} 

const getGraphDataCampaigns = (seenCampaigns, daysInPast) => {
    const data = []
    for (var i = daysInPast; i >= 0; i--) {
        const daysInMs = i * 24 * 60 * 60 * 1000;
        const date = new Date().getTime() - daysInMs;
        const formattedDate = moment(date).format("MMM Do");
        const dataPoint = {
            name: `${formattedDate}`,
            "Total Views": seenCampaigns[formattedDate] || 0,
        }
        data.push(dataPoint)
    } 
    return data;
} 

const ColorToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#fff",
        backgroundColor: '#819EF0'
    }
});

const getSelectedMetricButtons = (selectedMetric, handleMetricChange) => {
    return (
        <ToggleButtonGroup
            value={selectedMetric}
            exclusive
            onChange={handleMetricChange}
            style={{
            height: "58px",
            marginTop: "16px", 
            justifyContent: "center",
            borderColor: "#F5F5F5",
            borderRadius: "12px",
            boxShadow: '-2px 2px 8px 1px #819EF0',
            }}
            >
            <ColorToggleButton style={{width: "100%", fontSize: "14px", borderRadius: "12px 0px 0px 12px"}} value="0">Newsletters</ColorToggleButton>
            <ColorToggleButton style={{width: "100%", fontSize: "14px", borderRadius: "0px 12px 12px 0px"}} value="1">Campaigns</ColorToggleButton>
        </ToggleButtonGroup>
      );
}

export const Dashboard = ({ user, appBarHeight, isLoggedIn }) => {
      const [loadingMyCampaigns, setLoadingMyCampaigns] = useState(false)
      const [loadingMySeries, setLoadingMySeries] = useState(false)
      const [loadingMyCampaignData, setLoadingMyCampaignData] = useState(false)
      const [seenCampaigns, setSeenCampaigns] = useState(null)
      const [uniqueSeenCampaigns, setUniqueSeenCampaigns] = useState(0)
      const [allSeries, setAllSeries] = useState(null)
      const [allCampaigns, setAllCampaigns] = useState(null)
      const [dailySubscribe, setDailySubscribes] = useState(null)
      const [dailyUnsubscribe, setDailyUnSubscribe] = useState(null)
      const [loadingMySubscriptionsData, setLoadingMySubscriptionsData] = useState(false)
      const [selectedSeries, setSelectedSeries] = useState(null)
      const [selectedCampaign, setSelectedCampaign] = useState(null)
      const [activeSubscriptionsCount, setActiveSubscriptionsCount] = useState(0)
      const [seenSeries, setSeenSeries] = useState(null)
      const [seenSeriesTodayCount, setSeenSeriesTodayCount] = useState(0)
      const [seenCampaignsTodayCount, setSeenCampaignsTodayCount] = useState(0)
      const [dailySubscriptionsDays, setDailySubscriptionsDays] = useState(30)
      const [dailyCampaignSeenDays, setDailyCampaignSeenDays] = useState(30)
      const [activeColor, setActiveColor] = useState("#819EF0")
      const [selectedMetric, setSelectedMetric] = useState("0");

      const handleMetricChange = (event, newMetric) => {
        if(!newMetric) return
        setSelectedMetric(newMetric);
      };

      const classes = useStyles({activeColor, selectedMetric})

      useEffect(async () => {
        try { 
            if(!isLoggedIn) {
                return
            }
            const { userId } = user
            const body = {
                userId
            }
            setLoadingMyCampaigns(true)
            const allSeriesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-campaigns-by-user', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            const { data: { allCampaigns: allUserCampaigns, activeCampaign }} = allSeriesResult
            setAllCampaigns(allUserCampaigns)
            setSelectedCampaign(activeCampaign)
            setLoadingMyCampaigns(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingMyCampaigns(false)
        }
    }, [isLoggedIn]);

    useEffect(async () => {
        try { 
            if(!isLoggedIn) {
                return
            }
            const { userId } = user
            const body = {
                userId
            }
            setLoadingMySeries(true)
            const allCampaingsResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-series-by-user', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            const { data: { allSeries: allUserSeries, activeSeries }} = allCampaingsResult
            setAllSeries(allUserSeries)
            setSelectedSeries(activeSeries)
            setLoadingMySeries(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingMySeries(false)
        }
    }, [isLoggedIn]);

    useEffect(async () => {
      if(!selectedSeries) {
        return
      }
      try { 
          setLoadingMySubscriptionsData(true)
          const { seriesId, backgroundColors } = selectedSeries
          const body = {
            seriesId,
            days: 30
          }
          const subscriptionResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-subscriptions-by-series', body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
          })
          const { data: { 
                    dailySubscribe: subscribeDataMap,
                    dailyUnsubscribe: unsubscribeDataMap,
                    activeSubscriptionsCount,
                    seenSeries
                    }
            } = subscriptionResult
          setDailySubscribes(subscribeDataMap)
          setDailyUnSubscribe(unsubscribeDataMap)
          setActiveSubscriptionsCount(activeSubscriptionsCount)
          setSeenSeries(seenSeries)
          setActiveColor(`#819EF0`)
          setLoadingMySubscriptionsData(false)
      } catch (e) {
          // show alert dialog that failed 
          console.log(e)
          setLoadingMySubscriptionsData(false)
      }
    }, [selectedSeries]);

    useEffect(async () => {
        if(!selectedCampaign) {
          return
        }
        try { 
            setLoadingMyCampaignData(true)
            const { campaignId } = selectedCampaign
            const body = {
              campaignId,
              days: 30
            }
            const subscriptionResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-campaign-data', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            const { data: { seenCampaigns, uniqueSeenCampaign } } = subscriptionResult
            setSeenCampaigns(seenCampaigns)
            setUniqueSeenCampaigns(uniqueSeenCampaign)
            setLoadingMyCampaignData(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingMyCampaignData(false)
        }
      }, [selectedCampaign]);

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            if(window.innerWidth < 500) {
                setDailySubscriptionsDays(5)
                setDailyCampaignSeenDays(5)
            } else  if(window.innerWidth < 750) {
                setDailySubscriptionsDays(15)
                setDailyCampaignSeenDays(15)
            } else if (window.innerWidth < 950) {
                setDailySubscriptionsDays(30)
                setDailyCampaignSeenDays(30)
                return
            } else if (window.innerWidth < 1250) {
                setDailySubscriptionsDays(15)
                setDailyCampaignSeenDays(15)
                return
            } else {
                setDailySubscriptionsDays(20)
                setDailyCampaignSeenDays(20)
                return
            } 
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSeriesClick = (series) => {
        setSelectedSeries(series)
    };

    useEffect(async () => {
        if(!seenSeries) {
          return
        }
        try {
            const date = new Date().getTime()
            const formattedDate = moment(date).format("MMM Do")
            setSeenSeriesTodayCount(seenSeries[formattedDate] || 0)
        } catch (e) {
            console.log(e)
        }
      }, [seenSeries]);

      useEffect(async () => {
        if(!seenCampaigns) {
          return
        }
        try {
            const date = new Date().getTime()
            const formattedDate = moment(date).format("MMM Do")
            setSeenCampaignsTodayCount(seenCampaigns[formattedDate] || 0)
        } catch (e) {
            console.log(e)
        }
      }, [seenCampaigns]);

    const subscriptionBars = [
        {
            key: "Total Subscribed",
            fill: "#7FEB45"
        },
        {
            key: "Total Unsubscribed",
            fill: "#FF7F7F"
        }
    ]
    
    const totalViewsBars = [
        {
            key: "Total Views",
            fill: "#0096FF"
        }
    ]

    const onChangeCampaign = (e) => {
        const selectedCampaign = allCampaigns.find(campaign => campaign.campaignId === e.target.value)
        setSelectedCampaign(selectedCampaign)
    }

    const getItems = (campaigns) => {
        return campaigns.map((campaign, index) => {
          return <MenuItem key={campaign.campaignId} style={{color: "#000" }} value={campaign.campaignId}>{campaign.campaignName}</MenuItem>
        })
      }

      const getEmailList = (selectedCampaign) => {
        return selectedCampaign && selectedCampaign.emails && selectedCampaign.emails.map((email, index) => {
          return <ListItemText key={index} style={{color: "#000" }}>{email}</ListItemText>
        })
      }

    const newsletterData = dailySubscribe && dailyUnsubscribe &&  seenSeries && getGraphDataNewsletters(dailySubscribe, dailyUnsubscribe, seenSeries, dailySubscriptionsDays)
    const campaignData = seenCampaigns && getGraphDataCampaigns(seenCampaigns, dailyCampaignSeenDays)
    const items = allCampaigns ? getItems(allCampaigns) : {}
    const emailList = selectedCampaign ? getEmailList(selectedCampaign) : []

    const selectedMetricButtonGroup = getSelectedMetricButtons(selectedMetric, handleMetricChange)
    return (
      <Box className={classes.gridList} style={{height: `calc(100vh - ${appBarHeight}px)`, overflowX: "hidden", overflowY: "auto", display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
        <SEO
            title={`Pikkaboo's Dashboard: Monitor Your Newsletter Metrics and Subscription Counts in Real-Time`}
            description={`Welcome to Pikkaboo's Dashboard - your one-stop-shop for monitoring the success of your newsletter campaigns. With real-time insights into your email metrics and subscription counts, you can make informed decisions and optimize your strategy for maximum impact. Keep an eye on important metrics such as open, and conversion rates, and see how your campaigns are performing over time. Stay on top of your subscriber growth and engagement with easy-to-use visualizations and reports. Whether you're a seasoned marketer or just starting out, Pikkaboo's Dashboard provides you with the data you need to succeed in the creator economy.`}
            name={`Dashboard`}
            type={`article`}
        />
        <Box style={{marginTop: "20px", display: "flex", justifyContent: "center", width: "100%", padding: "12px 5% 12px 5%"}}>
            {selectedMetricButtonGroup}
        </Box>
        { selectedMetric === "0" ? 
            loadingMySeries ? 
            <Box style={{
                background: "#fff",
                paddingTop: "4px",
                justifyContent: "center", 
                alignItems: "center", 
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - ${appBarHeight}px)`
            }}>
                <LoadingIndicator loading={loadingMySeries}/>
            </Box> :   
            allSeries && allSeries.length > 0 ? 
            <Box className={classes.root} style={{marginTop: "12px", paddingBottom: "8px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Grid container direction="row" style={{justifyContent: "center"}}>
                    <Grid item xs={12}>
                        <Typography style={{marginLeft: 36, width:"100%", fontWeight: "bold", fontSize: "24px"}}>My Series</Typography>
                        <Box className={classes.gridList} style={{ overflowX: "scroll"}}>
                            <List style={{flexDirect:"row", display:"flex"}}>
                                <CarouselContent items={allSeries} handleSeriesClick={handleSeriesClick} selectedSeries={selectedSeries}/>
                            </List>
                        </Box>
                    </Grid>
                    <Typography style={{margin: "36px 0px 0px 36px", width:"100%", fontWeight: "bold", fontSize: "24px"}}>Metrics</Typography>
                    <Grid item xs={12} style={{ paddingLeft: "40px", paddingRight: "40px", marginTop: 8}}>
                        <Grid container direction="row" spacing={4} style={{marginTop: "8px", marginBottom: "4px"}}>
                            <Grid item xs={12}>
                                <Grid container direction="row" spacing={4} style={{display: "flex", justifyContent: "flex-start"}}>
                                    <Grid item xs={12} sm={6} md={6} lg={3} >
                                        <SeriesStatsWidget icon={<MarkAsUnreadIcon style={{fontSize:96}}/>} activeColor={activeColor} loadingData={loadingMySubscriptionsData} title={"Total Email Views (Today)"} value={seenSeriesTodayCount} valueColor={"#0096FF"}/>  
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <SeriesStatsWidget icon={<LoyaltyIcon style={{fontSize:96}}/>} activeColor={activeColor} loadingData={loadingMySubscriptionsData} title={"Active Subscriptions"} value={activeSubscriptionsCount} valueColor={"#7FEB45"}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <MetricsGraph activeColor={activeColor} title={"Daily Subscription Tracker"} data={newsletterData} lineMap={subscriptionBars} loadingData={loadingMySubscriptionsData}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <MetricsGraph activeColor={activeColor} title={"Daily Email Views Tracker"} data={newsletterData} lineMap={totalViewsBars} loadingData={loadingMySubscriptionsData}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box> : 
            <Box style={{
                background: "#fff",
                paddingTop: "4px",
                justifyContent: "center", 
                alignItems: "center", 
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - ${appBarHeight}px)`    
                }}>
                <TimelineIcon sx={{ color: "#819EF0", fontSize: 148 }}/>
                <Typography variant="h4" style={{fontWeight: "bold"}} gutterBottom component="div">
                    No Series Data
                </Typography>
            </Box>
         : loadingMyCampaigns ?  
            <Box style={{
                background: "#fff",
                paddingTop: "4px",
                justifyContent: "center", 
                alignItems: "center", 
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - ${appBarHeight}px)`
            }}>
                <LoadingIndicator loading={loadingMyCampaigns}/>
            </Box> : 
            allCampaigns && allCampaigns.length > 0 ? 
            <Box className={classes.root} style={{marginTop: "12px", paddingBottom: "8px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Grid container direction="row" spacing={4} style={{padding: "16px 32px 32px 52px"}}>
                    
                    <Typography style={{ width:"100%", fontWeight: "bold", fontSize: "24px"}}>My Campaigns</Typography>
                    <TextField
                        // label={
                        //     <InputLabel style={{alignContent: "center", textAlign: "center"}}>
                        //     { selectedCampaign && selectedCampaign.campaignId ?
                        //         <Typography>Selected Campaign</Typography> : 
                        //         <Typography>Select a Campaign</Typography>
                        //     }
                        //     </InputLabel>
                        // }
                        className={classes.selectOptions}
                        value={selectedCampaign ? selectedCampaign.campaignId : {}}
                        fullWidth
                        onChange={onChangeCampaign}
                        variant="outlined"
                        select
                        size="medium"
                        style={{ marginTop: 20, paddingRight: "20px",  maxWidth: 600, color: "#000", width: "100%",}}
                        SelectProps={{
                            MenuProps: { sx: { maxHeight: 250} }
                        }}>
                        {items}
                    </TextField>
                    <Typography style={{margin: "36px 0px 0px 0px", width:"100%", fontWeight: "bold", fontSize: "24px"}}>Metrics</Typography>
                    <Grid container spacing={4} direction="row" style={{marginTop: 20}}>
                        <Grid item md={12} xs={12} lg={12} style={{ paddingLeft: "20px", paddingRight: "40px"}}>
                            <Grid container spacing={4} style={{display: "flex", justifyContent: "flex-start"}}>
                                <Grid item xs={12} sm={6} md={6} lg={3} >
                                    <SeriesStatsWidget activeColor={"#819EF0"} icon={<MarkAsUnreadIcon style={{fontSize:96}}/>} loadingData={loadingMyCampaignData} title={`Unique Campaign Views (Last ${30} Days}`} value={uniqueSeenCampaigns} valueColor={"#0096FF"}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3}>
                                    <SeriesStatsWidget activeColor={"#819EF0"} icon={<CampaignIcon style={{fontSize:72}}/>} loadingData={loadingMyCampaignData} title={`Total Email Views (Today)`} value={seenCampaignsTodayCount} valueColor={"#0096FF"}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <MetricsGraph activeColor={"#819EF0"} title={"Daily Campaign Views Tracker"} data={campaignData} lineMap={totalViewsBars} loadingData={loadingMyCampaignData}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Box style={{
                                        display: "flex",
                                        borderRadius: "12px", 
                                        borderColor: "#F5F5F5",
                                        boxShadow: '-2px 2px 8px 1px #819EF0',
                                        height: 340, 
                                        overflowY: "scroll",
                                        flexDirection: "column",
                                    }}>
                                        <Typography style={{alignSelf: "center", widht: "100%", marginTop: 12, fontSize: "20px", fontWeight: "bold"}}>{"Email List"}</Typography>
                                    { loadingMyCampaignData ?     
                                        <LoadingIndicator loading={loadingMyCampaignData} overrideHeight={"25px"} overrideWidth={"25px"}/> :
                                        <List style={{ borderRadius: "4px", height: "100%", backgroundColor: "#fff", padding: "24px", overflow: "auto"}}>
                                            {emailList}
                                        </List>
                                    }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>    
                </Grid>
            </Box> :
            <Box style={{
                background: "#fff",
                paddingTop: "4px",
                justifyContent: "center", 
                alignItems: "center", 
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - ${appBarHeight}px)`    
            }}>
                <TimelineIcon sx={{ color: "#819EF0", fontSize: 148 }}/>
                <Typography variant="h4" style={{fontWeight: "bold"}} gutterBottom component="div">
                    No Campaign Data
                </Typography>
            </Box>
            }
        <Footer/>
      </Box>
    )
  }