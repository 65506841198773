import * as React from 'react';
import { Box, Button, Grid, InputLabel, MenuItem, Paper, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import HtmlTemplatePreview from './HtmlTemplatePreview';
import LoadingIndicator from './LoadingIndicator';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important"
        }
      },
    colorWhite: {
        color: "#fff"
    },
    colorBlack: {
        color: "#000"
    },
    container: {
        '&::-webkit-scrollbar': {
          width: "8px",
          height: "8px",
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "4px",
          margin:12
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "white",
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "12px",
          border: "1px transparent solid",
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    },
    inputColor: {
      "& label.Mui-focused": {
        color: "#000"
      },
      "& label": {
        color: "#000"
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: "#000"
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#000"
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        color: "#000",
        "&.Mui-focused fieldset": {
          borderColor: "#000"
        }
      }
    },
    selectOptions: {
      "& .MuiOutlinedInput-input": {
        backgroundColor: "#fff",
        color: "#000",
      },
      "& .MuiInputLabel-root": {
        color: "#000",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
        borderRadius: "10px",
        boxShadow: '-1px 1px 8px 1px #819EF0',
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "#000"
      },
      "&:hover .MuiInputLabel-root": {
        color: "#000"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#000"
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#000"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000"
      },
      container: {
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "4px"
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: ({activeColor})=> activeColor,
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "4px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    },
    }
  }));

const getItems = (templates) => {
  return templates.map((template, index) => {
    return <MenuItem key={template.templateId} style={{maxWidth: 500, color: "#000"}} value={template.templateId}>{template.templateName}</MenuItem>
  })
}

export function EditorTemplateList({
  onSelect,
  selectedTemplate, 
  templates, 
  templatePreview, 
  loadingTemplatPreview, 
  overrides,
  showInputSelector,
}) {

  const onChangeTemplate = (e) => {
    const selectedTemplate = templates.find(template => template.templateId === e.target.value)
    onSelect(selectedTemplate)
  }

  const classes = useStyles()
  const templateHeight = overrides && overrides.templateHeight ? overrides.templateHeight : `100%`
  const items = templates && templates.length > 0 ? getItems(templates) : <MenuItem key={"create-new-template"} style={{maxWidth: 500, color: "#000"}} value={"create-new-template"}>{"No Templates Created"}</MenuItem>
  return (
    <>
      <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
          { 
            showInputSelector ? 
            <Box>
              {selectedTemplate && selectedTemplate.templateId ?
               <Typography component="div" style={{margin: "0px 0px 12px 0px", fontSize: "20px", fontWeight: "bold"}}>Selected Template</Typography> : 
               <Typography component="div" style={{margin: "0px 0px 12px 0px", fontSize: "20px", fontWeight: "bold"}}>Select a Template</Typography>
              }
              <TextField
                className={classes.selectOptions}
                value={selectedTemplate ? selectedTemplate.templateId : {}}
                fullWidth
                style={{
                  width: "100%", 
                  margin:"0px 0px 12px 0px",
                  padding:"0px 0px 0px 0px",
                  maxWidth: 608
                }}
                onChange={onChangeTemplate}
                variant="outlined"
                select
                size="medium"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        maxWidth: 608,
                      },
                    },
                  },
                }}
              >
                {items}
              </TextField>
            </Box> : null
        }
        <Paper style={{margin:"12px 0px 0px 0px", boxShadow: '-1px 1px 8px 1px #819EF0', borderRadius: 12, backgroundColor: "#3d3d3d", height: templateHeight, overflow: "auto", display: "flex",  justifyContent: "center", alignContent: "center", alignItems: "center"}} >
          {loadingTemplatPreview ? <LoadingIndicator loading={loadingTemplatPreview} overrideLoadingColor={"#fff"}/> :
           templatePreview && <HtmlTemplatePreview customCSS={classes.container} htmlTemplate={templatePreview}/>
          }
        </Paper>
      </Box>
    </>
    );
    }