import { useState, useEffect } from 'react';
import { Grid, Box, Snackbar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { connect } from 'react-redux';
import Footer from './Footer';
import { Paper, Alert, Button, ButtonBase } from '@mui/material';
import EditUserDialog from './EditUserNameDialog';
import LoadingIndicator from './LoadingIndicator';
import axios from 'axios';
import ConfirmDialog from './ConfirmDialog';
import LockResetIcon from '@mui/icons-material/LockReset';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "250px",
      height: "250px",
      justifyContent: "center",
      alignItems: "center", 
      transition: "transform 0.15s ease-in-out",
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  colorRemove: {
    color: "#000",
    backgroundColor: ({activeColor})=> activeColor
  },
  colorBlack: {
        color: "#000"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "4px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

export const Settings = ({ user, appBarHeight, dispatch }) => {
    const { userName, email, userId, email_verified, tier, credits, api_key, api_key_id } = user
    
    const [openeditUsername, setOpenEditUsername] = useState(false)
    const [openRegenerateKey, setOpenRegenerateKey] = useState(false)
    const [loadingUpdateUser, setLoadingUpdateUser] = useState(false)
    const [loadingUpdateApiKey, setLoadingUpdateApiKey] = useState(false)
    const [showKey, setShowKey] = useState(false)
    const [snack, setSnack] = useState({open: false, message: null})
    const [activeColor, setActiveColor] = useState("#819EF0")

    const handleOnUserNameEditClick = (type) => {
        setOpenEditUsername(true);
    }

    const handleUserNameEditClose  = () => {
        setOpenEditUsername(false);
    };

    const handleOnRegenreateKeyClick = (type) => {
        setOpenRegenerateKey(true);
    }

    const handleShowKey = () => {
        setShowKey(!showKey)
    }

    const handleOnRegenreateKeyClose  = () => {
        setOpenRegenerateKey(false);
    };

    const validateUserName = (name) => {
        return /^[a-zA-Z0-9 ]*$/.test(name);
    }

    const onConfirmRegenrateKey = async () => {
        try { 
            setLoadingUpdateApiKey(true)
            const body = {
                userId,
                api_key_id
            }
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/create-api-key', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setSnack({
                open: true,
                message: "Succesfully updated API key!",
                type: "success"
            })
            dispatch({
                type: 'SET_USER',
                payload: { 
                    email,
                    email_verified,
                    userId,
                    tier,
                    userName,
                    credits,
                    api_key: result.data.api_key,
                    api_key_id: result.data.api_key_id
                }
              })
            setLoadingUpdateApiKey(false)
        } catch (e) {
            console.log(e)
            setSnack({
                open: true,
                message: "Unable to regerate API Key. Please try again later.",
                type: "error"
            })
            setLoadingUpdateApiKey(false)
        }
    }

    const onSaveUsername = async (updatedName) => {
        if(userName === updatedName) return
        if(updatedName === "") {
            setSnack({
                open: true,
                message: "Username cannot be empty.",
                type: "error"
            })
            return
        }
        if(!validateUserName(updatedName)){
            setSnack({
                open: true,
                message: "Username can't contain any special characters. Only letters, numbers, and spaces.",
                type: "error"
            })
            return 
        }
        try { 
            const body = {
                userId,
                userName: updatedName
            }
            setLoadingUpdateUser(true)
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/update-user', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setSnack({
                open: true,
                message: "Succesfully updated username!",
                type: "success"
            })
            dispatch({
                type: 'SET_USER',
                payload: { 
                    email,
                    email_verified,
                    userId,
                    tier,
                    userName: updatedName,
                    credits,
                    api_key,
                    api_key_id
                }
              })
            setLoadingUpdateUser(false)
        } catch (e) {
            console.log(e)
            setSnack({
                open: true,
                message: "Unable to update username. Please try again later.",
                type: "error"
            })
            setLoadingUpdateUser(false)
        }
    }

    const handleCloseSnack = () => {
        setSnack({
            open: false,
            message: null
        })
    }

    const classes = useStyles({activeColor})

    const { colorRemove, colorBlack } = classes
    const { open, message, type } = snack

    return (
        <Grid container className={classes.container} direction="row" style={{height: `calc(100vh - ${appBarHeight}px)`, overflowY: "auto", overflowX: "hidden"}}>
            <EditUserDialog 
                userName={userName}
                openeditUsername={openeditUsername}
                onSaveUsername={onSaveUsername}
                handleClickClose={handleUserNameEditClose}
            />
            <ConfirmDialog 
                onConfirm={onConfirmRegenrateKey}
                handleClickClose={handleOnRegenreateKeyClose} 
                title={"Regenerate API Key"} 
                message={"Are you sure you want to regenerate your API key? This cannot be undone and all integrations through third party apps using this key will cease to work unless updated with the new key."} 
                open={openRegenerateKey}
                confirmText={"Regenerate Key"}
            />
            <Snackbar 
                sx={{ height: "100%" }} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }} 
                open={open} 
                autoHideDuration={3500} 
                onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={type} sx={{ whiteSpace: "pre-wrap", width: '100%' }}>
                    {message} 
                </Alert>
            </Snackbar>
            <Box style={{padding: "12px", justifyContent: "center", width: "100%"}}>
                <Paper style={{padding: 8}}>
                <List style={{borderRadius: "4px", paddingTop: "4px", paddingBottom: 0}}>
                    <Box style={{ alignItems: "center", height:"100%", borderRadius:"2px", margin: "12px 0px 0px 12px", padding: 0, wordBreak: "break-word", display: "flex", alignText: "flex-end" }}>
                        <Typography variant={"h6"}>Username: </Typography>
                        <ListItemText primaryTypographyProps={{ marginLeft: "12px", fontSize: '20px'}} primary={userName} />
                        <ButtonBase style={{borderRadius: 8}}>
                            <ListItemIcon style={{justifyContent: "center"}} onClick={() =>handleOnUserNameEditClick()} >
                                {loadingUpdateUser ? <LoadingIndicator overrideWidth={"32px"} overrideHeight={"32px"} loading={loadingUpdateUser}/> : <EditIcon style={{fontSize: 36}} className={colorBlack}/>}
                            </ListItemIcon>
                        </ButtonBase>
                    </Box>
                    <ListItemButton
                        style={{ height:"100%", borderRadius:"2px", margin: "12px 0px 0px 12px", padding: 0, wordBreak: "break-word", display: "flex", alignText: "flex-end" }}
                        disabled
                    >   
                        <Typography variant={"h6"}>Email: </Typography>
                        <ListItemText primaryTypographyProps={{ marginLeft: "12px", fontSize: '20px'}} primary={email} />       
                    </ListItemButton>
                    <Box style={{ alignItems: "center", height:"100%", borderRadius:"2px", margin: "12px 0px 0px 12px", padding: 0, wordBreak: "break-word", display: "flex", alignText: "flex-end" }}>
                        <Typography variant={"h6"}>API Key: </Typography>
                        <ListItemText primaryTypographyProps={{ marginLeft: "12px", fontSize: '20px', pointerEvents: 'none' }} style={{filter: !showKey ? "blur(3.5px)" : null}}primary={api_key} />
                        <ButtonBase style={{borderRadius: 8}}>
                            <ListItemIcon style={{justifyContent: "center"}} onClick={() =>handleShowKey()}  >
                                {showKey ? <VisibilityIcon style={{fontSize: 42, padding:1}} className={colorBlack}/> : <VisibilityOffIcon style={{fontSize: 42, padding:1}} className={colorBlack}/>}
                            </ListItemIcon>
                        </ButtonBase>
                       <ButtonBase style={{borderRadius: 8}}>
                            <ListItemIcon style={{justifyContent: "center"}} onClick={() =>handleOnRegenreateKeyClick()}  >
                                {loadingUpdateApiKey ? <LoadingIndicator overrideWidth={"32px"} overrideHeight={"32px"} loading={loadingUpdateApiKey}/> : <LockResetIcon style={{fontSize: 42}} className={colorBlack}/>}
                            </ListItemIcon>
                        </ButtonBase>
                    </Box>
                </List>
                </Paper>
            </Box>
            <Footer excludeHeight={true}/>
        </Grid>
    )
  }

  const mapStateToProps = state => {
    return { credentialDrawerState: state.credentialDrawerState }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Settings)