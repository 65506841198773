import React from 'react';
import styles from '../stylings/GradientAnimation.module.css';

function GradientAnimation(props) {
    const { backgroundColors } = props
 // Set CSS variables for gradient colors
    const style = {
        '--color-1': backgroundColors[0],
        '--color-2': backgroundColors[1],
        '--color-3': backgroundColors[2],
        '--color-4': backgroundColors[1],
        '--color-5': backgroundColors[0],
        '--bg-position': '0% 50%', // Set initial background position,
    };

    const overlayStyle = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
      };

    return (
    <div className={styles['gradient-animation']} style={style}>
        <div style={overlayStyle}></div>
    </div>
    );
}

export default GradientAnimation;