import * as React from 'react';
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme) => ({
    notchedOutline: { 
        borderWidth: "2px !important",
        borderColor: '#000 !important' 
    },
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

export default function EditUserDialog({userName, openeditUsername, onSaveUsername, handleClickClose}) {
  const [name, setName] = useState(userName)
  const classes = useStyles()
  const { primary, notchedOutline } = classes

  const onChange = (e) => {
    setName(e.target.value)
  }
  const handleSaveClick = () => {
    onSaveUsername(name)
    handleClickClose()
  }
  return (
    <div>
      <Dialog open={openeditUsername} onClose={handleClickClose}>
        <DialogTitle className={primary}>Edit Username</DialogTitle>
        <DialogContent>
          <TextField
            onChange={onChange}
            value={name}
            autoFocus
            margin="dense"
            id="name"
            label="Username"
            fullWidth
            InputProps={{
                classes: {
                    notchedOutline: notchedOutline,
                    input: primary,
                    label: primary
                },
            }}
            InputLabelProps={{
                style: {
                    color:"#000"
                }
            }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button className={primary} onClick={handleClickClose}>Cancel</Button>
          <Button className={primary} onClick={handleSaveClick}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}