import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'; 
import { CardContent } from '@mui/material';
import { Card } from '@aws-amplify/ui-react';
import ProgressiveImage from './ProgressiveImage';

const BootstrapDialogTitle = (props) => {
  const { seriesName, onClose } = props;

  return (
    <DialogTitle>
      {seriesName}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const getReadbleFrequency = (frequency, startDate, selectedTimeZone,) => {
    return `Delivers ${frequency} at ${startDate}, ${selectedTimeZone} time`
}

export default function SeriesDetailCard({series, close, seriesCardState, openSeriesDetailPage}) {
  const { seriesName, frequency, startDate, selectedTimeZone, imageUrl, backgroundColors, description } = series
  const { seriesname, imageurl, backgroundcolors } = series // for series returned from cloudsearch
  const background = backgroundcolors || backgroundColors
  const readableFrequency = getReadbleFrequency(frequency, startDate, selectedTimeZone,)
  const cardStyleOverride = background && background.length > 0 ? 
  {alignItems: "center", display: "flex", flexDirection: "column", background: `linear-gradient(45deg, ${background[0]} 20%, ${background[1]} 90%)`} : { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #819EF0 30%, #819EF0 90%)'}
  
  const CustomButtonPrimary = styled(Button)(({ theme }) => ({
    borderColor: background && background.length > 0 ? `${background[0]}80` : "#fff",
    backgroundColor: background && background.length > 0 ? `${background[0]}80` : "#819EF0",
    '&:hover': {
        borderColor: background && background.length > 0 ? `${background[0]}80` : "#819EF0",
        backgroundColor:  "#fff"
      },
  }));

  return (
    <div>
      <Dialog
        onClose={() => close()}
        aria-labelledby="customized-dialog-title"
        open={seriesCardState}
        > 
            <Box style={{width: "300px"}}>
                <BootstrapDialogTitle id="customized-dialog-title" seriesName={seriesName || seriesname} onClose={() => close()}/>
                <DialogContent dividers style={{padding: 0}}>
                    <Card style={cardStyleOverride}>
                        <CardContent>
                            <div style={{
                                borderRadius: "8px",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center", 
                                display: "flex", 
                                boxShadow: "0 12px 12px rgba(0.25,0.25,0.25,0.25)",
                                }}>
                                  <ProgressiveImage
                                      width="150px"
                                      height="150px"
                                      src={imageUrl || imageurl}
                                  />
                            </div>
                        </CardContent>
                    </Card>
                    <Typography gutterBottom style={{padding: "8px 8px 0px 8px", fontSize: "12px", fontWeight: "bold"}} >
                        Description
                    </Typography>
                    <Typography gutterBottom style={{padding: "0px 8px 0px 8px", fontSize: "14px"}} >
                        {description}
                    </Typography>
                    <Typography gutterBottom style={{padding: "0px 8px 0px 8px", fontSize: "12px", fontWeight: "bold"}} >
                        Frequency
                    </Typography>
                    <Typography gutterBottom style={{padding: "0px 8px 8px 8px", fontSize: "14px"}} >
                        {readableFrequency}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <CustomButtonPrimary variant="outlined" style={{color: "#000"}} onClick={() => openSeriesDetailPage(series)}>
                        Series Details
                    </CustomButtonPrimary>
                </DialogActions>
            </Box>
      </Dialog>
    </div>
  );
}
