import {useEffect, useState, useRef} from "react"
import axios from 'axios';
import { Grid, Paper, Typography, Box, Alert, ListItem, ListItemText, Button, Checkbox } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import { connect } from "react-redux";
import { Snackbar, List, MenuItem, InputAdornment } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';
import { EditorTemplateList } from "../EditorTemplateList";
import { UserEmailList } from "../UserEmailList";
import Footer from "../Footer";
import ConfirmDialog from "../ConfirmDialog";
import { useNavigate } from "react-router";
import SEO from "../SEO";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles((theme) => ({
  root: {
      "&.Mui-selected": {
          backgroundColor: "#000 !important",
          color: "#fff !important",
          marginRight: "8px"
      }
    },
  colorWhite: {
      color: "#fff"
  },
  colorBlack: {
      color: "#000"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  },
  cardSelect: {
    borderRadius: "12px",
    borderColor: "#979797",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { 
      borderColor: "#000",
      transform: "scale3d(1.08, 1.08, 1)"
    },
    boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  notchedOutline: { 
    borderWidth: "2px !important",
    borderColor: '#000 !important'
  },
  inputColor: {
    "& label.Mui-focused": {
      color: "#F5F5F5"
    },
    "& label": {
      color: "#F5F5F5"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderColor: "#F5F5F5"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderColor: "#F5F5F5"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      color: "#000",
      borderColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: '-0.5px 1.5px 1px 3px rgba(0, 0, 0, 0.1)',
      "&.Mui-focused fieldset": {
        borderColor: "#000"
      }
    }
  },
  selectOptions: {
    "& .MuiOutlinedInput-input": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "& .MuiInputLabel-root": {
      color: "#000"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: '-2px 2px 8px 1px #819EF0',
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#000"
    },
    "&:hover .MuiInputLabel-root": {
      color: "#000"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#F5F5F5",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5"
    },
    '& .MuiMenu-paper': {
        borderRadius: '20px',
      },
  }
}));

const CampaignBuilder = ({dialogTutorialMetadata, user, appBarHeight, isLoggedIn, dispatch}) => {
  const [userSubscribers, setUserSubscribers] = useState([])
  const [userSeries, setUserSeries] = useState(null)
  const [uploadedUsers, setUploadedUsers] = useState([])
  const [allTemplates, setAllTemaplates] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [snackProps, setSnackProps] = useState({
    message: "",
    open: false
  });
  const [activeColor, setActiveColor] = useState("#819EF0")
  const [selectedSeriesSubscribers, setSelectedSeriesSubscribers] = useState([])
  const [loadingSubscribers, setLoadingSubscribers] = useState(false)
  const [emailSubject, setEmailSubject] = useState("")
  const [campaignName, setCampaignName] = useState("")
  const [loadingSend, setLoadingSend] = useState(false)
  const [selectedSeries, setSelectedSeries] = useState(null)
  const [templatePreview, setTemplatePreview] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [loadingTemplatPreview, setLoadingTemplatPreview] = useState(false)
  const [loadingTemplates, setLoadingTemplates] = useState(false)
  const [enableReply, setEnableReply] = useState(true)
  const [templateId, setTemplateId] = useState(null)
  const [openConfirmSendDialog, setOpenConfirmSendDialog] = useState(false)
  const [openConfirmSendToSelfDialog, setOpenConfirmSendToSelfDialog] = useState(false)
  const [openConfirmSubscribeDialog, setOpenConfirmSubscribeDialog] = useState(false)
  const [confirmConsent, setConfirmConsent] = useState(false)
  const navigate = useNavigate()
  const { userId, userName, email, tier } = user
  
  const handleCloseSnack = () => {
    setSnackProps({
      message: "",
      open: false
    })
  }

  const handleConsentChange = (event) => {
    setConfirmConsent(event.target.checked);
  };

  const onSelect = async (selectedTemplate) => {
    if(!isLoggedIn) {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
      return
    } 
    if(!selectedTemplate) return
    try {
        const { templateId } = selectedTemplate
        const body = {
            userId,
            templateId
        }
        setLoadingTemplatPreview(true)
        const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/load-template', body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        setTemplateId(templateId)
        setTemplatePreview(result.data.templates[0].htmlTemplate)
        setSelectedTemplate(selectedTemplate)
        setLoadingTemplatPreview(false)
    } catch (e) {
        console.log(e)
        setLoadingTemplatPreview(false)
    }
  }

  const onUserSelect = (user) => {
    const index = selectedUsers.findIndex(selectedUser => selectedUser.email === user.email)
    let newList = [...selectedUsers]
    if(index === -1) {
      newList.push(user)
    } else {
      const newListIndex = selectedUsers.map(e => e.email).indexOf(user.email);
      newList.splice(newListIndex, 1)
    }
    setSelectedUsers(newList);
  }

  const onUserSelectAll = (users) => {
    const userList = [...selectedUsers]
    users.forEach(user => {
      const isDuplicate = selectedUsers.some(selectedUser => selectedUser.email === user.email);
      if (!isDuplicate) {
        userList.push(user);
      }
    });
    const sortedUsers = userList.sort((a, b) => a.email.localeCompare(b.email));
    setSelectedUsers(sortedUsers)
  }

  const onUserClearAll = (users) => {
    const userList = [...selectedUsers] 
    const filteredUsers = userList.filter((user) => !users.some((u) => u.email === user.email));
    const sortedUsers = filteredUsers.sort((a, b) => a.email.localeCompare(b.email));
    setSelectedUsers(sortedUsers)
  }

  const classes = useStyles({activeColor})

  useEffect(() => {
    if(userSubscribers.length > 0 && selectedSeries) {
      const selectedUserSubscribers = userSubscribers.filter(subscriber => {
        return subscriber.seriesId === selectedSeries.seriesId
      })
      setSelectedSeriesSubscribers(selectedUserSubscribers)
    }
  }, [userSubscribers, selectedSeries])

  useEffect(async () => {
    try { 
      if(!isLoggedIn) {
        return
      } 
      const body = {
          userId
      }
      setLoadingSubscribers(true)
      const allUserSubscribers = axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-all-subscribers', body, {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
      })
      const allSeriesResult = axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-series-by-user', body, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
      })

      const results = await Promise.all([allUserSubscribers, allSeriesResult])

      const { data: { allSubscribers }} = results[0]
      const { data: { allSeries: allUserSeries }} = results[1]

      setUserSubscribers(allSubscribers)
      setUserSeries(allUserSeries)
      setSelectedSeries(allUserSeries[0])

      setLoadingSubscribers(false)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
      setLoadingSubscribers(false)
    }
  }, []);

  useEffect(async () => {
    try { 
      if(!isLoggedIn) {
        return
      }
      const body = {
          userId
      }
      setLoadingTemplates(true)
      const allTemplatesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-templates', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
          })

      const { data: { templates: allUserTemplates }} = allTemplatesResult

      setAllTemaplates(allUserTemplates)
      setLoadingTemplates(false)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
      setLoadingTemplates(false)
    }
  }, []);

  const onSeriesSelect = (series) => {
    setSelectedSeries(series)
  }

  useEffect(async () => {
      setActiveColor("#819EF0")
  }, []);

  const onHandleUploadedUsers = (users, error) => {
    if(error) {
      setSnackProps({
        open: true,
        message: "Unsupported file type, file is not a spreadsheet."
      })
      return
    }
    setUploadedUsers(users)
  }

  const onChangeCapaignName = (e) => {
    setCampaignName(e.target.value)
  }

  const onChangeEmailSubject = (e) => {
    setEmailSubject(e.target.value)
  }

  const onConfirmSend = async () => {
    setLoadingSend(true)
    const emails = selectedUsers.map(user => {
      return user.email
    })
    try { 
      const body = {
        campaignName,
        templateId,
        emails,
        userId,
        subject: emailSubject,
        from: userName || email,
        includeReply: enableReply,
        senderEmail: email,
        confirmConsent: confirmConsent
      }
      await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/send-campaign', body, {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
      })
      setSnackProps({
        open: true,
        message: "Succesfully sent campaign!",
        type: "success"
      })
    } catch (error) {
      console.log(error)
      setSnackProps({
        open: true,
        message: "Campaign was sent unsuccessfully. Please try again later.",
        type: "success"
      })
      setLoadingSend(false)
    }
    setLoadingSend(false)
  }

  const onConfirmSendToSelf = async () => {
    setLoadingSend(true)
    try { 
      const body = {
        campaignName,
        templateId,
        emails: [email],
        userId,
        subject: emailSubject,
        from: userName || email,
        includeReply: enableReply,
        senderEmail: email,
        confirmConsent: confirmConsent
      }
      await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/send-campaign', body, {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
      })
      setSnackProps({
        open: true,
        message: "Succesfully sent campaign to self!",
        type: "success"
      })
    } catch (error) {
      console.log(error)
      setSnackProps({
        open: true,
        message: "Campaign was sent unsuccessfully to your email. Please try again later.",
        type: "success"
      })
      setLoadingSend(false)
    }
    setLoadingSend(false)
  }

  const onConfirmSubscribe = () => {
    navigate('/subscription-plan')
  }

  const handleClickSend = (sendToSelf) => {
    if(!isLoggedIn) {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
      return
    } 
    if(tier === "FREE") {
      setOpenConfirmSubscribeDialog(true)
      return
    }
    const errors = validateFields(sendToSelf)
    if(errors !== "") {
      setSnackProps({
        open: true,
        message: errors,
        type: "error"
      })
      return
    }
    if(sendToSelf) {
      setOpenConfirmSendToSelfDialog(true)
    } else {
      setOpenConfirmSendDialog(true)
    }
  }

  const handleClickCloseSend = () => {
    setOpenConfirmSendDialog(false)
  }

  const handleClickCloseSendToSelf = () => {
    setOpenConfirmSendToSelfDialog(false)
  }

  const handleClickCloseSubscribe= () => {
    setOpenConfirmSubscribeDialog(false)
  }

  const toggleEnableReply = () => {
    setEnableReply(!enableReply)
  }

  const validateFields = (sendToSelf) => {
    let error = ""
    if(campaignName === "") {
      error += "Please enter a campaign name.\n"
    }
    if(emailSubject === "") {
      error += "Please enter a subject for you email campaign.\n"
    }
    if(!sendToSelf && selectedUsers.length === 0) {
      error += "Campaign email list cannot be empty.\n"
    }
    if(templateId === null) {
      error += "No template selected for campaign.\n"
    }
    if(!sendToSelf && confirmConsent === false) {
      error += "You did not provide consent that you lawfully obtaining these emails, that will be sent out with this campaign"
    }
    return error
  }

  const getSelectedEmailList = () => {
    return (
      selectedUsers.map((user, index) => {
        return (
          <Box style={{  
            backgroundColor: "#fff",
            color: "#000",
            borderColor: "#F5F5F5",
            borderRadius: "10px",
            boxShadow: '-1px 1px 2px 1px #819EF0',
            borderColor: "#F5F5F5",
            color: "#000",
            margin: 8,
            }} key={user.userId || `${user.email}-${index}` }>
            <ListItem>
              <ListItemText primary={user.email}/>
            </ListItem>
          </Box>
        )
      })
    )
  }

  const { open, message, type } = snackProps
  const { container, inputColor, selectOptions } = classes

  const selectedEmails = getSelectedEmailList()
  return (
    <Grid container className={container} style={{overscrollBehaviorY: "auto", height: `calc(100vh - ${appBarHeight}px)`, overflowX: "none", overflowY: "auto"}} >
      <SEO
        title={`Pikkaboo's Campaign Builder: Send Targeted Email Campaigns to Your Subscribed and Uploaded Users`}
        description={`Welcome to Pikkaboo's Campaign Builder - your solution for targeted and effective email campaigns. With our platform, you can easily send newsletters to your subscribed and uploaded users, ensuring that your message reaches the right audience.`}
        name={`Campaign Builder`}
        type={`article`}
      />
      <Box style={{ display: "flex", flexDirection: "row", height: `calc(100vh - ${appBarHeight}px)` }}>
      <Snackbar 
          sx={{ height: "100%" }} 
          anchorOrigin={{ vertical: 'top', horizontal: 'center', }} 
          open={open} 
          autoHideDuration={3500} 
          onClose={handleCloseSnack}>
          <Alert
            onClose={handleCloseSnack}
            severity={type}
            sx={{
              whiteSpace: "pre-wrap",
              width: '100%',
              padding: '12px', // Adjust padding to increase the size
              fontSize: '1.5rem', // Adjust font size to increase the size
            }}
            iconMapping={{
              success: <span style={{ fontSize: '1.5rem' }}>😊</span>, // You can adjust the font size or use a different icon
              error: <span style={{ fontSize: '1.5rem' }}>😞</span>, // You can adjust the font size or use a different icon
              info: <span style={{ fontSize: '1.5rem' }}>ℹ️</span>, // You can adjust the font size or use a different icon
              warning: <span style={{ fontSize: '1.5rem' }}>⚠️</span>, // You can adjust the font size or use a different icon
            }}
            action={
              <IconButton
                size="large"
                color="inherit"
                onClick={handleCloseSnack}
                aria-label="close"
                sx={{ fontSize: '1.5rem' }} // Adjust the font size of the close button to 1.5rem
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {message}
          </Alert>
      </Snackbar>
      </Box>
      <ConfirmDialog 
        onConfirm={onConfirmSend} 
        handleClickClose={handleClickCloseSend} 
        title={`Send Campaign`}
        message={`Confirm you want to send campaign ${campaignName}.`}
        open={openConfirmSendDialog}
        confirmText={"Send Campaign"}
      />
      <ConfirmDialog 
        onConfirm={onConfirmSendToSelf} 
        handleClickClose={handleClickCloseSendToSelf} 
        title={`Send Campaign to Self`}
        message={`Confirm you want to send campaign ${campaignName} to email ${email}.`}
        open={openConfirmSendToSelfDialog}
        confirmText={"Send Campaign"}
      />
      <ConfirmDialog 
        onConfirm={onConfirmSubscribe} 
        handleClickClose={handleClickCloseSubscribe} 
        title={"Premium Feature"} 
        message={`Oops! This is a premium feature.\n Sign up for a free trial today.`} 
        open={openConfirmSubscribeDialog}
        confirmText={"Sign Up"}
      />
      <Grid container spacing={4} style={{padding: "24px 5% 32px 5%"}}>
        <Grid item md={12} xs={12} order={{ xs: 2, md: 1 }}>
          <Grid container columnSpacing={8}>
            <Grid item xl={4} xs={12} md={6}>
              <Typography component="div" style={{ marginTop: "16px", fontSize: "20px", fontWeight: "bold"}}>
                { "Campaign Name" }
              </Typography>
              <TextField
                onChange={onChangeCapaignName}
                value={campaignName}
                margin="dense"
                fullWidth
                className={selectOptions}
                style={{maxWidth: 600, borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                variant="outlined"
                size="medium"
              />
            </Grid>
            <Grid item xl={4} xs={12} md={6}>
              <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                { "From (Username)" }
              </Typography>
              <TextField
                  readOnly={true}
                  className={selectOptions}
                  value={userName || email || ""}
                  margin="dense"
                  variant="outlined"
                  size="medium"
                  style={{maxWidth: 600, borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
              />
            </Grid>
            <Grid item xl={4} md={6} xs={12} style={{width: "100%"}}>
              <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                { "Reply to (Email)" }
              </Typography>
              <Box style={{display: "flex", alignContent: "center"}}>
                  <TextField
                    disabled={!enableReply}
                    readOnly={true}
                    value={email || ""}
                    margin="dense"
                    className={selectOptions}
                    style={{maxWidth: 600, borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                    variant="outlined"
                    size="medium"
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              <Box style={{
                                display: "flex",
                                alignSelf: "center",
                                background: "#fff",
                                borderWidth: 0,
                                alignContent: "center",
                                borderColor: "null",
                                boxShadow: '-4px 0px 8px -4px #819EF0'
                              }}>
                                <Button style={{ height: "56px", color: "#000", fontSize: 14, lineHeight: "1.2em" }} onClick={toggleEnableReply}>
                                  {enableReply ? "Disable Reply": "Enable Reply"}
                                </Button>
                              </Box>
                          </InputAdornment>
                      ),
                  }}
                  />
                  
                </Box>
            </Grid>
            <Grid item xl={4} md={6} xs={12} >
              <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                { "Subject" }
              </Typography>
              <TextField
                onChange={onChangeEmailSubject}
                value={emailSubject}
                margin="dense"
                fullWidth
                className={selectOptions}
                style={{maxWidth: 600, borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                variant="outlined"
                size="medium"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={8}>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
            <Typography style={{fontSize: "20px", fontWeight: "bold", margin: "32px 0 4px 0"}}>Series Email List</Typography>
            <UserEmailList 
              selector={true} 
              activeColor={activeColor} 
              users={selectedSeriesSubscribers} 
              onSelect={onUserSelect}
              selectedSeries={selectedSeries}
              onSeriesSelect={onSeriesSelect} 
              userSeries={userSeries}
              selectedUsers={selectedUsers} 
              appBarHeight={appBarHeight}
              onClearAll={onUserClearAll}
              onSelectAll={onUserSelectAll}
              loadingSeriesManager={loadingSubscribers}/>
          </Grid>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
            <Typography style={{ fontSize: "20px", fontWeight: "bold", margin: "32px 0 4px 0"}}>Imported Email List</Typography> 
            <UserEmailList 
              onHandleUploadedUsers={onHandleUploadedUsers} 
              upload={true} 
              activeColor={activeColor} 
              users={uploadedUsers} 
              onSelect={onUserSelect} 
              onSelectAll={onUserSelectAll}
              onClearAll={onUserClearAll}
              selectedUsers={selectedUsers} 
              appBarHeight={appBarHeight}/>
          </Grid>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
            <Typography style={{fontSize: "20px", fontWeight: "bold", margin: "32px 0 4px 0"}}>Selected Email List</Typography>
            <Box 
              className={classes.container} 
              style={{ 
                marginTop: 20, 
                borderRadius: "12px", 
                borderColor: "#F5F5F5",
                borderRadius: "10px",
                boxShadow: '-2px 2px 8px 1px #819EF0',
                height: 328, 
                overflowY: "scroll",
                maxWidth: 600
              }}>
              <List style={{backgroundColor: "#fff", borderRadius: "4px"}}>
                {selectedEmails}
              </List>
            </Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={8} style={{marginTop: "32px"}}>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
            <Box style={{maxWidth: 600}}>
              <EditorTemplateList 
                showInputSelector={true}
                overrides={{templateHeight: "550px"}}
                templates={allTemplates} 
                onSelect={onSelect} 
                templatePreview={templatePreview} 
                loadingTemplatPreview={loadingTemplatPreview}
                loadingTemplates={loadingTemplates}
                selectedTemplate={selectedTemplate}
              />
            </Box>
          </Grid>
          <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
            <Typography style={{fontSize: "20px", fontWeight: "bold"}}>Consent Declaration</Typography>
            <Paper elevation={4} style={{maxWidth: 600, borderRadius: "10px", margin: "12px 0px 0px 0px", padding: "28px", position: "relative"}}>
              <Typography variant="body1" readOnly={true} style={{fontSize: "16px"}}>
                {`I hereby confirm that I have obtained explicit consent from the individuals on my email list who will receive this campaign from my entity ${userName} and email ${email}. I understand and acknowledge my responsibility to comply with applicable laws and regulations related to email marketing.`}   
              </Typography>
              <Checkbox
                style={{ position: "absolute", bottom: "0", right: "0" }}
                checked={confirmConsent}
                onChange={handleConsentChange}
                color="primary"
                sx={{
                  '&.Mui-checked': {
                    color: activeColor
                  }
                }}
              />
            </Paper>
            <LoadingButton
                  onClick={() => handleClickSend(true)}
                  loading={loadingSend}
                  loadingPosition="start"
                  startIcon={<MarkunreadIcon />}
                  variant="contained"
                  style={{
                    margin: "20px 12px 6px 0px",
                    maxWidth: 600,
                    fontSize:"16px",
                    backgroundColor: "#819EF0",
                    color: "#000",
                    width: "100%",
                    borderColor: "#F5F5F5",
                    borderRadius: "12px",
                    height: "58px",
                    boxShadow: '-2px 2px 8px 1px #819EF0'
                  }}>
                Send To Self
            </LoadingButton>
            <LoadingButton
                  onClick={() => handleClickSend(false)}
                  loading={loadingSend}
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  variant="contained"
                  style={{
                    margin: "12px 12px 12px 0px",
                    maxWidth: 600,
                    fontSize:"16px",
                    backgroundColor: "#819EF0",
                    color: "#000",
                    width: "100%",
                    borderColor: "#F5F5F5",
                    borderRadius: "12px",
                    height: "58px",
                    boxShadow: '-2px 2px 8px 1px #819EF0'
                  }}>
                Send Campaign
            </LoadingButton>
          </Grid>
        </Grid>
        </Grid>
    </Grid>
    
      <Footer excludeHeight={true}/>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

const mapStateToProps = state => {
  return { 
      dialogTutorialMetadata: state.dialogTutorialMetadata
   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignBuilder)