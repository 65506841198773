import { useState, useEffect } from 'react';
import LoadingIndicator from "./LoadingIndicator";
import { Grid, Box, ImageListItem } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import UnsubscribeDialog from './UnsubscribeDialog';
import TipSeriesDialog from './TipSeriesDialog';
import { useParams } from 'react-router';
import { Card } from '@aws-amplify/ui-react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
  import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import ProgressiveImage from './ProgressiveImage';
import { connect } from 'react-redux';
import Footer from './Footer';
import moment from "moment";
import GradientAnimation from './GradientAnimation';
import PdfViewer from './PDFViewer';
import { List } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "300px",
      height: "300px",
      justifyContent: "center",
      alignItems: "center", 
      transition: "transform 0.15s ease-in-out",
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)",
      transform: "scale3d(0.95, 0.95, 0.95)"
  },
  cardHover: {
    "&:hover": { 
        borderColor: "#000",
        transform: "scale3d(1.00, 1.00, 1.00)"
      }
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px",
        height: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

const getSocialButtons = (seriesId) => {
    return (
        <Box style={{padding: "0px 12px 0px 12px", marginLeft: "24px"}}>
            <FacebookShareButton
                url={`https://mypikkaboo.com/series/${seriesId}`}
            >
                <FacebookIcon
                    size={40} round={true}
                />
            </FacebookShareButton>
            <LinkedinShareButton
                style={{marginLeft: "8px"}}
                url={`https://mypikkaboo.com/series/${seriesId}`}
            >
                <LinkedinIcon
                    size={40} round={true}
                />
            </LinkedinShareButton>
            <RedditShareButton
                style={{marginLeft: "8px"}}
                url={`https://mypikkaboo.com/series/${seriesId}`}
            >
                <RedditIcon
                    size={40} round={true}
                />
            </RedditShareButton>
            <TwitterShareButton
                style={{marginLeft: "8px"}}
                url={`https://mypikkaboo.com/series/${seriesId}`}
            >
                <TwitterIcon
                    size={40} round={true}
                />
            </TwitterShareButton>
            <WhatsappShareButton
                style={{marginLeft: "8px"}}
                url={`https://mypikkaboo.com/series/${seriesId}`}
            >
                <WhatsappIcon
                    size={40} round={true}
                />
            </WhatsappShareButton>
        </Box>
    )
}

const getSeriesContent = (user, loadingSubscribe, subscribed, series, classes, handleSubscribeClick, handleUnsubscribeClick, handleTipSeriesClick, pdfs, openPdfTab) => {
    const userId = user && user.userId
    const seriesCard = [classes.cardSelect]
    const { imageUrl, seriesName, backgroundColors, description, seriesId, frequency, startDate, selectedTimeZone } = series
    const cardStyleOverride = backgroundColors && backgroundColors.length > 0 ? 
    {justifyContent: "center", display: "flex", background: `linear-gradient(45deg, ${backgroundColors[0]} 20%, ${backgroundColors[1]} 90%)`} : { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #819EF0 30%, #819EF0 90%)'}
    
    const isBackgroundPresent = backgroundColors && backgroundColors.length > 0
    const primaryColor = isBackgroundPresent ? backgroundColors[0] : "#819EF0"

    const CustomButtonPrimary = styled(LoadingButton)(({ theme }) => ({
        borderColor: isBackgroundPresent ? `${primaryColor}80` : "#fff",
        backgroundColor: isBackgroundPresent ? `${primaryColor}80` : "#819EF0",
        '&:hover': {
            borderColor: isBackgroundPresent ? `${primaryColor}80` : "#819EF0",
            backgroundColor:  "#fff"
          },
      }));

      const CustomButtonSecondary = styled(LoadingButton)(({ theme }) => ({
        borderColor: "#118C4F",
        backgroundColor: "#90ee90",
        '&:hover': {
            borderColor: "#118C4F",
            backgroundColor:  "#fff"
          },
      }));  

      const renderedPdfs = pdfs ? renderPdfs(pdfs, [...seriesCard, classes.cardHover], openPdfTab) : null
      
    return (
    <Grid container>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "start", marginBottom: "16px" }}>
            <Box style={{ marginTop: "12px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", textAlign: "center", marginLeft: "12px",}}>
                <Card className={seriesCard} style={{...cardStyleOverride, margin: "12px", padding: "0px", overflow: "hidden"}}>
                    <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                        <div style={{borderRadius: "12px", height: 300, width: 300}}>
                            <ProgressiveImage
                                width="100%"
                                height="100%"
                                src={imageUrl}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Box>
            <Box style={{ padding: "0px 12px 0px 12px", marginLeft: "24px", marginTop: "32px"}}>
                <Typography component="div" variant="h4" style={{margin: 0, padding: "0px", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "start"}}>
                    {seriesName}
                </Typography>
                <Typography component="div" style={{fontSize: 24, marginTop: "8px", marginLeft: "1px", padding: "0px", display: "flex", flexDirection: "row", justifyContent: "start"}}>
                    {description}
                </Typography>
                <Typography component="div" style={{fontWeight: "bold", fontSize: 18, marginTop: "8px", marginLeft: "1px", padding: "0px", display: "flex", flexDirection: "row", justifyContent: "start"}}>
                    Delivers {frequency} at {moment(startDate).format("h:mmA")}, {selectedTimeZone} time.
                </Typography>
            </Box>
            <Box style={{ padding: "0px 12px 0px 12px", margin: "16px 0px 0px 24px"}}>
                {subscribed ? 
                <CustomButtonPrimary loading={loadingSubscribe} variant="outlined" style={{fontSize: "20px", fontWeight: "bold", height: "50px", width: "200px", color: "#000"}} onClick={() => handleUnsubscribeClick(series)}>
                    {!loadingSubscribe && "Unsubscribe"}
                </CustomButtonPrimary> :
                <CustomButtonPrimary loading={loadingSubscribe} variant="outlined" style={{fontSize: "20px", fontWeight: "bold", height: "50px", width: "200px", color: "#000"}} onClick={() => handleSubscribeClick(series)}>
                    {!loadingSubscribe && "Subscribe"}
                </CustomButtonPrimary>
                }
                {/* {[
                    "828f0110-3377-49e3-9df0-5ef36403bab0", 
                    "6d50b53d-909a-40ad-a68c-fbdd2c53ff6e", 
                    "956c6e8d-1a89-4b05-a6da-25e14bceb44b"].includes(userId) ? 
                            <CustomButtonSecondary variant="outlined" style={{marginLeft: "12px", fontSize: "12px", fontWeight: "bold", height: "40px", color: "#000"}} onClick={() => handleTipSeriesClick(series)}>
                                <Box style={{flexDirection: "column", display: "flex"}}>
                                    <Typography style={{fontSize: "12px", fontWeight: "bold"}}>
                                        Donate
                                    </Typography>
                                </Box>
                            </CustomButtonSecondary> :
                            <CustomButtonSecondary disabled={true} variant="outlined" style={{backgroundColor: "#819EF0", marginLeft: "12px", fontSize: "12px", fontWeight: "bold", height: "40px", color: "#000"}} onClick={() => handleTipSeriesClick(series)}>
                                <Box style={{flexDirection: "column", display: "flex", }}>
                                    <Typography style={{fontSize: "12px", fontWeight: "bold"}}>
                                        Donate
                                    </Typography>
                                    <Typography style={{fontSize: "8px", fontWeight: "bold"}}>
                                        (Coming Soon)
                                </Typography>
                                </Box>
                            </CustomButtonSecondary>
                } */}
            </Box>
            <Typography style={{padding: "0px 12px 0px 12px", margin: "16px 0px 8px 24px", fontSize: "20px", fontWeight: "bold"}}>
                Share
            </Typography>
            {getSocialButtons(seriesId)}
            {renderedPdfs && renderedPdfs.length > 0 ? 
            <>
                <Typography style={{padding: "0px 12px 0px 16px", margin: "40px 0px 0px 24px", fontSize: "32px", fontWeight: "bold"}}>
                    Past Newsletters
                </Typography>
                <List className={classes.container} style={{padding: "8px 32px 16px 32px", flexDirection:"row", display:"flex", overflowX: "auto", overflowY: "hidden"}}>
                    {renderedPdfs}
                </List> 
            </> : null }
        </Grid>
    </Grid>
    )
}

const renderPdfs = (pdfs, seriesCard, openPdfTab) => {
    return pdfs.map((pdf, index) => {
        const { pdfUrl, pdfId, sendDate } = pdf
        const stylings = {
            width: "300px",
            height: "400px",
            border: "1px solid #ccc"
        }
        let margins = {}
        let paddings = {}
        if(index === 0 ) {
            margins = { marginRight: "16px" }
            paddings = { padding: "8px 16px 0px 0px" }
        } else if (index === pdfs.length -1) {
            margins = { marginLeft: "16px" }
            paddings = { padding: "8px 0px 0px 16px" }
        } else {
            margins = { marginLeft: "16px", marginRight: "16px" }
            paddings = { padding: "8px 16px 0px 16px" }
        }
        return <Box style={{display: "flex", flexDirection: "column", textAlign:"center"}}>
                    <Card id={pdfId} className={seriesCard} style={{...stylings, ...margins}}
                        onClick={() => openPdfTab(pdfUrl)}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                         <div style={{borderRadius: "12px"}}>
                             <PdfViewer pdfUrl={pdfUrl}/>
                            </div>
                        </CardContent>
                    </Card>
                    <Typography style={{...paddings, fontWeight: "bold", fontSize: "16px"}}>{`${moment(sendDate).format("MMM Do YYYY, h:mmA")}`}</Typography>
                </Box>
        })
}

export const SeriesDetailPage = ({ user, appBarHeight, isLoggedIn, dispatch }) => {
    const [activeColor, setActiveColor] = useState("#819EF0")
    const navigate = useNavigate()
    const [loadingSeries, setLoadingSeries] = useState(false)
    const [loadingSubscribe, setLoadingSubscribe] = useState(false)
    const [selectedSeries, setSelectedSeries] = useState(null)
    const [snackBar, setSnackBar] = useState(null)
    const [subscribedSeries, setSubscribedSeries] = useState(false)
    const [openUnsubscribe, setOpenUnsubscribe] = useState(false);
    const [openTipSeries, setOpenTipSeries] = useState(false);
    const [pdfs, setPdfs] = useState(null)
    const classes = useStyles({activeColor})
    const { id } = useParams();

    useEffect(()=> {
        if(!selectedSeries) return
        const { backgroundColors } = selectedSeries
        const isBackgroundPresent = backgroundColors && backgroundColors.length > 0
        const primaryColor = isBackgroundPresent ? backgroundColors[0] : "#819EF0"
        setActiveColor(`${primaryColor}80`)
    }, [selectedSeries])

    const handleSnackBarClose = () => {
        setSnackBar(null);
    };

    const handleSubscribeClick = async (series) => {
        if(!isLoggedIn) {
            dispatch({
                type: 'SET_CREDENTIAL_DRAWER',
                payload: { 
                    credentialDrawerState: true
                }
            })
            return
        }
        setLoadingSubscribe(true)
        const { seriesId, seriesName } = series
        try { 
            const { userId, email } = user
            const body = {
                userId,
                seriesId,
                email
            }
            await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/subscribe-series', body, {
              headers: {
                  "Content-Type": "application/json"
              }
            })
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Successfully subscribed to ${seriesName}`,
                snackBarType: "success"
            })
            setSubscribedSeries(true)
            setLoadingSubscribe(false)
        } catch (e) {
            // show alert dialog that failed
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Unable to subscribe to ${seriesName}`,
                snackBarType: "error"
            })
            setLoadingSubscribe(false)
            console.log(e)
        }
    }

    useEffect(async () => {
        try {
            setLoadingSeries(true)
            const seriesId = id
            const userId = user && user.userId
            const seriesResult = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-series-by-series-id?seriesId=${seriesId}&userId=${userId}`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            const { data: { series, subscribed, savedPdfs }} = seriesResult
            setPdfs(savedPdfs)
            setSelectedSeries(series)
            setSubscribedSeries(subscribed)
            setLoadingSeries(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingSeries(false)
        }
    }, [isLoggedIn]);

    const handleTipSeriesClick = () => {
        if(!isLoggedIn) {
            navigate('/signin')
            return
        }
        setOpenTipSeries(true);
    }

    const handleClickCloseTipSeries= () => {
        setOpenTipSeries(false);
    }

    const handleUnsubscribeClick = (series) => {
        setOpenUnsubscribe(true);
    };

    const handleClickCloseUnsubscribe = () => {
        setOpenUnsubscribe(false);
    }

    const onUnsubscribeClick = async () => {
        if(!selectedSeries) {
            return
        }
        setLoadingSubscribe(true)
        const { userId, email } = user
        const { seriesId, seriesName } = selectedSeries
        const body = {
            userId,
            seriesId,
            email
        }
        try { 
            await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/unsubscribe-series', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Successfully unsubscribed to ${seriesName}`,
                snackBarType: "success"
            })
            setLoadingSubscribe(false)
            setSubscribedSeries(false)
        } catch (e) {
            console.log(e)
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Unable to unsubscribe to ${seriesName}`,
                snackBarType: "error"
            })
            setLoadingSubscribe(false)
        }
    }

    const onTipSeriesClick = (validInput, amount) => {
        const { seriesName } = selectedSeries
        if(validInput) {
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Successfully paid \$${amount} to ${seriesName}`,
                snackBarType: "success"
            })
        } else {
            setSnackBar({
                openSnackBar: true,
                snackBarMessage: `Unable to donate to ${seriesName}, please enter a valid non zero amount`,
                snackBarType: "error"
            })  
        }

    }

    const openPdfTab = (url) => {
        window.open(url, '_blank');
    }

    const backgroundColors = selectedSeries && selectedSeries.backgroundColors
    return (
        <Grid className={classes.container} container direction="row" style={{ height: `calc(100vh - ${appBarHeight}px)`, overflowY: "auto", overflowX: "hidden"}}>
            {backgroundColors ? 
            <Box style={{position: "absolute", zIndex: -1, height: "50%", width: "100%"}}>
                <GradientAnimation backgroundColors={backgroundColors} />
            </Box> : null
            }
            <Box style={{justifyContent: "center", width: "100%"}}>
                {snackBar && <Snackbar
                    sx={{ height: "100%" }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                    open={snackBar.openSnackBar}
                    onClose={handleSnackBarClose}
                    autoHideDuration={2500}
                >
                    <Alert severity={snackBar.snackBarType}>{snackBar.snackBarMessage}</Alert>
                </Snackbar>}
                <UnsubscribeDialog series={selectedSeries} openUnsubscribe={openUnsubscribe} onUnsubscribeClick={onUnsubscribeClick} handleClickClose={handleClickCloseUnsubscribe}/>
                <TipSeriesDialog series={selectedSeries} openTipSeries={openTipSeries} onTipSeriesClick={onTipSeriesClick} handleClickClose={handleClickCloseTipSeries}/>
                { loadingSeries ? 
                    <Box style={{
                        background: "#fff",
                        paddingTop: "4px",
                        alignItems: "center", 
                        display: "flex",
                        flexDirection: "column",
                        height: `calc(100vh - ${appBarHeight}px)`   
                    }}>
                        <LoadingIndicator loading={loadingSeries}/>
                    </Box> : 
                selectedSeries && getSeriesContent(user, loadingSubscribe, subscribedSeries, selectedSeries, classes, handleSubscribeClick, handleUnsubscribeClick, handleTipSeriesClick, pdfs, openPdfTab)
                }
            </Box>
            <Footer excludeHeight={true}/>
        </Grid>
    )
  }

  const mapStateToProps = state => {
    return { credentialDrawerState: state.credentialDrawerState }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SeriesDetailPage)