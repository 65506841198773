import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingIndicator({loading, overrideWidth, overrideHeight, overrideLoadingColor}) {
	return (
		<Box sx={{ display: 'flex', justifyContent: "center", alignItems:"center", height: "100%" }}>
            <Fade
            in={loading}
            style={{
                transitionDelay: '0ms',
                justifyContent: "center", 
                color: overrideLoadingColor ? overrideLoadingColor : "#000",
                display: 'flex', 
                height: overrideWidth ? overrideWidth : "50px",
                width: overrideHeight ? overrideHeight : "50px"
            }}
            unmountOnExit
            >
                <CircularProgress />
            </Fade>
      </Box>
    )
}