import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

export default function DeleteSeriesDialog({openDelete, onDeleteSeriesClick, closeDelete, selectedSeries}) {
  const classes = useStyles()
  const { primary } = classes
  const handleDeleteClick = () => {
    onDeleteSeriesClick()
    closeDelete()
  }
  return (
    <div>
      <Dialog open={openDelete} onClose={handleDeleteClick}>
        <DialogTitle className={primary}>Delete</DialogTitle>
        <DialogContent>
            <Typography gutterBottom className={primary}>
              {selectedSeries && `Are you sure you want to delete series "${selectedSeries.seriesName}"`}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button className={primary} onClick={closeDelete}>Cancel</Button>
          <Button className={primary} onClick={handleDeleteClick}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}