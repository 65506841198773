import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import App from "./App";
import store, { persistor } from './store/index'
import { PersistGate } from "redux-persist/integration/react"

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <AmplifyProvider>
        <Authenticator.Provider>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Authenticator.Provider>
      </AmplifyProvider>
    </Provider>
  </StrictMode>,
  rootElement
);