import { Grid } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important",
            marginRight: "8px"
        }
      },
    colorWhite: {
        color: "#fff"
    },
    colorBlack: {
        color: "#000"
    },
  }));


export function SeriesActions({loadingSave, loadingDelete, onSaveClick, onDeleteClick}) {

    const handleClickSave = () => {
        onSaveClick();
    };

    const handleClickDelete = () => {
        onDeleteClick()
    };

    return (
    <Grid container spacing={2} direction="row">  
        <Grid item md={6} xs={6} style={{display:"flex", alignItems: "flex-end"}}>
            <LoadingButton
                onClick={handleClickSave}
                loading={loadingSave}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                style={{
                    fontSize:"16px",
                    backgroundColor: "#819EF0",
                    color: "#000",
                    width: "100%",
                    borderColor: "#F5F5F5",
                    borderRadius: "12px",
                    height: "58px",
                    boxShadow: '-2px 2px 8px 1px #819EF0',
                }}
            >
                Save Series
            </LoadingButton>
        </Grid>  
        <Grid item md={6} xs={6} style={{display:"flex", alignItems: "flex-end"}}>
            <LoadingButton
                onClick={handleClickDelete}
                loading={loadingDelete}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="contained"
                style={{
                    fontSize:"16px",
                    backgroundColor: "#819EF0",
                    color: "#000",
                    width: "100%",
                    borderColor: "#F5F5F5",
                    borderRadius: "12px",
                    height: "58px",
                    boxShadow: '-2px 2px 8px 1px #819EF0',
                }}
            >
                Delete Series
            </LoadingButton>
        </Grid>      
    </Grid>
    );
}