import React, { useEffect, useState } from 'react';
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import moment from 'moment-timezone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, TextField, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
      selectOptions: {
        "& .MuiOutlinedInput-input": {
          backgroundColor: "#fff",
          color: "#000",
          height: "100%",
        },
        "& .MuiInputLabel-root": {
          color: "#000"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5",
          borderRadius: "12px",
          height: "58px",
          boxShadow: '-2px 2px 8px 1px #819EF0'
        },
        "&:hover .MuiOutlinedInput-input": {
          color: "#000"
        },
        "&:hover .MuiInputLabel-root": {
          color: "#000",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#000"
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#F5F5F5",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        '& .MuiMenu-paper': {
            borderRadius: '20px',
          },
      },
      timeZonePicker: {
        "& .MuiOutlinedInput-input": {
          backgroundColor: "#fff",
          color: "#000",
          height: "58px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
        "& .MuiInputLabel-root": {
          color: "#000",
          fontSize: "16px",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ccc",
        },
        "&:hover .MuiOutlinedInput-input": {
          borderColor: "#000",
        },
        "&:hover .MuiInputLabel-root": {
          color: "#000",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#000",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          borderColor: "#000",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#000",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#000",
        }
      },
  }));

const ColorToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#000",
        backgroundColor: "#819EF0"
    },
    backgroundColor: "#fff",
    width:"100%",
    "&:hover": {
        backgroundColor: "#cccccc"
    }
});

function DateTimeInput(props) {
  const { onSelectDate, onSelectTimeZone, onSelectFrequency, selectedDate, selectedTimeZone, selectedFrequency, seriesFreqRef, seriesTimeRef } = props
  
  const classes = useStyles()

  const handleFrequencyChange = (event, freq) => {
    if(!freq) return
    onSelectFrequency(freq)
  };

  const handleDateChange = (startDate) => {
    onSelectDate(startDate)
  };

  const handleTimeZoneChange = (event) => {
    onSelectTimeZone(event.target.value)
  };

  useEffect(() => {
    handleDateChange(selectedDate)
  }, [selectedDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container columnSpacing={2} rowSpacing={2} style={{paddingBottom: "8px"}}>
          <Grid item xs={12}>
            <Typography component="div" style={{ margin: "24px 0px 16px 0px",  fontSize: "20px", fontWeight: "bold"}}>
              { "Frequency" }
            </Typography>
            <ToggleButtonGroup
                ref={seriesFreqRef}
                value={selectedFrequency}
                exclusive
                onChange={handleFrequencyChange}
                style={{          
                  borderColor: "#F5F5F5",
                  borderRadius: "12px",
                  boxShadow: '-2px 2px 8px 1px #819EF0',
                  width: "100%", 
                  height: "58px",
                  justifyContent: "center", 
                  marginBottom: "4px",}}
                >
                <ColorToggleButton style={{fontSize: "14px", borderRadius: "12px 0px 0px 12px", height: "100%"}} value="daily">
                    Daily
                </ColorToggleButton>
                <ColorToggleButton style={{fontSize: "14px", height: "100%"}} value="weekly">
                    Weekly
                </ColorToggleButton>
                <ColorToggleButton style={{fontSize: "14px", borderRadius: "0px 12px 12px 0px", height: "100%"}} value="monthly">
                    Monthly
                </ColorToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography component="div" style={{margin: "12px 0px 16px 0px", fontSize: "20px", fontWeight: "bold"}}>
              { "Select Start Date" }
            </Typography>
            <DateField
              ref={seriesTimeRef}
              defaultValue={dayjs(moment())}
              value={dayjs(selectedDate)}
              onChange={handleDateChange}
              className={classes.selectOptions}
              style={{width: "100%"}}
            />
           </Grid>
           <Grid item xs={12} sm={6} md={6}>
            <Typography component="div" style={{ margin: "12px 0px 16px 0px",  fontSize: "20px", fontWeight: "bold"}}>
              { "Select Start Time" }
            </Typography>
            <TimeField 
              defaultValue={dayjs(moment())}
              value={dayjs(selectedDate)}
              onChange={handleDateChange}
              className={classes.selectOptions}
              style={{width: "100%"}}
              />
           </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography component="div" style={{ margin: "12px 0px 16px 0px",  fontSize: "20px", fontWeight: "bold"}}>
              { "Select Time Zone" }
            </Typography>
            <TextField
              select
              value={selectedTimeZone}
              onChange={handleTimeZoneChange}
              sx={{ backgroundColor: "#fff", borderRadius: "4px", width: "100%"}}
              SelectProps={{
                  MenuProps: {
                      style: { maxHeight: '250px' } // Set maxHeight on Menu component
                    }
              }}
              className={classes.selectOptions}
              >
              {
                moment.tz.names().map((tz) => (
                  <MenuItem style={{color: "#000" }} key={tz} value={tz}>{tz}</MenuItem>
              ))
            }
          </TextField>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default DateTimeInput;
