import { useRef, useState, useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import { Box } from '@mui/system';
import axios from 'axios';
import { ActionDrawerMyTemplates } from './ActionDrawerMyTemplates';
import moment from "moment";
import {isMobile} from 'react-device-detect';
import { Snackbar, Alert, Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import ConfirmDialog from './ConfirmDialog';
import SEO from './SEO';
import { CircularProgress, Grid } from '@material-ui/core';
import { TemplateActions } from './TemplateActions';
import TutorialDialog from './TutorialDialog';

const PROD_GENERATE_EMAIL_PRICE_1 = 'price_1NIlQcAGDMeRymuHASAtvufy'
const PROD_GENERATE_EMAIL_PRICE_2 = 'price_1NIlXsAGDMeRymuHQ0PO1OJf'
const PROD_GENERATE_EMAIL_PRICE_3 = 'price_1NIlSYAGDMeRymuHxUPZ2M4p'

const stripe = window.Stripe('pk_live_51LxNcoAGDMeRymuHOy4jU4c26DnverioAja7Hht5OicoFXsWJcKIKUypqnBxiZf6j88I6FVNcMrGeKW2yycEFIiU00euYFTbAi');


const generateUniqueTemplateName = (pre) => {
    const currentDate = moment(Date()).format("MMMM Do YYYY, h:mm:ss a");
    return `${ pre } (${currentDate})`;
}

const useStyles = makeStyles((theme) => ({
    container: {
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "4px",
          marginLeft: "40px",
          marginRight: "40px"
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "#819EF0",
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "12px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    }
  }));

const TEST_GENERATE_EMAIL_PRICE = 'price_1Mkv3lAGDMeRymuHbcJey9vM'
const PROD_GENERATE_EMAIL_PRICE = 'price_1MkxulAGDMeRymuHRzYwlar4'

export const Editor = ({ user, appBarHeight, isLoggedIn, dispatch, credentialDrawerState, cachedTemplate, dialogTutorialMetadata }) => {
    const [snack, setSnack] = useState({
        open: false,
        message: ""
      });
    const [loadingGenerate, setLoadingGenerate] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [loadingLoad, setLoadingLoad] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [loadingCopy, setLoadingCopy] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [loadingSendToSelf, setLoadingToSelf] = useState(false)
    const [loadingSendEmailToSelf, setLoadingSendEmailToSelf] = useState(false)
    const [templates, setTemaplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [loadedTemplate, setLoadedTemplate] = useState(null)
    const [templatePreview, setTemplatePreview] = useState(null)
    const [loadingTemplates, setLoadingTemplates] = useState(false)
    const [loadingTemplatPreview, setLoadingTemplatPreview] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openConfirGenerates, setOpenConfirmGenerates] = useState(false)
    const [openConfirmClear, setOpenConfirmClear] = useState(false)
    const [instanceCreated, setInstanceCreated] = useState(false)
    const [openDrawerMyTemplates, setOpenDrawerMyTemplates] = useState(false);
    const [title, setTitle] = useState("")
    const [loadingCheckout, setLoadingCheckout] = useState(false)
    const [seenTutorials, setSeenTutorials] = useState(false)

    const navigate = useNavigate()
    const { tier, userId, email, userName } = user
    
    useEffect(async () => {
        if(isMobile) {
            setSnack({ 
                open: true,
                message:  "Some functionality like drag and drop might not work on mobile/tablet devices.",
                severity: "warning"
            });
        }
    }, []);

    const dispatchSignIn = () => {
        dispatch({
            type: 'SET_CREDENTIAL_DRAWER',
            payload: { 
                credentialDrawerState: true
            }
        })
    }

    useEffect(() => {
        if(credentialDrawerState) {
            toggleDrawerMyTemplates(false)
        }
    }, [credentialDrawerState])

    useEffect(() => {
        const { seen } = dialogTutorialMetadata["editor"]
        setSeenTutorials(seen) //Necassary, something happens with window focus if Tutorials are rendered (Disables pull to refresh) 
      }, [dialogTutorialMetadata]);

    const onSelect = async (selectedTemplate) => {
        setSelectedTemplate(selectedTemplate)
        try { 
            const { templateId } = selectedTemplate
            const body = {
                userId,
                templateId
            }
            setLoadingTemplatPreview(true)
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/load-template', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setTemplatePreview(result.data.templates[0].htmlTemplate)
            setLoadingTemplatPreview(false)
        } catch (e) {
            console.log(e)
            setLoadingTemplatPreview(false)
        }
    }

    const emailEditorRef = useRef(null);
    const saveDesign = async (templateName) => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        }
        if(tier === "FREE") {
            setOpenConfirm(true)
            return
        }
        if(!templateName) {
            return
        }
        setLoadingSave(true)

        emailEditorRef.current.editor.exportHtml(async (loadedTemplate) => {
            const { html: htmlTemplate, design: designTemplate } = loadedTemplate
            const index = templates.findIndex(curTemplate => curTemplate.templateName === templateName)
            let templateId
            if(index != -1) {
              ({ templateId } = templates[index])
            }
            const body = {
                userId,
                templateId,
                templateName,
                htmlTemplate,
                designTemplate
            }

            try { 
                const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/save-template', body, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                const { data: { templates }} = result
                setTemaplates(templates)
            } catch (e) {
                console.log(e)
            }
            setLoadingSave(false)
        });
    };

    //Create copy endpoint
    const copyDesign = async () => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(tier === "FREE") {
            setOpenConfirm(true)
            return
        }       
        if(!selectedTemplate) {
            return
        }
        setLoadingCopy(true)
        const { templateName } = selectedTemplate
        const templateNameCopy = generateUniqueTemplateName(templateName)
        const { userId } = user
        const { templateId } = selectedTemplate
        const body = {
            userId,
            templateId,
            templateNameCopy
        }
        try { 
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/copy-template', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const { data: { templates }} = result
            setTemaplates(templates)
        } catch (e) {
            console.log(e)
        }
        setLoadingCopy(false)
    };
    
    const generateTemplate = async (title) => {
        try {
            if(!isLoggedIn) {
                dispatchSignIn()
                return
            }
            if(tier === "FREE") {
                setOpenConfirm(true)
                return
            }      
            if(!title) {
                setSnack({
                    open: true,
                    message: "To generate a template you need to specify a title to your template",
                    severity: "error"
                })
                return
            }
            if(loadingGenerate) {
                return
            }
            const { userId } = user 
            const body = {
                userId,
                title,
                temperature: 0.7,
                model: "gpt-4-1106-preview"
            }
            setLoadingTemplatPreview(true)
            setLoadingGenerate(true)
            const generatedEmail = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/generate-email', body, {
              headers: {
                  "Content-Type": "application/json"
              }
            })
            console.log(generatedEmail)
            if(generatedEmail.data) {
                if(generatedEmail.data.enableGenerate) {
                    emailEditorRef.current.editor.loadDesign(generatedEmail.data.designTemplate);
                    dispatch({
                        type: 'DEDUCT_CREDIT'
                    })
                } else {
                    setOpenConfirmGenerates(true)
                }
            }
            console.log(generatedEmail)
            setLoadingGenerate(false)
            setLoadingTemplatPreview(false)
          } catch (e) {
            setLoadingTemplatPreview(false)
            setLoadingGenerate(false)
            console.log("Error: " + e)
          }
    }

    //Delete endpoint
    const deleteDesign = async () => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(!selectedTemplate) {
            return
        }
        setLoadingDelete(true)
        const { userId } = user
        const { templateId, templateName } = selectedTemplate
        const body = {
            userId,
            templateId,
            templateName
        }
        try { 
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/delete-template', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const { data: { templates }} = result
            setTemaplates(templates)
            setSelectedTemplate(null)
            setTemplatePreview(null)
        } catch (e) {
            console.log(e)
        }
        setLoadingDelete(false)
    };

    //Edit endpoint
    const editDesign = async (template) => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(!selectedTemplate) {
            return
        }
        setLoadingEdit(true)
        const { templateName } = template
        const { userId } = user
        const { templateId } = selectedTemplate
        const body = {
            userId,
            templateName,
            selectedTemplateId: templateId
        }
        try { 
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/edit-template-name', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const { data: { templates }} = result
            setTemaplates(templates)
            setSelectedTemplate(template)
        } catch (e) {
            console.log(e)
        }
        setLoadingEdit(false)
    };

    const sendToSelf = async () => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(tier === "FREE") {
            setOpenConfirm(true)
            return
        }
        try { 
            setLoadingSendEmailToSelf(true)
            let html
            emailEditorRef.current.editor.exportHtml(async (data) => {
                const { html } = data;
                const body = {
                    userName,
                    automate: false,
                    senderEmail: email,
                    htmlTemplate: html
                  }
                await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/send-email-to-self', body, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                setSnack({ 
                    open: true,
                    message: `Successfully emailed templat to ${email}`,
                    severity: "success"
                });
                setLoadingSendEmailToSelf(false)
            });

        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingSendEmailToSelf(false)
        }
    }

    const loadDesign = async () => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(tier === "FREE") {
            setOpenConfirm(true)
            return
        }
        if(!selectedTemplate) {
            return
        }
        setLoadingLoad(true)
        try { 
            const { templateId } = selectedTemplate
            const body = {
                userId,
                templateId
            }
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/load-template', body, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            setLoadingLoad(false)
            setOpenDrawerMyTemplates(false)
            setLoadedTemplate({selectedTemplate})
            const { designTemplate, htmlTemplate } = result.data.templates[0]
            if(designTemplate !== "")  {
                emailEditorRef.current.editor.loadDesign(designTemplate);
            } else {
                emailEditorRef.current.editor.loadDesign({
                    html:htmlTemplate,
                    classic: true
                });
            } 
            
            // show alert dialog that saved 
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingLoad(false)
        }
    }

    //Doanload file
    const exportDesign = () => {
        if(!isLoggedIn) {
            dispatchSignIn()
            return
        } 
        if(tier === "FREE") {
            setOpenConfirm(true)
            return
        }
        if(!loadedTemplate) {
            return
        }
        setLoadingExport(true)
        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            var data = new Blob([html], {type: 'text/html'});
            var csvURL = window.URL.createObjectURL(data);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'pikkaboo-template.html');
            tempLink.click();
            setLoadingExport(false)
        });
        
    };

    useEffect( async () => {
        if(!isLoggedIn || !instanceCreated) {
            return
        }
        try { 
            const body = {
                userId
            }
            setLoadingTemplates(true)
            const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-templates', body, {
                    headers: { "Content-Type": "application/json" }
                })
            
            const { data: { templates }} = result
            setTemaplates(templates)
            setLoadingTemplates(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingTemplates(false)
        }
    }, [instanceCreated])

    useEffect(() => {
        if(instanceCreated && emailEditorRef.current && cachedTemplate) {
            emailEditorRef.current.editor.loadDesign(cachedTemplate);
        }
    }, [instanceCreated])

    const onLoad = () => {
        setInstanceCreated(true)
    }

    const onReady = () => {
        if(emailEditorRef.current) {
            emailEditorRef.current.addEventListener('design:updated', (data) => { 
                emailEditorRef.current.editor.exportHtml((loadedTemplate) => {
                    const { design } = loadedTemplate
                    dispatch({
                        type: 'SAVE_TEMPLATE',
                        payload: { 
                            cachedTemplate: design
                        }
                    })
                }) 
            })
        }
    };

    const appearance = {
        theme: "dark",
    }

    const handleClose = () => {
        setSnack({ 
            open: false,
            message: "",
            severity: ""
        });
    };

    const onConfirmSubscribe = () => {
        navigate('/subscription-plan')
    }

    const handleClickClose = () => {
        setOpenConfirm(false)
    }
    const handleClickOpenClear = () => {
        setOpenConfirmClear(true)
    }

    const onConfirmClear = () => {
        emailEditorRef.current.editor.loadBlank({
            backgroundColor: '#e7e7e7'
        });
    }

    const onConfirmPurchaseGenerates = async (selectedOption) => {
        setLoadingCheckout(true)
        try {
          const purchaseGenerates = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/stripe-checkout-session?priceId=${selectedOption}&userId=${userId}&email=${email}&mode=payment`, {
              headers: {
                  "Content-Type": "application/json"
              }
          })
          stripe.redirectToCheckout({
              sessionId: purchaseGenerates.data.sessionId
          })
          setLoadingCheckout(false)
        } catch(e) {
            console.log(e)
            setLoadingCheckout(false)
        }
    }

    const handleClickCloseClear = () => {
        setOpenConfirmClear(false)
    }

    const handleClickCloseGeneartes = () => {
        setOpenConfirmGenerates(false)
    }

    const toggleDrawerMyTemplates = (state) => {
        setOpenDrawerMyTemplates(state);
    };

    const onChangeTitle = (title) => {
        setTitle(title)
    }

    const { open, message, severity } = snack;

    const generateRef = useRef(null)
  
    const refList = [
        generateRef
    ]

    return (
        <>
            <SEO
                title={`Pikkaboo's Design Studio: Elevate Your Newsletters with Intuitive Design Tools`}
                description={`Welcome to Pikkaboo's Design Studio - where creativity meets functionality. Our intuitive design editor allows you to effortlessly create beautiful and engaging newsletters that truly reflect your unique style and brand. Whether you're a professional designer or just starting out, our platform makes it easy to bring your vision to life. With a range of customizable templates, a vast library of stock images, and a simple drag-and-drop interface, you can create newsletters that are both beautiful and effective. So why wait? Unleash your creativity and elevate your newsletter game with Pikkaboo's Design Studio.`}
                name={`Design Studio`}
                type={`article`} 
            />

            {!seenTutorials ? <TutorialDialog refList={refList} page="editor" /> : undefined}
            <Box style={{ display: "flex", flexDirection: "row", height: `calc(100vh - ${appBarHeight}px)` }}>
                <Snackbar 
                    sx={{ height: "100%" }} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center', }} 
                    open={open} 
                    autoHideDuration={2000} 
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
                <ConfirmDialog 
                    onConfirm={onConfirmSubscribe}
                    handleClickClose={handleClickClose} 
                    title={"Premium Feature"} 
                    message={"Oops! This is a premium feature.\nSign up for a free trial today."} 
                    open={openConfirm}
                    confirmText={"Sign Up"}
                />
                <ConfirmDialog 
                    onConfirm={onConfirmPurchaseGenerates}
                    handleClickClose={handleClickCloseGeneartes} 
                    title={"Premium Feature"} 
                    message={"Oops! It seems like you've run out of credits. Check back next month for your free 10 credits, or proceed to checkout to purchase more!"} 
                    open={openConfirGenerates}
                    confirmText={"Proceed to Checkout"}
                    selectOption={[PROD_GENERATE_EMAIL_PRICE_1, PROD_GENERATE_EMAIL_PRICE_2, PROD_GENERATE_EMAIL_PRICE_3]}
                />
                <ConfirmDialog 
                    onConfirm={onConfirmClear}
                    handleClickClose={handleClickCloseClear} 
                    title={"Clear Canvas"} 
                    message={"Are you sure you want to clear your canvas?"} 
                    open={openConfirmClear}
                    confirmText={"Confirm"}
                />
                { loadingGenerate ?
                    <Box style={{
                        position: 'absolute', 
                        width: `calc(100vw)`,
                        height: `calc(100vh - ${appBarHeight+72}px)`, 
                        overflowX: "auto", 
                        overflowY: "hidden", 
                        zIndex: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: "flex",
                        backgroundColor: "#ffffffA0"
                        }} >
                        <CircularProgress style={{width: "75px", height: "75px"}}/>
                        <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: "128px"
                        }}
                        >
                        <Typography
                            variant="caption"
                            component="div"
                            fontSize={16}
                        >{"Generating Template..."}</Typography>
                        </Box>
                    </Box> : null
                }
                { loadingCheckout ?
                    <Box style={{
                        position: 'absolute', 
                        width: `calc(100vw)`,
                        height: `calc(100vh - ${appBarHeight+72}px)`, 
                        overflowX: "auto", 
                        overflowY: "hidden", 
                        zIndex: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: "flex",
                        backgroundColor: "#ffffffA0"
                        }} >
                        <CircularProgress style={{width: "75px", height: "75px"}}/>
                        <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: "128px"
                        }}
                        >
                        <Typography
                            variant="caption"
                            component="div"
                            fontSize={16}
                        >{"Redirecting to Checkout"}</Typography>
                        </Box>
                    </Box> : null
                }                
                <Grid container direction="row" justifyContent='center' style={{backgroundColor: "#232E3F"}}> 
                    <EmailEditor style={{ width: `calc(100vw)`,height: `calc(100vh - ${appBarHeight+86}px)`, overflowX: "auto", overflowY: "hidden"}} appearance={appearance} ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
                    <TemplateActions
                        generateRef={generateRef}
                        loadingGenerate={loadingGenerate}
                        toggleDrawerMyTemplates={toggleDrawerMyTemplates}
                        onSaveClick={saveDesign} 
                        onSendToSelfClick={sendToSelf}
                        loadingSendEmailToSelf={loadingSendEmailToSelf}
                        loadingSave={loadingSave}
                        loadingLoad={loadingLoad}
                        loadingExport={loadingExport}
                        loadingCopy={loadingCopy}
                        loadingDelete={loadingDelete}
                        loadingEdit={loadingEdit}
                        loadingSendToSelf={loadingSendToSelf}
                        selectedTemplate={selectedTemplate}
                        templates={templates}
                        showStudioButtons={true}
                        clearCanvas={handleClickOpenClear}
                        onChangeTitle={onChangeTitle}
                        title={title}
                        onGenerateTemplate={generateTemplate}
                    />
                </Grid>
            </Box>
            <ActionDrawerMyTemplates
                appBarHeight={appBarHeight}
                toggleDrawerMyTemplates={toggleDrawerMyTemplates}
                openDrawerMyTemplates={openDrawerMyTemplates}
                onLoadClick={loadDesign} 
                onCopyClick={copyDesign} 
                onDeleteClick={deleteDesign}
                onEditClick={editDesign}
                onExportClick={exportDesign}
                loadingSave={loadingSave}
                loadingLoad={loadingLoad}
                loadingExport={loadingExport}
                loadingCopy={loadingCopy}
                loadingDelete={loadingDelete}
                loadingEdit={loadingEdit}
                templates={templates}
                onSelect={onSelect}
                loadedTemplate={loadedTemplate}
                selectedTemplate={selectedTemplate}
                templatePreview={templatePreview}
                loadingTemplatPreview={loadingTemplatPreview}
                loadingTemplates={loadingTemplates}
            />
        </>
    )
}

const mapStateToProps = state => {
    return { 
        credentialDrawerState: state.credentialDrawerState,
        cachedTemplate: state.cachedTemplate,
        dialogTutorialMetadata: state.dialogTutorialMetadata
     }
}
  
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor)