import {useEffect, useState, useRef} from "react"
import axios from 'axios';
import { Grid, Paper, Typography, Box, Alert, styled, ToggleButton, ToggleButtonGroup, Chip, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { ManagerSeriesContentList } from "../ManagerSeriesContentList";
import { SeriesContentQueue } from "../SeriesContentQueue";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { SeriesActions } from "../SeriesActions";
import { SeriesSelector } from "../SeriesSelector";
import { SeriesDescriptionInputBox } from "../SeriesDescriptionInputBox";
import DeleteSeriesDialog from "../DeleteSeriesDialog";
import { connect } from "react-redux";
import TutorialDialog from "../TutorialDialog";
import { Snackbar } from "@material-ui/core";
import Footer from "../Footer";
import ConfirmDialog from "../ConfirmDialog";
import { useNavigate } from "react-router";
import SEO from "../SEO";
import DateTimeInput from "../DateTimeInput";
import moment from "moment";
import Cancel from '@mui/icons-material/Cancel';
import { LoadingButton } from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import ConstructionIcon from '@mui/icons-material/Construction';
import CropModel from "../image-componenets/CropModel";
import UploadCroppedImage from "../image-componenets/UploadCroppedImage";

const useStyles = makeStyles((theme) => ({
  root: {
      "&.Mui-selected": {
          backgroundColor: "#000 !important",
          color: "#fff !important",
          marginRight: "8px"
      }
    },
  colorWhite: {
      color: "#fff"
  },
  colorBlack: {
      color: "#000"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        margin: 12
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  },
  cardSelect: { 
    borderRadius: "12px",
    borderColor: "#979797",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { 
      borderColor: "#000",
      transform: "scale3d(1.08, 1.08, 1)"
    },
    boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  notchedOutline: { 
    borderWidth: "2px !important",
    borderColor: '#000 !important'
  },
  inputColor: {
    "& label.Mui-focused": {
      color: "#F5F5F5"
    },
    "& label": {
      color: "#F5F5F5"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderColor: "#F5F5F5"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderColor: "#F5F5F5"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      color: "#000",
      borderColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: '-0.5px 1.5px 1px 3px rgba(0, 0, 0, 0.1)',
      "&.Mui-focused fieldset": {
        borderColor: "#000"
      }
    }
  },
  selectOptions: {
    "& .MuiOutlinedInput-input": {
      backgroundColor: "#fff",
      color: "#000",
    },
    "& .MuiInputLabel-root": {
      color: "#000"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: '-2px 2px 8px 1px #819EF0',
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#000"
    },
    "&:hover .MuiInputLabel-root": {
      color: "#000"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#F5F5F5",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F5F5F5"
    },
    '& .MuiMenu-paper': {
        borderRadius: '20px',
      },
  }
}));

const validateFields = (fields) => {
  const { selectedFrequency, selectedDate, selectedTimeZone, category, description, imageData, seriesName, selectedAutomate, sectionImage, tagsOne, tagsTwo, tagsThree } = fields
  let error = ""
  if(seriesName === "") {
    error += "Please enter a series name.\n"
  }
  if(selectedFrequency === "" || !selectedFrequency) {
    error += "Please select a frequency.\n"
  }
  const validDate = new Date(selectedDate);
  if(selectedDate === "" || isNaN(validDate.getTime())) {
    error += "The date is not in the expected format.\n"
  }
  if(selectedTimeZone === "" || !selectedTimeZone) {
    error += "Please select a timezone.\n"
  }
  if(category === "") {
    error += "Please select a category.\n"
  }
  if(imageData === null) {
    error += "Please provide an thumbnail for you series.\n"
  }
  if(description === "") {
    error += "Please provide a series description.\n"
  }
  if(selectedAutomate === "1") {
    // if(sectionImage === "") {
    //   error += `Please provide a description for "Enter Image Description".\n`
    // }
    if(tagsOne.length === 0) {
      error += "Please provide a keywords for Section 1.\n"
    }
    if(tagsTwo.length === 0) {
      error += "Please provide a keywords for Section 2.\n"
    }
    if(tagsThree.length === 0) {
      error += "Please provide a keywords for Section 3.\n"
    }
  }
  return error
}

const ColorToggleButton = styled(ToggleButton)(({ activeColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
      color: "#000",
      backgroundColor: "#819EF0"
  }, 
  fontWeight: "bold"
}));

const getSelectedAutomateButtons = (selectedMetric, handleMetricChange, activeColor, scheduleTypeRef) => {
  return (
      <ToggleButtonGroup
          ref={scheduleTypeRef}
          value={selectedMetric}
          exclusive
          onChange={handleMetricChange}
          style={{
            height: "58px",
            marginTop: "16px",
            width: "100%",
            borderColor: "#F5F5F5",
            borderRadius: "12px",
            boxShadow: '-2px 2px 8px 1px #819EF0',
          }}
          >
          <ColorToggleButton style={{width: "100%", fontSize: "14px", borderRadius: "12px 0px 0px 12px"}} value="0">Schedule Content</ColorToggleButton>
          <ColorToggleButton style={{width: "100%", fontSize: "14px", borderRadius: "0px 12px 12px 0px"}} value="1">Automate Content</ColorToggleButton>
      </ToggleButtonGroup>
    );
}

const iconToDataURL = (icon) => {
  const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="40px" height="40px">
      ${icon}
    </svg>
  `;
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  const dataURL = URL.createObjectURL(blob);
  return dataURL;
};

const SeriesBuilder = ({dialogTutorialMetadata, user, appBarHeight, isLoggedIn, dispatch}) => {
  const [allTemplates, setAllTemaplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [imageData, setImageData] = useState(null)
  const [imageColors, setImageColors] = useState(null)
  const [allSeries, setAllSeries] = useState(null)
  const [seriesName, setSeriesName] = useState("")
  const [sectionImage, setSectionImage] = useState("")
  const [sectionOne, setSectionOne] = useState("")
  const [sectionTwo, setSectionTwo] = useState("")
  const [sectionThree, setSectionThree] = useState("")
  const [selectedSeries, setSelectedSeries] = useState(null)
  const [category, setCategory] = useState("")
  const [description, setDescription] = useState("")
  const [loadingSeriesManager, setLoadingSeriesManager] = useState(false)
  const [openDelete, setOpenDelete] = useState(false);
  const [snackProps, setSnackProps] = useState({
    message: "",
    open: false,
    severity: "error"
  });
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingTest, setLoadingTest] = useState(false)
  const [activeColor, setActiveColor] = useState("#819EF0")
  const [seenTutorials, setSeenTutorials] = useState(false)
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [selectedAutomate, setSelectedAutomate] = useState("0");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [selectedFrequency, setSelectedFrequency] = useState('daily');
  const [tagsOne, setTagsOne] = useState([]);
  const [tagsTwo, setTagsTwo] = useState([]);
  const [tagsThree, setTagsThree] = useState([]);

  const [cropPopupOpen, setCropPopupOpen] = useState(false);
  const [croppedImageData, setCroppedImageData] = useState(null);

  const { tier, userId, userName, email } = user

  const handleAutomateChange = (event, newState) => {
    if(!newState) return
    setSelectedAutomate(newState);
  };

  const handleCloseSnack = () => {
    setSnackProps({
      message: "",
      open: false,
      severity: "error"
    })
  }

  const onChangeDescription = (e) => {
    setDescription(e.target.value)
  }

  const onChangeSeriesName = (e) => {
    setSeriesName(e.target.value)
  }

  const onChangeSectionImage = (e) => {
    setSectionImage(e.target.value)
  }

  const onChangeSectionOne = (e) => {
    setSectionOne(e.target.value)
  }

  const onChangeSectionTwo = (e) => {
    setSectionTwo(e.target.value)
  }

  const onChangeSectionThree = (e) => {
    setSectionThree(e.target.value)
  }

  const onChangeCategory = (e) => {
    setCategory(e.target.value)
  }

  const onSelectFrequency = (freq) => {
    setSelectedFrequency(freq);
  };

  const onSelectDate = (startDate) => {
    setSelectedDate(startDate);
  };

  const onSelectTimeZone = (timeZone) => {
    setSelectedTimeZone(timeZone);
  };

  const onSelect = (template) => {
    const { templateId } = template
    const index = selectedTemplates.findIndex(selectedTemplate => selectedTemplate.templateId === templateId)
    let newList = [...selectedTemplates]
    if(index === -1) {
      newList.push(template)
    } else {
      const newListIndex = selectedTemplates.map(e => e.templateId).indexOf(templateId);
      newList.splice(newListIndex, 1)
    }
    setSelectedTemplates(newList);
  }

  const onSwapIndex = (indexA, indexB) => {
    const newList = [...selectedTemplates]
    const temp = newList[indexA];
    newList[indexA] = newList[indexB];
    newList[indexB] = temp;
    setSelectedTemplates(newList);
  }

  const handleUploadClick = event => {
    var file = event.target.files["0"];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      const base63String = reader.result
      setImageData(base63String)
    }
    setCropPopupOpen(true)
  };

  const setColors = (colors) => {
    setImageColors([...colors])
  }

  const onSaveSeries = async () => {
    if(!isLoggedIn) {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
      return
    }
    
    if(tier === "FREE") {
      setOpenConfirm(true)
      return
    }

    const errors = validateFields({
      seriesName,
      selectedFrequency,
      selectedDate,
      selectedTimeZone,
      imageData,
      category,
      description,
      selectedAutomate,
      sectionImage,
      tagsOne,
      tagsTwo,
      tagsThree
    })
    if(errors !== "") {
      setSnackProps({
        open: true,
        message: errors,
        severity: "error"
      })
      return
    }
    //Check if all fields are Strings otherwise display error banner
    //Check if image is selected
    //Check if templates are selected
    try { 
      const body = {
        userName,
        userId, 
        seriesId: selectedSeries ? selectedSeries.seriesId : null, 
        seriesName, 
        category, 
        frequency: selectedFrequency, 
        startDate: selectedDate, 
        selectedTimeZone, 
        selectedTemplates, 
        imageData,
        backgroundColors: imageColors,
        description,
        automate: selectedAutomate === "1",
        sections: {
          sectionImage,
          sectionOne: tagsOne,
          sectionTwo: tagsTwo,
          sectionThree: tagsThree
        }
      }
      setLoadingSave(true)
      const allSeriesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/save-series', body, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
      const { data: { activeSeries, allSeries }} = allSeriesResult
      setAllSeries(allSeries)
      setSelectedSeries(activeSeries)
      setLoadingSave(false)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
      setLoadingSave(false)
    }
  }

  const handleSendToSelf = async (event) => {
    if(!isLoggedIn) {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
      return
    }
    
    if(tier === "FREE") {
      setOpenConfirm(true)
      return
    }

    const errors = validateFields({
      seriesName,
      selectedFrequency,
      selectedDate,
      selectedTimeZone,
      imageData,
      category,
      description,
      selectedAutomate,
      sectionImage,
      tagsOne,
      tagsTwo,
      tagsThree
    })
    if(errors !== "") {
      setSnackProps({
        open: true,
        message: errors,
        severity: "error"
      })
      return
    }
    try { 
      const body = {
        userName,
        userId, 
        seriesId: selectedSeries ? selectedSeries.seriesId : null, 
        seriesName, 
        category, 
        frequency: selectedFrequency, 
        startDate: selectedDate, 
        selectedTimeZone, 
        selectedTemplates, 
        imageUrl: imageData,
        backgroundColors: imageColors,
        description,
        automate: selectedAutomate === "1",
        senderEmail: email,
        sections: {
          sectionImage,
          sectionOne: tagsOne,
          sectionTwo: tagsTwo,
          sectionThree: tagsThree
        }
      }
      setLoadingTest(true)
      setSnackProps({
        open: true,
        message: "Request sent succesfully. This will take about 2 minutes to complete",
        severity: "success"
      })
      const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/send-email-to-self', body, {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
      })
      const { data: { creditsLeft }} = result
      if(creditsLeft) {
        setSnackProps({
          open: true,
          message: "Success! Check your inbox!",
          severity: "success"
        })
        dispatch({
          type: 'DEDUCT_CREDIT'
        })
      }
      setLoadingTest(false)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
      setLoadingTest(false)
    }
  }

  const onDeleteSeriesClick = async () => {
    if(!isLoggedIn) {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
      return
    }
    if(!selectedSeries || !selectedSeries.seriesId) {
      return
    }
    try { 
      const body = {
        userId, 
        seriesId: selectedSeries.seriesId
      }
      setLoadingDelete(true)
      const allSeriesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/delete-series', body, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
      const { data: { activeSeries, allSeries }} = allSeriesResult
      setAllSeries(allSeries)
      setSelectedSeries(activeSeries)
      setLoadingDelete(false)
    } catch (e) {
      // show alert dialog that failed 
      setLoadingDelete(false)
      console.log(e)
    }
  }

  const openDeleteDialog = () => {
    setOpenDelete(true)
  }

  const closeDeleteDialog = () => {
    setOpenDelete(false)
  }

  const createSeries = () => {
    setSeriesName("")
    setSelectedFrequency('daily')
    setSelectedDate(moment())
    setSelectedTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    setCategory("")
    setSelectedTemplates([])
    setImageData(null)
    setImageColors(null)
    setSelectedSeries(null)
    setCroppedImageData(null)
    setDescription("")
    setSelectedAutomate("0")
    setSectionImage("")
    setSectionOne("")
    setTagsOne([])
    setSectionTwo("")
    setTagsTwo([])
    setSectionThree("")
    setTagsThree([])
  }

  const onSeriesSelect = (series) => {
    setSelectedSeries(series)
  }

  const classes = useStyles({activeColor})
  const {selectOptions} = classes
  
  useEffect(async () => {
    try { 
      if(!isLoggedIn) {
        return
      } 
      const body = {
          userId
      }
      const allTemplatesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-templates', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
          })

      const { data: { templates: allUserTemplates }} = allTemplatesResult

      setAllTemaplates(allUserTemplates)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
    }
  }, []);

  useEffect(async () => {
    try {
      if(!isLoggedIn) {
        return
      } 
      const body = {
          userId
      }
      setLoadingSeriesManager(true)
      const allSeriesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-series-by-user', body, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      const { data: { allSeries: allUserSeries, activeSeries }} = allSeriesResult
      setAllSeries(allUserSeries)
      setSelectedSeries(activeSeries)
      setLoadingSeriesManager(false)
    } catch (e) {
      // show alert dialog that failed 
      console.log(e)
      setLoadingSeriesManager(false)
    }
  }, []);

  useEffect(async () => {
    if(selectedSeries) {
      const { seriesName, frequency, startDate, selectedTimeZone: timeZone, category, selectedTemplates, imageUrl, description, automate, sections } = selectedSeries
      setSeriesName(seriesName)
      setSelectedFrequency(frequency || "daily")
      setSelectedDate(startDate || moment())
      setSelectedTimeZone(timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone)
      setCategory(category)
      setSelectedTemplates(selectedTemplates)
      setImageData(imageUrl)
      setDescription(description)
      setCroppedImageData(null)
      if(sections) {
        const { sectionImage, sectionOne, sectionTwo, sectionThree } = sections
        setSectionImage(sectionImage)
        setTagsOne(sectionOne || [])
        setTagsTwo(sectionTwo || [])
        setTagsThree(sectionThree || [])
      } else {
        setSectionImage("")
        setTagsOne([])
        setTagsTwo([])
        setTagsThree([])
      }
      if(automate) {
        setSelectedAutomate("1")
      } else {
        setSelectedAutomate("0")
      }
    }
  }, [selectedSeries]);

  const onConfirm = () => {
    navigate("/subscription-plan")
  }

  const handleClickClose = () => {
    setOpenConfirm(false)
  }

  const handleKeyDownSectionOne = (event) => {
    if (sectionOne.trim() !== "" && (event.key === "Enter" || event.type === "click")) {
      const newTagsOne = sectionOne.split(",").map((tag) => tag.trim());
      setTagsOne((prevTags) => [...prevTags, ...newTagsOne]);
      setSectionOne("");
    }
  };

  const handleDeleteTagsOne = (tag) => () => {
    setTagsOne((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const handleKeyDownSectionTwo = (event) => {
    if (sectionTwo.trim() !== "" && (event.key === "Enter" || event.type === "click")) {
      const newTagsTwo = sectionTwo.split(",").map((tag) => tag.trim());
      setTagsTwo((prevTags) => [...prevTags, ...newTagsTwo]);
      setSectionTwo("");
    }
  };

  const handleDeleteTagsTwo = (tag) => () => {
    setTagsTwo((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const handleKeyDownSectionThree = (event) => {
    if (sectionThree.trim() !== "" && (event.key === "Enter" || event.type === "click")) {
      const newTagsThree = sectionThree.split(",").map((tag) => tag.trim());
      setTagsThree((prevTags) => [...prevTags, ...newTagsThree]);
      setSectionThree("");
    }
  };

  const handleDeleteTagsThree = (tag) => () => {
    setTagsThree((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const seriesNameRef = useRef(null)
  const seriesFreqRef = useRef(null)
  const seriesCategoryRef = useRef(null)
  const templateSelectorRef = useRef(null)
  const seriesScheduleQueueRef = useRef(null)
  const seriesImageRef = useRef(null)
  const seriesDescriptionRef = useRef(null)
  const scrollListRef = useRef(null)
  const seriesTimeRef = useRef(null)
  const scheduleTypeRef = useRef(null)
  const seriesBuildRef = useRef(null)

  const refList = [
    seriesNameRef,
    seriesBuildRef,
    seriesImageRef,
    seriesNameRef, 
    seriesCategoryRef, 
    seriesFreqRef,
    seriesTimeRef,
    seriesDescriptionRef,
    scheduleTypeRef,
    templateSelectorRef, 
    seriesScheduleQueueRef, 
    seriesNameRef
  ]

  useEffect(() => {
    const { seen } = dialogTutorialMetadata["manager"]
    setSeenTutorials(seen) //Necassary, something happens with window focus if Tutorials are rendered (Disables pull to refresh) 
  }, [dialogTutorialMetadata]);

  const { open, message, severity } = snackProps
  const automateButtonGroup = getSelectedAutomateButtons(selectedAutomate, handleAutomateChange, activeColor, scheduleTypeRef)

  return (
    <Grid container ref={scrollListRef} className={classes.container} style={{overscrollBehaviorY: "auto", height: `calc(100vh - ${appBarHeight}px)`, overflowX: "none", overflowY: "auto"}} >
      <SEO
        title={`Pikkaboo's Series Builder: Effortlessly Create and Schedule Your Newsletters`}
        description={`Say goodbye to manual tasks and hello to streamlined efficiency with Pikkaboo's Series Builder makes it easy to create and schedule your newsletters, so you can focus on what you do best. Whether you want to send weekly updates, monthly newsletters, or anything in between, Pikkaboo has got you covered.`}
        name={`Series Builder`}
        type={`article`}
      />
      <Box style={{padding: "24px", width: "100%", marginBottom: "24px"}}>
        <Snackbar 
            sx={{ height: "100%" }} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center', }} 
            open={open} 
            autoHideDuration={3500} 
            onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} severity={severity} sx={{ whiteSpace: "pre-wrap", width: '100%' }}>
                {message} 
            </Alert>
        </Snackbar>
        <ConfirmDialog 
          onConfirm={onConfirm}
          handleClickClose={handleClickClose} 
          title={"Premium Feature"} 
          message={"Oops! This is a premium feature.\nSign up for a free trial today."} 
          open={openConfirm}
          confirmText={"Sign Up"}
          />
        {!seenTutorials ? <TutorialDialog scrollListRef={scrollListRef} refList={refList} page="manager" /> : undefined}
        <Grid container spacing={2}>
              <Grid item md={6} xs={12} style={{paddingLeft: '5%', paddingRight: '5%' }} order={{ xs: 2, md: 1 }}>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                      { "Series Name" }
                    </Typography>
                    <TextField
                      ref={seriesNameRef}
                      onChange={onChangeSeriesName}
                      value={seriesName}
                      margin="dense"
                      fullWidth
                      className={selectOptions}
                      style={{borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                      variant="outlined"
                      size="medium"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                      { "Category" }
                    </Typography>
                    <TextField
                        ref={seriesCategoryRef}
                        className={selectOptions}
                        value={category}
                        fullWidth
                        onChange={onChangeCategory}
                        variant="outlined"
                        select
                        size="medium"
                        style={{borderRadius: "4px", marginTop: "16px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                        SelectProps={{
                          MenuProps: { sx: { maxHeight: 250} }
                        }}
                    >
                      <MenuItem style={{color: "#000" }} value={"Food & Travel"}>Food & Travel</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Lifestyle & DIY"}>Lifestyle & DIY</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Business & Tech"}>Business & Tech</MenuItem>
                      <MenuItem style={{olor: "#000" }} value={"Music & Entertainment"}>Music & Entertainment</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Sports & Fitness"}>Sports & Fitness</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Health & Wellness"}>Health & Wellness</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Politics"}>Politics</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Energy & Environment"}>Energy & Environment</MenuItem>
                      <MenuItem style={{color: "#000" }} value={"Other"}>Other</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                  <DateTimeInput
                    seriesFreqRef={seriesFreqRef}
                    seriesTimeRef={seriesTimeRef}
                    onSelectDate={onSelectDate} 
                    onSelectFrequency={onSelectFrequency} 
                    onSelectTimeZone={onSelectTimeZone}
                    selectedDate={selectedDate}
                    selectedFrequency={selectedFrequency}
                    selectedTimeZone={selectedTimeZone}
                  />
                  <Typography component="div" style={{marginTop: "12px", fontSize: "20px", fontWeight: "bold"}}>
                   { "Series Description" }
                  </Typography>
                  <SeriesDescriptionInputBox seriesDescriptionRef={seriesDescriptionRef} activeColor={activeColor} loadingSeriesManager={loadingSeriesManager} onChangeDescription={onChangeDescription} description={description} appBarHeight={appBarHeight}/>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingLeft: '5%', paddingRight: '5%' }} order={{ xs: 1, md: 2 }}>
                  <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                    { "Create Series" }
                  </Typography>
                  <LoadingButton
                    ref={seriesBuildRef}
                    onClick={createSeries}
                    loadingPosition="start"
                    startIcon={<ConstructionIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        marginTop: "16px",
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "100%",
                        maxWidth: "420px ",
                        borderColor: "#F5F5F5",
                        borderRadius: "12px",
                        height: "58px",
                        boxShadow: '-2px 2px 8px 1px #819EF0',
                    }}
                  >
                    Create New Series
                  </LoadingButton>
                  <Typography component="div" style={{marginTop: 24,fontSize: "20px", fontWeight: "bold"}}>
                    { "Select Series" }
                  </Typography>
                  <SeriesSelector selectedSeries={selectedSeries} activeColor={activeColor} loadingSeriesManager={loadingSeriesManager} onCreateClick={createSeries} onSeriesSelect={onSeriesSelect} allSeries={allSeries} appBarHeight={appBarHeight} />
                  <Box style={{padding: "4px 0px 12px 0px", width: "100%", display: "flex", justifyContent: "flex-start"}}>
                    <CropModel 
                      cropPopupOpen={cropPopupOpen}
                      imageData={imageData}
                      setCropPopupOpen={setCropPopupOpen}
                      setCroppedImageData={setCroppedImageData}
                      />
                    <UploadCroppedImage seriesImageRef={seriesImageRef} handleUploadClick={handleUploadClick} imageData={imageData} croppedImageData={croppedImageData}/>
                  </Box>
                </Grid>
          </Grid>
          <Grid spacing={2} container direction="row">
              <Grid item md={6} sm={12} xs={12} style={{marginTop: 12, padding: "0px 5% 0px 5%"}}>
                <Typography component="div" style={{ marginTop: 16, fontSize: "20px", fontWeight: "bold"}}>
                  { "Schedule Type" }
                </Typography>
                  {automateButtonGroup}
              </Grid>
              <Grid item md={6} sm={12} xs={12} style={{alignSelf: "flex-end", marginTop: 24, padding: "0px 5% 0px 5%"}} order={{ xs: 3, sm: 3, md: 1 }}>
                <SeriesActions loadingSave={loadingSave} loadingDelete={loadingDelete} onSaveClick={onSaveSeries} onDeleteClick={openDeleteDialog}/>
                <DeleteSeriesDialog selectedSeries={selectedSeries} openDelete={openDelete} onDeleteSeriesClick={onDeleteSeriesClick} closeDelete={closeDeleteDialog}/>
              </Grid>
            { selectedAutomate === "0" ? 
            <>
            <Grid item md={6} sm={12} xs={12} style={{marginTop: 24, padding: "0px 5% 0px 5%"}} order={{ xs: 1, sm: 1, md: 1 }}>
              <Typography component="div" style={{ fontSize: "20px", fontWeight: "bold"}}>
                { "Templates" }
              </Typography>
              <ManagerSeriesContentList templateSelectorRef={templateSelectorRef} activeColor={activeColor} loadingSeriesManager={loadingSeriesManager} templates={allTemplates} onSelect={onSelect} selectedTemplates={selectedTemplates} appBarHeight={appBarHeight}/>
            </Grid>
            <Grid item md={6} sm={12} xs={12}  style={{marginTop: 24, padding: "0px 5% 0px 5%"}} order={{ xs: 2, sm: 2, md: 3 }}>
              <Typography component="div" style={{ fontSize: "20px", fontWeight: "bold"}}>
                { "Series Schedule Queue" }
              </Typography>
              <SeriesContentQueue seriesScheduleQueueRef={seriesScheduleQueueRef} selectedDate={selectedDate} selectedFrequency={selectedFrequency} selectedTimeZone={selectedTimeZone} activeColor={activeColor} loadingSeriesManager={loadingSeriesManager} selectedTemplates={selectedTemplates} appBarHeight={appBarHeight} onSwapIndex={onSwapIndex}/>
            </Grid>
            </> :
            <>
            <Grid item md={6} sm={12} xs={12}  style={{marginTop: 24, padding: "0px 5% 0px 5%"}} order={{ xs: 1, sm: 1, md: 1 }}>
              <Typography component="div" style={{ fontSize: "20px", fontWeight: "bold"}}>
                { "Template Sections" }
              </Typography>
              <Box 
                ref={templateSelectorRef}
                className={classes.container} 
                style={{
                  borderColor: "#F5F5F5",
                  borderRadius: "20px", 
                  boxShadow: '-2px 2px 8px 1px #819EF0',
                  padding: "10px",
                  marginTop: 16, background: "#fff", height: 300, overflowY: "scroll"
                  }}>
            
                    <Typography component="div" style={{ padding: "4px 4px 0px 10px", fontSize: "16px", fontWeight: "bold"}}>
                      { "Section One" }
                    </Typography>
                    <Box style={{ 
                      display: "flex", 
                      backgroundColor: "#fff",
                      color: "#000",
                      borderColor: "#F5F5F5",
                      borderRadius: "10px",
                      borderColor: "#F5F5F5",
                      color: "#000",
                      margin: 8}}>
                    
                        <TextField
                          onChange={onChangeSectionOne}
                          value={sectionOne}
                          fullWidth
                          className={selectOptions}
                          style={{borderColor: "transparent", borderRadius: "10px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                          size="small"
                          onKeyDown={handleKeyDownSectionOne}
                        />
                        <Button onClick={handleKeyDownSectionOne} style={{borderRadius: "10px", backgroundColor: activeColor, padding: "10px 0px 10px 0px", marginLeft: "8px", color: "#000", fontSize: 12, alignSelf: "center", boxShadow: '-1px 1px 6px 1px #819EF0',  }}>
                          Add Tag
                        </Button>
                      </Box>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {tagsOne.map((tag, index) => (
                          <Chip key={`1 - ${tag} - ${index}`} label={tag} style={{ borderRadius: "14px", backgroundColor: activeColor, boxShadow: '-1px 1px 6px 1px #819EF0', margin: 8 }} onDelete={handleDeleteTagsOne(tag)} deleteIcon={<Cancel style={{color: "#000"}}/>} />
                        ))}
                      </div>
                      <Typography component="div" style={{ padding: "4px 4px 0px 10px", fontSize: "16px", fontWeight: "bold"}}>
                        { "Section Two" }
                      </Typography>
                      <Box style={{ 
                        display: "flex", 
                        backgroundColor: "#fff",
                        color: "#000",
                        borderColor: "#F5F5F5",
                        borderRadius: "10px",
                        borderColor: "#F5F5F5",
                        color: "#000",
                        margin: 8}}>
                      <TextField
                        onChange={onChangeSectionTwo}
                        value={sectionTwo}
                        fullWidth
                        className={selectOptions}
                        style={{borderColor: "transparent", borderRadius: "10px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                        size="small"
                        onKeyDown={handleKeyDownSectionTwo}
                      />
                        <Button onClick={handleKeyDownSectionTwo} style={{borderRadius: "10px", backgroundColor: activeColor, padding: "10px 0px 10px 0px", marginLeft: "8px", color: "#000", fontSize: 12, alignSelf: "center", boxShadow: '-1px 1px 6px 1px #819EF0',  }}>
                          Add Tag
                        </Button>
                      </Box>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tagsTwo.map((tag, index) => (
                        <Chip  key={`2 - ${tag} - ${index}`} label={tag} style={{ backgroundColor: activeColor, margin: "8px" }} onDelete={handleDeleteTagsTwo(tag)} deleteIcon={<Cancel style={{color: "#000"}}/>}/>
                      ))}
                    </div>
                    <Typography component="div" style={{ padding: "4px 4px 0px 10px", fontSize: "16px", fontWeight: "bold"}}>
                      { "Section Three" }
                    </Typography>
                    <Box style={{ 
                      display: "flex", 
                      backgroundColor: "#fff",
                      color: "#000",
                      borderColor: "#F5F5F5",
                      borderRadius: "10px",
                      borderColor: "#F5F5F5",
                      color: "#000",
                      margin: 8}}>
                      <TextField
                        onChange={onChangeSectionThree}
                        value={sectionThree}
                        fullWidth
                        className={selectOptions}
                        style={{borderColor: "transparent", borderRadius: "10px", width: "100%", alignSelf: "center", backgroundColor: "#fff"}}
                        size="small"
                        onKeyDown={handleKeyDownSectionThree}
                      />
                        <Button onClick={handleKeyDownSectionThree} style={{borderRadius: "10px", backgroundColor: activeColor, padding: "10px 0px 10px 0px", marginLeft: "8px", color: "#000", fontSize: 12, alignSelf: "center", boxShadow: '-1px 1px 6px 1px #819EF0',  }}>
                          Add Tag
                        </Button>
                    </Box>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {tagsThree.map((tag, index) => (
                        <Chip key={`3 - ${tag} - ${index}`} label={tag} style={{ backgroundColor: activeColor, margin: "8px" }} onDelete={handleDeleteTagsThree(tag)} deleteIcon={<Cancel style={{color: "#000"}} />}/>
                      ))}
                    </div>
                </Box>
                <Box style={{marginTop: "12px", display: "flex", justifyContent: "center"}}>
                  <LoadingButton
                    onClick={handleSendToSelf}
                    loading={loadingTest}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                    style={{
                        maxWidth: "300px",
                        fontSize:"16px",
                        marginLeft: "12px",
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "100%",
                        borderColor: "#F5F5F5",
                        borderRadius: "12px",
                        marginTop: "12px", 
                        height: "58px",
                        boxShadow: '-2px 2px 8px 1px #819EF0',
                    }}
                    >
                    Email to Self
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item md={6} sm={12} xs={12}  style={{marginTop: 24, padding: "0px 5% 0px 5%"}} order={{ xs: 2, sm: 2, md: 3 }}>
                <Typography component="div" style={{ fontSize: "20px", fontWeight: "bold"}}>
                  { "Series Schedule Queue" }
                </Typography>
                <SeriesContentQueue 
                  seriesScheduleQueueRef={seriesScheduleQueueRef}
                  selectedDate={selectedDate} 
                  selectedFrequency={selectedFrequency} 
                  selectedTimeZone={selectedTimeZone} 
                  activeColor={activeColor} 
                  loadingSeriesManager={loadingSeriesManager} 
                  selectedTemplates={[
                    { templateName: "Generated Template 1", templateId: "Template Id 1" },
                    { templateName: "Generated Template 2", templateId: "Template Id 2" },
                    { templateName: "Generated Template 3", templateId: "Template Id 3" },
                    { templateName: "Generated Template 4", templateId: "Template Id 4" },
                    { templateName: "Generated Template 5", templateId: "Template Id 5" }]} 
                  appBarHeight={appBarHeight}
                  hideSwap={true}/>
            </Grid>
          </>}
          </Grid> 
          </Box>
        <Footer excludeHeight={true}/>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

const mapStateToProps = state => {
  return { 
      dialogTutorialMetadata: state.dialogTutorialMetadata
   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeriesBuilder)