import { useState, useEffect } from 'react';
import { Box, Grid, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveDialog from "./SaveDialog";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { borderRadius } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important"
        }
      },
    colorWhite: {
        color: "#fff"
    },
    colorBlack: {
        color: "#000"
    },
    container: {
        '&::-webkit-scrollbar': {
            height: "10px",
            backgroundColor: ({showStudioButtons})=> showStudioButtons ? "#232E3F" : "white",
          },
          '&::-webkit-scrollbar-track':{
            borderRadius: "8px",
            backgroundColor: ({showStudioButtons})=> showStudioButtons ? "#232E3F" : "white",
            margin:12
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: ({showStudioButtons})=> showStudioButtons ? "white" :  "#819EF0",
            boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "6px",
            border: ({showStudioButtons})=> showStudioButtons ? "1px #232E3F solid" :  "#819EF0"
          },
          /* Opera doesn't support this in the shorthand */
          backgroundAttachment: "local, local, scroll, scroll" 
    },
    inputColor: {
        "& label.Mui-focused": {
          color: "#000"
        },
        "& label": {
          color: "#000"
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
          borderBottomColor: "#000"
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: "#000"
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
            backgroundColor: 'white',
            border: '0px solid white',
            borderRadius: '16px',
            borderColor: "#F5F5F5",
            boxShadow: '0px 0px 12px 1px #F5F5F5',
            "&:hover": {
                borderColor: "#F5F5F5",
            }
          }
      },
  }));

export function TemplateActions({
    onSendToSelfClick,
    generateRef,
    onGenerateClick,
    onSaveClick, 
    onLoadClick, 
    onExportClick, 
    onCopyClick, 
    onDeleteClick, 
    onEditClick, 
    loadingSave, 
    loadingLoad, 
    loadingExport, 
    loadingCopy, 
    loadingDelete, 
    loadingEdit, 
    loadingSendEmailToSelf,
    selectedTemplate, 
    templates, 
    toggleDrawerMyTemplates,
    showStudioButtons,
    clearCanvas,
    showAiGeneratorButtons,
    loadingGenerate,
    onChangeTitle,
    title,
    onGenerateTemplate
}) {
    const [template, setTemplate] = useState(selectedTemplate);
    const [openSave, setOpenSave] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const classes = useStyles({showStudioButtons})

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };
  
    const handleClickCloseSave = () => {
        setOpenSave(false);
    };

    const handleClickOpenDelete = () => {
        if(selectedTemplate) {
            setOpenDelete(true);
        }
    };
  
    const handleClickCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpenEdit = () => {
        if(selectedTemplate) {
            setOpenEdit(true);
        }
    };
  
    const handleClickCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClickToggleDrawerMyTemplates = () => {
        toggleDrawerMyTemplates(true)
    }

    const handleClickClearCanvas = () => {
        clearCanvas()
    }

    const handleClickGenerateTemplate = () => {
        onGenerateTemplate(title)
    }

    const onChangeTemplateTitle = (e) => {
        onChangeTitle(e.target.value)
      }

    useEffect(() => {
        setTemplate(selectedTemplate)
      }, [selectedTemplate]);

    return (
    <Box className={classes.container} style={{overflow: "auto", width: "100%", display: "flex", padding: "16px 0px 0px 0px"}} direction="row">  
        { showStudioButtons ? 
            <Box style={{display: "flex", flexDirection: "row"}}>
                <TextField
                    ref={generateRef}
                    onChange={onChangeTemplateTitle}
                    value={title}
                    autoFocus
                    label="Template Title"
                    fullWidth
                    className={classes.inputColor}
                    style={{
                        width: 288, 
                        margin:"0px 0px 12px 14px", 
                        borderRadius: '16px',
                        borderColor: "#F5F5F5",
                    }}
                    variant="outlined"
                    size="medium"
                    InputProps={{
                        onKeyDown: (e) => {
                            if (e.key === "Enter") {
                                handleClickGenerateTemplate();
                            }
                        },
                        maxLength: 120,
                        endAdornment: (
                            <InputAdornment position="end">
                                <LoadingButton
                                    onClick={handleClickGenerateTemplate}
                                    loadingPosition="start"
                                    loading={loadingGenerate}
                                    startIcon={<AutoFixHighIcon />}
                                    variant="contained"
                                    style={{
                                        fontSize:"12px",
                                        padding: 8,
                                        color: "#000",
                                        boxShadow: "none",
                                        backgroundColor: 'white',
                                        border: '0px solid white',
                                        borderRadius: '16px',
                                        borderColor: "#F5F5F5",
                                    }}
                                >
                                    <Typography>Generate</Typography>
                                </LoadingButton>
                            </InputAdornment>
                        ),
                    }}
                />

            </Box> : null
        }
        { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={onSendToSelfClick}
                    loading={loadingSendEmailToSelf}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        color: "#000",
                        width: "200px",
                        marginLeft: "16px",
                        backgroundColor: 'white',
                        border: '1px solid white',
                        borderRadius: '16px',
                        borderColor: "#F5F5F5",
                        boxShadow: '0px 0px 4px 1px #F5F5F5',
                    }}
                >
                    Email to Self
                </LoadingButton>
            </Box> : null
        }
        { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={handleClickToggleDrawerMyTemplates}
                    loadingPosition="start"
                    startIcon={<DynamicFeedIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        color: "#000",
                        width: "200px",
                        marginRight: "16px",
                        marginLeft: "16px",
                        backgroundColor: 'white',
                        border: '1px solid white',
                        borderRadius: '16px',
                        borderColor: "#F5F5F5",
                        boxShadow: '0px 0px 4px 1px #F5F5F5',
                    }}
                >
                    View Templates
                </LoadingButton>
            </Box> : null
        }
        { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={handleClickOpenSave}
                    loading={loadingSave}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        color: "#000",
                        height: 54,
                        width: "128px",
                        marginRight: "16px",                        
                        backgroundColor: 'white',
                        border: '1px solid white',
                        borderRadius: '16px',
                        borderColor: "#F5F5F5",
                        boxShadow: '0px 0px 4px 1px #F5F5F5',
                    }}
                >
                Save As
                </LoadingButton>
                <SaveDialog openSave={openSave} onSaveClick={onSaveClick} handleClickClose={handleClickCloseSave}/>
            </Box> : null
        }
        {/* { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={handleClickSendTestEmailToSelf}
                    loading={loadingSendEmailToSelf}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        backgroundColor: "#fff",
                        color: "#000",
                        width: "180px",
                        marginRight: "16px"
                    }}
                >
                    Send to Self
                </LoadingButton>
            </Box> : null
        } */}
        { !showStudioButtons ?
            <Box>
                <LoadingButton
                    onClick={onLoadClick}
                    loading={loadingLoad}
                    loadingPosition="start"
                    startIcon={<DriveFolderUploadIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "112px",
                        marginRight: "16px",
                        marginBottom: "12px",
                        marginLeft: "12px",
                        boxShadow: '0px 0px 4px 1px #819EF0',
                        borderRadius: 12
                    }}
                >
                    Load
                </LoadingButton>
            </Box> : null
        }
        { !showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={onExportClick}
                    loading={loadingExport}
                    loadingPosition="start"
                    startIcon={<DownloadIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "150px",
                        marginRight: "16px",
                        boxShadow: '0px 0px 4px 1px #819EF0',
                        borderRadius: 12
                    }}
                >
                    Download
                </LoadingButton>
            </Box> : null
        }
         { !showStudioButtons && !showAiGeneratorButtons ?
            <Box>
                <LoadingButton
                    onClick={onCopyClick}
                    loading={loadingCopy}
                    loadingPosition="start"
                    startIcon={<ContentCutIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "112px",
                        marginRight: "16px",
                        boxShadow: '0px 0px 4px 1px #819EF0',
                        borderRadius: 12
                    }}
                >
                    Copy
                </LoadingButton>
            </Box> : null 
        }   
         { !showStudioButtons && !showAiGeneratorButtons ?
            <Box>
                <LoadingButton
                    onClick={handleClickOpenDelete}
                    loading={loadingDelete}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        height: 54,
                        backgroundColor: "#819EF0",
                        color: "#000",
                        width: "128px",
                        marginRight: "16px",
                        boxShadow: '0px 0px 4px 1px #819EF0',
                        borderRadius: 12
                    }}
                >
                    Delete
                </LoadingButton>
                <DeleteDialog selectedTemplate={template} openDelete={openDelete} onDeleteClick={onDeleteClick} handleClickClose={handleClickCloseDelete}/>
            </Box> : null
        }
         { !showStudioButtons && !showAiGeneratorButtons ?
            <Box>
                <LoadingButton
                    onClick={handleClickOpenEdit}
                    loading={loadingEdit}
                    loadingPosition="start"
                    startIcon={<EditIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        backgroundColor: "#819EF0",
                        color: "#000",
                        height: 54,
                        width: "156px",
                        marginRight: 12,
                        boxShadow: '0px 0px 4px 1px #819EF0',
                        borderRadius: 12
                    }}
                >
                    Edit Name
                </LoadingButton>
                <EditDialog templates={templates} selectedTemplate={template} openEdit={openEdit} onEditClick={onEditClick} handleClickClose={handleClickCloseEdit}/>  
            </Box> : null 
        }
        { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={handleClickClearCanvas}
                    loadingPosition="start"
                    startIcon={<DeleteForeverIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        color: "#000",
                        width: "180px",
                        height: 54,
                        marginRight: "16px",
                        backgroundColor: 'white',
                        border: '1px solid white',
                        borderRadius: '16px',
                        borderColor: "#F5F5F5",
                        boxShadow: '0px 0px 4px 1px #F5F5F5',
                    }}
                >
                    Clear Canvas
                </LoadingButton>
            </Box> : null
        }
        {/* { showStudioButtons ? 
            <Box>
                <LoadingButton
                    onClick={handleClickGenerateTemplate}
                    loadingPosition="start"
                    startIcon={<AutoFixHighIcon />}
                    variant="contained"
                    style={{
                        fontSize:"16px",
                        backgroundColor: "#fff",
                        color: "#000",
                        width: "240px"
                    }}
                >
                    Generate Template
                </LoadingButton>
            </Box> : null
        } */}
    </Box>
    );
}