import * as React from 'react';
import { Box, Grid, Typography } from "@material-ui/core";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState} from "react"
import LoadingIndicator from './LoadingIndicator';
import * as XLSX from 'xlsx';
import { Button, TextField, MenuItem } from '@mui/material';

Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important",
            marginRight: "8px"
        }
      },
    colorRemove: {
        color: "#000",
        backgroundColor: ({activeColor})=> activeColor
    },
    colorBlack: {
        color: "#000"
    },
    container: {
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "4px",
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: ({activeColor})=> activeColor,
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "4px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    },
    selectOptions: {
      "& .MuiOutlinedInput-input": {
        backgroundColor: "#fff",
        color: "#000",
      },
      "& .MuiInputLabel-root": {
        color: "#000"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F5F5F5",
        borderRadius: "10px",
        boxShadow: '-2px 2px 8px 1px #819EF0',
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "#000"
      },
      "&:hover .MuiInputLabel-root": {
        color: "#000"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F5F5F5"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#000"
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#F5F5F5",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F5F5F5"
      },
      '& .MuiMenu-paper': {
          borderRadius: '20px',
        },
    }
  }));
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

const getListItems = (users, handleListItemClick, classes, selectedUsers) => {
    const { colorRemove, colorBlack } = classes
    return users.sort((a,b) => a.email.localeCompare(b.email)).map((user, index) => {
        const { userId, email } = user
        const isSelected = selectedUsers.findIndex(selectedUser => selectedUser.email === email) !== -1
       
        return <Box style={{  
                  backgroundColor: "#fff",
                  color: "#000",
                  borderColor: "#F5F5F5",
                  borderRadius: "10px",
                  boxShadow: '-1px 1px 2px 1px #819EF0',
                  borderColor: "#F5F5F5",
                  color: "#000",
                  margin: 8,
                  }} key={`userList-${index}`}>
                  <ListItemButton
                      style={{wordBreak: "break-word" }}
                      key={userId || index}
                      onClick={() =>handleListItemClick(index, user)} 
                  >
                      <ListItemText primaryTypographyProps={{ height: "100%", alignSelf: "center"}} primary={email} />
                      <ListItemIcon style={{justifyContent: "end"}} >
                          {isSelected ? <RemoveIcon style={{borderRadius: "4px", height: "20px", width: "20px"}} className={colorRemove}/> : <AddIcon style={{height: "20px", width: "20px"}} className={colorBlack}/>  }
                      </ListItemIcon>
                  </ListItemButton>
                </Box>
    })
}

export function UserEmailList({
  onHandleUploadedUsers, 
  upload, 
  selector,
  onSeriesSelect,
  userSeries,
  selectedSeries,
  activeColor, 
  onSelect, 
  users, 
  selectedUsers, 
  loadingSeriesManager,
  onSelectAll,
  onClearAll
}) {

  const handleUpload = (event) => {
    var file = event.target.files["0"];
    var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData
        try {
          readedData = XLSX.read(data, {type: 'binary'});
        } catch(error) {
          onHandleUploadedUsers([], error)
          return
        }
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        const uploadedEmails = []
        const users = []
        dataParse.forEach(row => {
          row.forEach(column => {
            if(column && typeof column === "string" ) {
              const cell = column.toString().replace(/\s+/g, '').toLowerCase()
              if(cell && validateEmail(cell) && !uploadedEmails.includes(cell)) {
                uploadedEmails.push(cell)
              } 
            }
          })
        })
        uploadedEmails.forEach(email => {
          users.push({email})
        })
        onHandleUploadedUsers(users, null)
    };
    reader.readAsBinaryString(file)

  }

  const handleListItemClick = (index, user) => {
    onSelect(user)
  };

  const onChangeSelectedSeries = (e) => {
    if(!userSeries) return
    const selectedSeries = userSeries.filter(series => {
      return series.seriesName === e.target.value
    })
    if(!selectedSeries) return
    onSeriesSelect(selectedSeries[0])
  }

  const getAllMenuItems = (userSeries) => {
    if(userSeries) {
      return userSeries.map((series) => {
        return <MenuItem key={series.seriesId} style={{color: "#000" }} value={series.seriesName}>{series.seriesName}</MenuItem>
      })
    }
    return []
  }

  const handleClickAll = () => {
    onSelectAll(users)
  }

  const handleClearAll = () => {
    onClearAll(users)
  }

  const classes = useStyles({activeColor})
  const { selectOptions, colorRemove, colorBlack } = classes
  const listItems = getListItems(users, handleListItemClick, classes, selectedUsers)
  const selectedSeriesName = selectedSeries ? selectedSeries.seriesName : ""
  const seriesMenuList = userSeries && userSeries.length > 0 ? getAllMenuItems(userSeries) : <MenuItem key={"series-placeholder"} style={{color: "#000" }} value={"series-placeholder"}>{"No Series Created"}</MenuItem>
  return (
    <Box>
        {upload ? 
        <Box style={{
          cursor: "pointer",
          fontSize:"16px",
          backgroundColor: "#819EF0",
          color: "#000",
          width: "100%",
          borderColor: "#F5F5F5",
          borderRadius: "12px",
          marginTop: "20px", 
          height: "58px",
          boxShadow: '-2px 2px 8px 1px #819EF0',
          maxWidth: 600,
        }}>
        <input
          accept=".xlsx, .xls, .csv"
          style={{display: "none"}}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUpload}
        />
        <Button htmlFor="contained-button-file" style={{cursor: "pointer", fontSize: 16, fontWeight: "bold", color: "#000", height: "100%", width: "100%"}}>
          <label style={{width: "100%", cursor: "pointer"}} htmlFor="contained-button-file">
              UPLOAD
          </label>
        </Button>
      </Box> : null}
      {selector ? 
        <Box style={{marginTop: 20}}>
          <TextField
              className={selectOptions}
              value={selectedSeriesName}
              fullWidth
              onChange={onChangeSelectedSeries}
              variant="outlined"
              select
              size="medium"
              style={{ maxWidth: 600, color: "#000", width: "100%",}}
              SelectProps={{
                MenuProps: { sx: { maxHeight: 250} }
              }}
            
          >
            {seriesMenuList}
          </TextField>
        </Box>
        : null}
      <Box 
        className={classes.container} 
        style={{ 
          marginTop: 20, 
          borderRadius: "12px", 
          borderColor: "#F5F5F5",
          borderRadius: "10px",
          boxShadow: '-2px 2px 8px 1px #819EF0',
          height: 250, 
          overflowY: "scroll",
          maxWidth: 600,
        }}>
        { loadingSeriesManager ? <LoadingIndicator loading={loadingSeriesManager}/> : 
          <List style={{borderRadius: "4px", paddingTop: "4px", paddingBottom: 0}}>
            {listItems}
          </List>
        }
      </Box>
      {listItems.length > 0 ?
      <Grid container spacing={2} style={{marginTop: 12, maxWidth: "612px"}}>
        <Grid item xs={12} md={6}>
          <Button variant="contained" style={{
            fontSize:"16px",
            backgroundColor: "#819EF0",
            color: "#000",
            width: "100%",
            borderColor: "#F5F5F5",
            borderRadius: "12px",
            height: "58px",
            boxShadow: '-2px 2px 8px 1px #819EF0'
            }} onClick={handleClickAll}>Add All</Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" style={{
            fontSize:"16px",
            backgroundColor: "#819EF0",
            color: "#000",
            width: "100%",
            borderColor: "#F5F5F5",
            borderRadius: "12px",
            height: "58px",
            boxShadow: '-2px 2px 8px 1px #819EF0'
            }} onClick={handleClearAll}>Clear All</Button>
        </Grid>
      </Grid>
      : null }
    </Box>
      );
    }