import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box, FormControl, OutlinedInput, TextField, FilledInput } from '@mui/material';
import { InputLabel } from '@material-ui/core';
import { useState } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


const useStyles = makeStyles((theme) => ({
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

export default function TipSeriesDialog({openTipSeries, onTipSeriesClick, handleClickClose, series}) {
  const classes = useStyles()
  const [amount, setAmount] = useState("")
  const { primary } = classes
  const handlePayClick = () => {
    const isNum = /^\d+$/.test(amount);
    if(isNum && parseInt(amount) != 0) {
      onTipSeriesClick(true, amount)
    } else {
      onTipSeriesClick(false)
    }
    setAmount("")
    handleClickClose()
  }
  const handleChange = (e) => {
    setAmount(e.target.value)
  }
  return (
    <div>
      <Dialog open={openTipSeries} onClose={handleClickClose}>
          <DialogTitle className={primary}>
            <Box style={{marginBottom: "0px", display: "flex", justifyContent: "start", alignItems: "center"}}>
              <MonetizationOnIcon style={{marginRight: "8px", fontSize: "24px", color: "#118C4F"}}/>
              {"Donate"}
            </Box>
          </DialogTitle>
        <DialogContent>
          <FormControl variant="filled">
              <InputLabel style={{color: "#118C4F"}} htmlFor="outlined-adornment-amount">Amount</InputLabel>
              <OutlinedInput
                color="success"
                id="outlined-adornment-amount"
                value={amount}
                onChange={handleChange}
                style={{color: "#118C4F"}}
                startAdornment={<Typography style={{color: "#118C4F"}}>$</Typography>}
                label="Amount"
              />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor: "#819EF0"}} className={primary} onClick={handleClickClose}>Cancel</Button>
          <Button style={{backgroundColor: "#90ee90"}} className={primary} onClick={handlePayClick}>Donate</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}