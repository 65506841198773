import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

export default function UnsubscribeDialog({openUnsubscribe, onUnsubscribeClick, handleClickClose, series}) {
  const classes = useStyles()
  const { primary } = classes
  const handleDeleteClick = () => {
    onUnsubscribeClick()
    handleClickClose()
  }
  return (
    <div>
      <Dialog open={openUnsubscribe} onClose={handleClickClose}>
        <DialogTitle className={primary}>Unsubscribe</DialogTitle>
        <DialogContent>
            <Typography gutterBottom className={primary}>
              {series && `Are you sure you want to unsubscribe from series "${series.seriesName}"`}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button className={primary} onClick={handleClickClose}>Cancel</Button>
          <Button className={primary} onClick={handleDeleteClick}>Unsubscribe</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}