import { useState, useEffect } from 'react';
import LoadingIndicator from "./LoadingIndicator";
import { Grid, Box, ImageListItem, Card, CardContent, Typography, Paper, Chip, List } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UnsubscribeDialog from './UnsubscribeDialog';
import { useNavigate } from 'react-router-dom';
import ProgressiveImage from './ProgressiveImage';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "250px",
      height: "320px",
      justifyContent: "center",
      alignItems: "center", 
      transition: "transform 0.15s ease-in-out",
      transform: "scale(0.9)",
      cursor: "pointer",
      "&:hover": { 
          borderColor: "#000",
          transform: "scale3d(1, 1, 1)"
      }
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px",
        height: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#819EF0",
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

const getSeriesGridContent = (allSeries, handleSeriesClick, classes, handleUnsubscribeClick) => {
  const seriesCard = classes.cardSelect
  return allSeries.map((seriesItem, index) => {
            const firstPadding = index == 0 ? { paddingLeft: "24px" } : { paddingLeft: "16px" }
            const lastPadding = index == allSeries.length-1 ? { paddingRight: "24px" } : { paddingRight: "16px" }
            const { imageUrl, seriesId, seriesName, backgroundColors, description } = seriesItem
            const cardStyleOverride = { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #fff 30%, #819EF0 90%)'}
            const shorthandSeriesName = seriesName.length > 20 ? seriesName.substring(0,20) + "..." : seriesName
            const shorthandSeriesDescription = description.length > 40 ? description.substring(0,40)+ "..." : description
            return (
                <ImageListItem key={seriesId} style={{height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex", flexDirection: "column"}} >
                    <Box style={{...firstPadding, ...lastPadding, height: "100%"}}>
                        <Card className={seriesCard} style={cardStyleOverride} onClick={() => handleSeriesClick(index, seriesItem)}>
                            <CardContent style={{
                                    height: "100%", 
                                    width: "100%",
                                    padding: "0px"
                                }}>
                                <div style={{borderRadius: "12px", overflow: "hidden"}}>
                                    <ProgressiveImage
                                        width="250px"
                                        height="250px"
                                        src={imageUrl}
                                    />
                                </div>
                                <Typography component="div" style={{ fontWeight: "bold", fontSize: "14px", width: "230px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", justifyContent: "start"}}>
                                    {shorthandSeriesName}
                                </Typography>
                                <Typography component="div" style={{fontSize: "12px", width: "220px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                    {shorthandSeriesDescription}
                                </Typography>
                            </CardContent>
                        </Card>
                        { handleUnsubscribeClick &&
                        <Box style={{ marginTop: "12px"}}>
                            <Chip color="secondary" style={{fontSize: "16px", fontWeight: "bold"}} label="Unsubscribe" onClick={() => handleUnsubscribeClick(index, seriesItem)} />
                        </Box>
                        }
                    </Box>
                </ImageListItem>
                )
          })
  }

  const getProfileCard = (handleUploadClick, classes, imageData) => {
    const {cardSelect} = classes
    const cardStyleOverride = {height: "150px", borderRadius: "75px", justifyContent: "center",  display: "flex", background: 'linear-gradient(45deg, #819EF0 30%, #819EF0 90%)'}  
    return (
        <Box>
            <input
                accept="image/*"
                style={{display: "none"}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUploadClick}
            />
            <label  htmlFor="contained-button-file">
                <Card className={cardSelect} style={cardStyleOverride}>
                    <CardContent style={{
                        padding: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }} >
                    { imageData ? 
                        <img
                            src={imageData}
                            height="100%"
                            width="100%"
                            style={{borderRadius: "75px"}}
                            crossOrigin="anonymous"
                        />
                        : 
                        <div style={{
                            justifyContent: "center",
                            alignItems: "center", 
                            display: "flex", 
                            height: "100%",
                            width: "100%"
                            }}>
                            <AccountCircleIcon style={{ height: "50px", width: "50px", color: "#000"}}/>
                        </div>
                    }
                    </CardContent>
                </Card>
            </label>
        </Box>
    )
  }

export const Profile = ({ user, isLoggedIn, appBarHeight}) => {
      const [loadingMySeries, setLoadingMySeries] = useState(false)
      const [loadingMySubscriptions, setLoadingMySubscriptions] = useState(false)
      const [allSeries, setAllSeries] = useState(null)
      const [allSubscriptions, setAllMySubscriptions] = useState(null)
      const [imageData, setImageData] = useState(null)
      const [openUnsubscribe, setOpenUnsubscribe] = useState(false);
      const [unsubscribeSeries, setUnsubscribeSeries] = useState(null)
      const classes = useStyles()
      const navigate = useNavigate()

      useEffect(async () => {
        if(!isLoggedIn) {
            navigate("/explorer")
        }
      }, [isLoggedIn]);

      useEffect(async () => {
        try { 
            setLoadingMySeries(true)
            const { userId } = user
            const body = {
                userId
            }
            const allSeriesResult = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-series-by-user', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
            })
            const { data: { allSeries: allUserSeries }} = allSeriesResult
            setAllSeries(allUserSeries.sort((a,b) => a.seriesName.localeCompare(b.seriesName)))
            setLoadingMySeries(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingMySeries(false)
        }
    }, []);

    useEffect(async () => {
      try { 
        setLoadingMySubscriptions(true)
        const { userId } = user
        const body = {
            userId
        }
        const subscriptions = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-subscriptions-by-user', body, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
        })
        const { data: { allSubscribedSeries: allUserSubscriptions }} = subscriptions
        setAllMySubscriptions(allUserSubscriptions.sort((a,b) => a.seriesName.localeCompare(b.seriesName)))
        setLoadingMySubscriptions(false)
      } catch (e) {
        // show alert dialog that failed 
        console.log(e)
        setLoadingMySubscriptions(false)
      }
  }, []);

    const handleUploadClick = event => {
        var file = event.target.files["0"];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
            const base63String = reader.result
            setImageData(base63String)
        }
    };

    const handleSeriesClick = (index, series) => {
        const { seriesId } = series
        navigate(`/series/${seriesId}`)   
    };

    const handleUnsubscribeClick = (index, series) => {
        setUnsubscribeSeries(series)
        setOpenUnsubscribe(true);
    };

    const handleClickCloseUnsubscribe = () => {
        setUnsubscribeSeries(null)
        setOpenUnsubscribe(false);
    }

    const onUnsubscribeClick = async () => {
        if(!unsubscribeSeries) {
            return
        }
        setLoadingMySubscriptions(true)
        const { userId, email } = user
        const { seriesId } = unsubscribeSeries
        const body = {
            userId,
            seriesId,
            email
        }
        try { 
            await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/unsubscribe-series', body, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            setAllMySubscriptions(allSubscriptions.filter(subscription => subscription.seriesId != seriesId))
            setLoadingMySubscriptions(false)
            setUnsubscribeSeries(null)
        } catch (e) {
            console.log(e)
            setUnsubscribeSeries(null)
            setLoadingMySubscriptions(false)
        }
    }
    
    const userSeries = allSeries ? getSeriesGridContent(allSeries, handleSeriesClick, classes) : []
    const userSubscriptions = allSubscriptions ? getSeriesGridContent(allSubscriptions, handleSeriesClick, classes, handleUnsubscribeClick) : []
    const profile = getProfileCard(handleUploadClick, classes, imageData)

    return (
        <Grid className={classes.container} container direction="row" style={{overflowY:"auto", overflowX: "hidden", height: `calc(100vh - ${appBarHeight}px)`, justifyContent: "center"}}>
            <Grid item xs={12} style={{padding: "24px 0px 24px 0px"}}>
                <UnsubscribeDialog series={unsubscribeSeries} openUnsubscribe={openUnsubscribe} onUnsubscribeClick={onUnsubscribeClick} handleClickClose={handleClickCloseUnsubscribe}/>
                <Typography style={{marginLeft: "32px", fontWeight: "bold", fontWeigth: "bold", fontSize: "24px"}}>My Subscriptions</Typography>
                <Grid item style={{width: "100%"}}>
                    { loadingMySubscriptions ? 
                        <Box style={{
                            background: "#fff",
                            paddingTop: "4px",
                            justifyContent: "center", 
                            alignItems: "center", 
                            display: "flex",
                            flexDirection: "column",
                            height: "400px"  
                        }}>
                            <LoadingIndicator loading={loadingMySubscriptions}/>
                        </Box> : 
                        userSubscriptions && userSubscriptions.length > 0 ? 
                        <Box  style={{ marginTop: "8px"}}>
                            <List className={classes.container} style={{
                                        background: "#fff",
                                        paddingTop: "12px",
                                        flexDirection:"row",
                                        overflow: "auto",
                                        flexWrap: 'nowrap',
                                        alignItems: "center",     
                                        display: "flex",
                                        height: "400px"   
                                    }}>
                                {userSubscriptions}
                            </List>
                        </Box> : 
                        <Box style={{
                            background: "#fff",
                            paddingTop: "4px",
                            justifyContent: "center", 
                            alignItems: "center", 
                            display: "flex",
                            flexDirection: "column",
                            height: "365px"  
                        }}>
                            <EmailIcon sx={{ color: "#819EF0", fontSize: 120 }}/>
                            <Typography variant="h5" style={{fontWeight: "bold"}} gutterBottom component="div">
                                No Subscriptions
                            </Typography>
                        </Box>
                    }
                </Grid>            
                <Grid item xs={12} style={{marginTop: "36px"}}>
                   <Typography style={{marginLeft: "32px", fontWeight: "bold", fontSize: "24px"}}>My Series</Typography>
                    { loadingMySeries ? 
                        <Box style={{
                            background: "#fff",
                            paddingTop: "4px",
                            justifyContent: "center", 
                            alignItems: "center", 
                            display: "flex",
                            flexDirection: "column",
                            height: "365px"   
                        }}>
                            <LoadingIndicator loading={loadingMySeries}/> 
                        </Box> : 
                        userSeries && userSeries.length > 0 ? 
                        <Box  style={{ marginTop: "8px"}}>
                            <List className={classes.container} style={{
                                        background: "#fff",
                                        flexDirection:"row",
                                        overflow: "auto",
                                        flexWrap: 'nowrap',
                                        alignItems: "center",     
                                        display: "flex",
                                        height: "365px"  
                                    }}>
                                {userSeries}
                            </List>
                        </Box>  : 
                        <Box style={{
                            background: "#fff",
                            paddingTop: "4px",
                            justifyContent: "center", 
                            alignItems: "center", 
                            display: "flex",
                            flexDirection: "column",
                            height: "365px"    
                        }}>
                            <EmailIcon sx={{ color: "#819EF0", fontSize: 120 }}/>
                            <Typography variant="h5" style={{fontWeight: "bold"}} gutterBottom component="div">
                                No Series
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Footer excludeHeight={true}/>
        </Grid>
    )
  }