import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, ListItem, Divider, List, Drawer, Typography, Chip, CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Auth } from 'aws-amplify';
import { styled, useTheme } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import ExploreIcon from '@mui/icons-material/Explore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SendIcon from '@mui/icons-material/Send';
import CableIcon from '@mui/icons-material/Cable';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom'
import ConfirmDialog from './ConfirmDialog';
import axios from "axios";

const stripe = window.Stripe('pk_live_51LxNcoAGDMeRymuHOy4jU4c26DnverioAja7Hht5OicoFXsWJcKIKUypqnBxiZf6j88I6FVNcMrGeKW2yycEFIiU00euYFTbAi');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#819EF0",
    width: "100%"
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    paddingBottom: 0,
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  background: "#819EF0",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const HamburgerMenuIcons = [
  {
    name: "Explore",
    icon: <ExploreIcon style={{color: "#000", fontSize: "32px"}}/>,
  },
  {
    name: "Design Studio",
    icon: <EditIcon style={{color: "#000", fontSize: "32px"}}/>,
  },
  {
    name: "Series Builder",
    icon: <Inventory2Icon style={{color: "#000", fontSize: "32px"}}/>,
  },
  {
    name: "Dashboard",
    icon: <DashboardIcon style={{color: "#000", fontSize: "32px"}}/>,
  },
  {
    name: "Campaign Builder",
    icon: <SendIcon style={{color: "#000", fontSize: "32px"}}/>,
  },
  {
    name: "Integrations",
    icon: <CableIcon style={{color: "#000", fontSize: "32px"}}/>, 
  }
]

const chipStyle = {
  display: 'flex',
  paddingTop: "2px",
  fontSize: "16px",
  height: "32px",
  fontWeight: "bold",
  backgroundColor: 'white',
  border: '1px solid white',
  borderRadius: '16px',
  borderColor: "#F5F5F5",
  boxShadow: '0px 0px 12px 1px #F5F5F5',
};

const TEST_GENERATE_EMAIL_PRICE = 'price_1Mkv3lAGDMeRymuHbcJey9vM'
const PROD_GENERATE_EMAIL_PRICE_1 = 'price_1NIlQcAGDMeRymuHASAtvufy'
const PROD_GENERATE_EMAIL_PRICE_2 = 'price_1NIlXsAGDMeRymuHQ0PO1OJf'
const PROD_GENERATE_EMAIL_PRICE_3 = 'price_1NIlSYAGDMeRymuHxUPZ2M4p'

export const NavigationBar = ({dispatch, isLoggedIn, user}) => {
  
  const { credits, userId, email } = user
  const location = useLocation();

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openConfirGenerates, setOpenConfirmGenerates] = useState(false)
  const [loadingCheckout, setLoadingCheckout] = useState(false)

  const onConfirmPurchaseGenerates = async (selectedOption) => {
    setLoadingCheckout(true)
    try {
      const purchaseGenerates = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/stripe-checkout-session?priceId=${selectedOption}&userId=${userId}&email=${email}&credits=${credits}&mode=payment`, {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
      })
      stripe.redirectToCheckout({
          sessionId: purchaseGenerates.data.sessionId
      })
      setLoadingCheckout(false)
    } catch(e) {
        console.log(e)
        setLoadingCheckout(false)
    }
  }

  const handleClickCloseGeneartes = () => {
    setOpenConfirmGenerates(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { textContent } = event.target
    switch (textContent) {
      case 'Explore':
        navigate('/explorer')
        break;
      case 'Series Builder':
        navigate('/series-builder')
        break;
      case 'Design Studio':
        navigate('/editor')
        break;
      case 'Dashboard':
        navigate('/dashboard')
        break
      case 'Campaign Builder':
        navigate('/campaign-builder')
        break;  
      case 'Integrations':
        navigate('/public-api/tutorials')
        break;    
      default:
        navigate('/explorer')
    };
    handleDrawerClose()
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const menuItemClicked = (event) => {
    const { textContent } = event.target
    const text = textContent
    if(text === 'Sign Out') {
      Auth.signOut()
    }
    if(text === 'Pricing') {
      navigate('/subscription-plan')
    }
    if(text === 'Profile') {
      navigate('/profile')
    }
    if(text === 'Settings') {
      navigate('/settings')
    }
    if(text === 'Sign In') {
      dispatch({
        type: 'SET_CREDENTIAL_DRAWER',
        payload: { 
          credentialDrawerState: true
        }
      })
    }
    if(text === 'Redo Tutorials') {
      dispatch({
        type: 'RESET_TUTORIALS'
      })
    }
    handleClose()
    handleDrawerClose()
  }

  
  const options = isLoggedIn ? [
    'Profile',
    'Pricing',
    'Settings',
    'Redo Tutorials',
    'Sign Out'
  ] : 
  [
    'Sign In',
    'Redo Tutorials'
  ];
  
  if(location && location.pathname === "/") return null
  
  return (
    <Box sx={{ display: 'flex' }}>
    <ConfirmDialog 
      onConfirm={onConfirmPurchaseGenerates}
      handleClickClose={handleClickCloseGeneartes} 
      title={"Get more done with Pikkaboo credits!"} 
      message={`Credits are your key to unlocking powerful features on our platform. Use them to generate stunning templates in the Design Studio, or automate newsletters in the Series Builder.\n
      We replenish up to 10 credits every month at no cost to kickstart your productivity. Want to do more? No problem! Simply proceed to checkout to purchase additional credits.`} 
      open={openConfirGenerates}
      confirmText={"Proceed to Checkout"}
      selectOption={[PROD_GENERATE_EMAIL_PRICE_1, PROD_GENERATE_EMAIL_PRICE_2, PROD_GENERATE_EMAIL_PRICE_3]}
    />
    { loadingCheckout ?
      <Box style={{
          position: 'absolute', 
          width: `calc(100vw)`,
          height: `calc(100vh)`, 
          overflowX: "auto", 
          overflowY: "hidden", 
          zIndex: 10,
          alignItems: 'center',
          justifyContent: 'center',
          display: "flex",
          backgroundColor: "#ffffffA0"
          }} >
          <CircularProgress style={{width: "75px", height: "75px"}}/>
          <Box
          sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: "128px"
          }}
          >
          <Typography
              variant="caption"
              component="div"
              style={{fontSize: 16, fontWeight: "bold"}}
          >{"Redirecting to Checkout"}</Typography>
          </Box>
      </Box> : null
    }    
    <AppBar position="static" open={open} className={classes.root} >
      <Toolbar >
        <Box display="flex" style={{justifyContent: "flex-start"}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ left: 8 }}
            onClick={handleDrawerOpen}
            style={{color: "#000",}}
          >
            <MenuIcon style={{fontSize: "32px"}} />
          </IconButton>
        </Box>
       
        <Box display="flex" style={{width: "100%", justifyContent: "center"}}>
          {isLoggedIn ? 
            <Chip
              onClick={() => setOpenConfirmGenerates(true)}
              style={chipStyle}
              label={`Credits: ${credits}`} 
              />
           : null}
        </Box>
        <Box display="flex" style={{justifyContent: "flex-end"}}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            style={{color: "#000",}}
          >
            <AccountCircle style={{fontSize: "32px"}} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={menuItemClicked} style={{fontSize: "20px", padding: 12}}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'ltr' ? <ChevronLeftIcon style={{fontSize: "32px"}}/> : <ChevronRightIcon style={{fontSize: "32px"}}/>}
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List>
      {HamburgerMenuIcons.map((item, index) => (
        <ListItem button key={index} onClick={handleChange} style={{padding: "16px"}}>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <Typography style={{fontSize: "20px"}}>{item.name}</Typography>
        </ListItem>
      ))}
    </List>
  </Drawer>
  </Box>
  );
}

const mapStateToProps = state => {
  return { credentialDrawerState: state.credentialDrawerState }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBar)