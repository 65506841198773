import { useEffect, useState, useRef, useLayoutEffect} from "react"
import { Box, Grid, Chip, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Card } from '@aws-amplify/ui-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ImageListItem from '@material-ui/core/ImageListItem';
import SeriesDetailCard from "./SeriesDetailDialog";
import LoadingIndicator from "./LoadingIndicator";
import SearchWidget from "./SearchWidget";
import PikkabooTransparent from '../images/pikkaboo-transparent.png';
import { connect } from 'react-redux'
import {isMobile} from 'react-device-detect';
import ProgressiveImage from "./ProgressiveImage";
import TutorialDialog from "./TutorialDialog";
import Footer from "./Footer";
import SEO from "./SEO";
import { SeriesDescriptionInputBox } from "./SeriesDescriptionInputBox";

const useStyles = makeStyles((theme) => ({
    cardSelect: {
      height: 400,
      width: 300,
      marginTop: "16px",
      marginBottom: "20px",
      borderColor: "#979797",
      borderRadius: "12px",
      transform: "scale(0.9)",
      transition: "transform 0.15s ease-in-out",
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)",
      "&:hover": { 
        borderColor: "#000",
        transform: "scale3d(1.00, 1.00, 1.00)"
      }
    },
    colorIconAdd: {
        borderRadius: "14px", 
        color: "#000",
        backgroundColor: "#819EF0"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: "#fff",
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        '&::-webkit-scrollbar': {
            width: "12px",
            height: "12px"
          },
          '&::-webkit-scrollbar-track':{
            borderRadius: "4px",
            marginLeft: "40px",
            marginRight: "40px"
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#819EF0",
            boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "12px",
            border: "3px white solid"
          },
          /* Opera doesn't support this in the shorthand */
          backgroundAttachment: "local, local, scroll, scroll" 
    },
    title: {
        color: theme.palette.primary.light,
    }
  }));

const getSortedSeriesSections = (exploreResults) => {
    const seriesMap = {
        "Food & Travel": [],
        "Lifestyle & DIY": [],
        "Business & Tech": [],
        "Music & Entertainment": [],
        "Sports & Fitness": [],
        "Health & Wellness": [],
        "Politics": [],
        "Energy & Environment": [],
        "Other": []
    }
    if(exploreResults) {
        const mappedSeries = exploreResults.map((series) => {
            const { fields } = series
            const seriesObject = {}
            if(fields) {
                for (const [key, value] of Object.entries(fields)) {
                    if(key === "backgroundcolors" ){
                        seriesObject[key] = value
                    } else {
                        seriesObject[key] = value[0]
                    }
                }
                return seriesObject;
            }
        })
        mappedSeries && mappedSeries.map(series => {
            const { category } = series
            seriesMap[category] = [...seriesMap[category], series]
        })
    }
    return seriesMap
}

const Explorer = (props) => {
    const { user, explorePageResults, dispatch, appBarHeight, dialogTutorialMetadata } = props
    const { exploreResults, lastUpdated } = explorePageResults
    const classes = useStyles()
    const [seriesCardState, setSeriesCardState] = useState(false)
    const [selectedSeries, setSelectedSeries] = useState(null)
    const [loadingExplorePage, setLoadingExplorePage] = useState(false)
    const [searchedSeries, setSearchedSeries] = useState(null)
    const [seenTutorials, setSeenTutorials] = useState(false)
    const [paginationStart, setPaginationStart] = useState(0)
    const [searchValue, setSearchValue] = useState(null);
    const [paginate, setPaginate] = useState(false);
    const [stopPagination, setStopPagination] = useState(false);
    const navigate = useNavigate()

    const dispatchExploreSeriesResults = (allSeries) => {
        dispatch({
            type: 'SET_EXPLORE_RESULTS',
            payload: { 
                exploreResults: allSeries
            }
          })
    }

    const openSeriesDetailPage = (series) => {
        const { seriesId, seriesid } = series
        const id = seriesId ? seriesId : seriesid
        navigate(`/series/${id}`)
    }

    useEffect(async () => {
        try { 
            setLoadingExplorePage(true)
            const diffTime = new Date().getTime() - lastUpdated
            var resultInMinutes = Math.round(diffTime / 60000);
            const category = "all"
            const numberOfSeries = isMobile ? 5 : 10
            if(resultInMinutes > 0) {
                const allSeriesResult = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/get-all-series?categoryId=${category}&start=${paginationStart}&numberOfSeries=${numberOfSeries}`, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                const { data: { searchedSeries }} = allSeriesResult
                dispatchExploreSeriesResults(searchedSeries)
            }
            setLoadingExplorePage(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingExplorePage(false)
        }
    }, []);

    const openSeriesDetailDialog = (series) => {
        setSelectedSeries(series)
        setSeriesCardState(true)
    }

    const closeSeriesDetailDialog = () => {
        setSelectedSeries(null)
        setSeriesCardState(false)
    }

    const onClickSeeMore = async (searchTerm) => {
        navigate(`/explorer/${searchTerm}`)
    }

    const onClickClear = () => {
        setSearchedSeries(null)
        setPaginationStart(0)
    }

    const onClickSearch = async (searchTerm) => {
        if(!searchTerm) {
            setSearchedSeries(null)
            setSearchValue(null)
            return 
        }
        try {
            setLoadingExplorePage(true)
            await searchSeries(searchTerm)
            setLoadingExplorePage(false)
        } catch (e) {
            // show alert dialog that failed 
            console.log(e)
            setLoadingExplorePage(false)
        }
    }

    const searchSeries = async (searchTerm) => {
        const numberOfSeries = isMobile ? 5 : 10
        //paginted calls don't call this function with search term, so we used the save searchValue in the following lines
        const searchedSeriesResult = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/search-series?searchTerm=${searchTerm || searchValue}&start=${paginationStart}&numberOfSeries=${numberOfSeries}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
        const { data: { searchedSeries }} = searchedSeriesResult
        if(searchTerm) {
            setSearchValue(searchTerm)
            setSearchedSeries(searchedSeries.hit)
            setStopPagination(false)
        } else { 
            setSearchedSeries(prevSearchedResults => [...prevSearchedResults, ...searchedSeries.hit])
        }
        if(paginationStart+numberOfSeries < searchedSeries.found) { // if there are less hits left than the number we search for we should end pagination
            setPaginationStart(paginationStart+numberOfSeries)
        } else {
            setStopPagination(true)
        }
        setPaginate(false)
    }

    useEffect(() => {
        const { seen } = dialogTutorialMetadata["explorer"]
        setSeenTutorials(seen) //Necassary, something happens with window focus if Tutorials are rendered (Disables pull to refresh) 
    }, [dialogTutorialMetadata]);
    
    const scrollHandler = (e) => {
        if(searchedSeries && !stopPagination) {
            if(e.target.clientHeight === (e.target.scrollHeight - Math.round(e.target.scrollTop))) {
                setPaginate(true)
            }
        }
    }

    useEffect(async () => {
        if(paginate) {
            await searchSeries(null)
        }
    },[paginate])

    const sortedSeries = getSortedSeriesSections(exploreResults)
    return (
        <Box onScroll={scrollHandler} className={classes.gridList} style={{ height: `calc(100vh - ${appBarHeight}px)`, overflowX: "hidden", overflowY: "auto", display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
            {!seenTutorials ? <TutorialDialog page="explorer" /> : undefined}
            {selectedSeries && <SeriesDetailCard 
                user={user}
                close={closeSeriesDetailDialog}
                seriesCardState={seriesCardState}
                series={selectedSeries}
                openSeriesDetailPage={openSeriesDetailPage}
            />}
            <SEO
                title={`Browse and Find Your Next Favorite Newsletter with Pikkaboo's Explorer Page.`}
                description={`Our user-friendly interface allows you to browse and search for newsletters by topic and interest, making it easy to find your next favorite newsletter. With a wide variety of topics and categories to choose from, you can explore newsletters on everything from technology and business to fashion and lifestyle. Plus, our Explorer page makes it easy to subscribe to your favorite newsletters and stay up-to-date with the latest content.`}
                name={`Pikkaboo`}
                type={`article`} 
            />
            <Box style={{paddingTop: "32px", flexDirection:"column", display: "flex", justifyContent: "center"}}>
                <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                    <img src={PikkabooTransparent} width="50%" style={{marginBottom: "24px", minWidth: "200px", maxWidth: "250px"}} />
                    <SearchWidget onClickSearch={onClickSearch} onClickClear={onClickClear}/>
                </Box>
                { loadingExplorePage ? 
                    <Box style={{
                        background: "#fff",
                        marginTop: "24px",
                        alignItems: "center", 
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <LoadingIndicator loading={loadingExplorePage}/>
                    </Box> :  
                    searchedSeries && searchedSeries.length > 0 ?
                    <Box style={{marginTop: "24px"}}>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign:"center", marginTop: "12px", marginBottom: "12px", fontWeight: "bold", fontSize: "32px"}}>Search Results</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Grid 
                            style={{paddingBottom: 24}}
                            direction={"row"}
                            container>
                            <SearchedResults searchedSeries={searchedSeries} openSeriesDetailDialog={openSeriesDetailDialog}/>
                        </Grid>
                        </Grid>
                    </Box> :
                    <List style={{marginTop: "24px", maxHeight: '100%', overflow: 'auto'}} className={classes.gridList} >
                        <CarouselSections onClickSeeMore={onClickSeeMore} sortedSeries={sortedSeries} openSeriesDetailDialog={openSeriesDetailDialog}/>
                    </List>
                }
            </Box> 
            <Footer excludeHeight={false}/>
        </Box>
    )
}

function CarouselSections(props) {
    const classes = useStyles()
    const { sortedSeries, openSeriesDetailDialog, onClickSeeMore } = props
    return Object.entries(sortedSeries).map((series, index) => {
        return series[1].length > 0 && 
        <Box key={index} className={classes.root} style={{marginTop: "32px", marginBottom: "32px"}}>
            <Box style={{ paddingTop: 8, display: "flex", flexDirection: "row", width:"100%", marginTop: "0px"}}>
                <Typography style={{width:"100%", paddingTop: "2px", height: "32px", marginLeft: "36px", fontWeight: "bold", fontSize: isMobile ? "20px" : "26px"}}>{series[0]}</Typography>
                <Chip color="primary" style={{ boxShadow: '0px 0px 8px 1px #819EF0', marginRight: "36px", alignSelf: "center", justifyContent: "center", fontSize: "16px", fontWeight: "bold", height: "32px"}} label="Explore More" onClick={() => onClickSeeMore(series[0])} />
            </Box>
            <Box className={classes.gridList} style={{ overflowX: "scroll", marginTop: "8px"}}>
                <List style={{flexDirection:"row", display:"flex"}}>
                    <CarouselContent items={series[1]} openSeriesDetailDialog={openSeriesDetailDialog}/>
                </List>
            </Box>
        </Box>
    })
}

function CarouselContent(props) {
    const classes = useStyles()
    const seriesCard = [classes.cardSelect]
    const { openSeriesDetailDialog, items } = props
    return items.map((seriesItem, index) => {
        const firstPadding = index === 0 ? { paddingLeft: "16px" } : { paddingLeft: "0px" }
        const lastPadding = index === items.length - 1 ? { paddingRight: "16px" } : { paddingRight: "0px" }
        const { imageurl, seriesid, seriesname, description } = seriesItem
        const shorthandSeriesName = seriesname.length > 26 ? seriesname.substring(0,26)+ "..." : seriesname
        const shorthandSeriesDescription = description.length > 60 ? description.substring(0,60)+ "..." : description
        const cardStyleOverride = { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #fff 30%, #819EF0 90%)'}
                return ( 
                    <ImageListItem style={{...firstPadding, ...lastPadding, height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex", flexDirection: "column"}} key={seriesid} >
                        <Box style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center"}}>
                            <Card className={seriesCard} style={{...cardStyleOverride, margin: "12px", padding: "0px"}} onClick={() => openSeriesDetailDialog(seriesItem)}>
                                <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                                    <div style={{borderRadius: "12px", overflow: "hidden"}}>
                                        <ProgressiveImage                                         
                                            width="300px"
                                            height="300px"
                                            src={imageurl}
                                        />
                                    </div>
                                    <Typography component="div" style={{fontWeight: "bold", fontSize: "20px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                        {shorthandSeriesName}
                                    </Typography>
                                    <Typography component="div" style={{fontSize: "16px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                        {shorthandSeriesDescription}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </ImageListItem>
                    )
            })
}

function SearchedResults(props) {
    const { searchedSeries, openSeriesDetailDialog } = props
    const classes = useStyles()
    const seriesCard = [classes.cardSelect] 

    const mappedSeries = searchedSeries.map((series) => {
        const { fields } = series
        const seriesObject ={}
        for (const [key, value] of Object.entries(fields)) {
            if(key === "backgroundcolors" ){
                seriesObject[key] = value
            } else {
                seriesObject[key] = value[0]
            }
        }
        return seriesObject;
    })

    return mappedSeries.map((series) => {
        const { imageurl, seriesid, seriesname, description } = series
        const cardStyleOverride = { justifyContent: "center", display: "flex", background: 'linear-gradient(45deg, #fff 30%, #819EF0 90%)'}
        const shorthandSeriesName = seriesname.length > 25 ? seriesname.substring(0,25) + "..." : seriesname
        const shorthandSeriesDescription = description.length > 60 ? description.substring(0,60)+ "..." : description
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={seriesid} >
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign: "center"}}>
                    <Card className={seriesCard} style={{...cardStyleOverride, margin: "12px", padding: "0px"}} onClick={() => openSeriesDetailDialog(series)}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                            <div style={{overflow: "hidden", borderRadius: "12px", background: "#fff"}}>
                                <ProgressiveImage
                                    width="300px"
                                    height="300px"
                                    src={imageurl}
                                />
                            </div>
                            <Typography component="div" style={{fontWeight: "bold", fontSize: "20px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                {shorthandSeriesName}
                            </Typography>
                            <Typography component="div" style={{fontSize: "16px", width: "280px", marginLeft: "12px", padding: "0px", display: "flex", flexDirection: "row", textAlign: "start"}}>
                                {shorthandSeriesDescription}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        )
    })
}

  const mapStateToProps = state => {
    return { 
        user: state.user,
        explorePageResults: state.explorePageResults,
        dialogTutorialMetadata: state.dialogTutorialMetadata
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Explorer)