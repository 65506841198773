import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop'
import styles from "../../stylings/Modal.module.css"
import { Modal, Typography } from "@material-ui/core";
import { Box } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

const CropModel = ({cropPopupOpen, imageData, setCropPopupOpen, setCroppedImageData}) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [cropPixels, setCropPixels] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCropPixels(croppedAreaPixels)
      }, [])

    const closeCropPopup = useCallback(async () => {
        try {
            handleCropChange(imageData, cropPixels, 1);
        } catch (error) {
            console.error('Error cropping image:', error);
        }
        setCropPopupOpen(false);
    }, [imageData, crop]);

    const onCropChange = (crop) => {
        setCrop(crop)
    }
    
    const handleCropChange = (imageData, crop, aspectRatio) => {
        const image = new Image();
      
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
      
          const scaleX = image.naturalWidth / image.width;
          const scaleY = image.naturalHeight / image.height;
      
          const croppedWidth = crop.width * scaleX;
          const croppedHeight = crop.height * scaleY;
          const croppedX = crop.x 
          const croppedY = crop.y 
      
          canvas.width = 420;
          canvas.height = 420;
       
          ctx.drawImage(
            image,
            croppedX,
            croppedY,
            croppedWidth,
            croppedHeight,
            0,
            0,
            420,
            420
          );
      
          const croppedImageDataURL = canvas.toDataURL();
          setCroppedImageData(croppedImageDataURL);
        }
      
          // Set the source of the image
          image.onerror = (e) => {
            console.error(e.target);
          };
      
          image.src = imageData
        
      };

    return (
        <Modal open={cropPopupOpen}>
        <div className={styles["modal"]}>
            <div className={styles["modal-content"]}>
            <div
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: `100%`,
                height: `100%`,
                overflow: "auto",
                position: "relative",
                }}
            >
                <Cropper
                image={imageData}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                />
            </div>

            <LoadingButton
                onClick={closeCropPopup}
                variant="contained"
                startIcon={<SaveIcon style={{fontSize: 20}}/>}
                style={{
                    fontSize: "16px",
                    backgroundColor: "#819EF0",
                    color: "#000",
                    width: "100%",
                    marginTop: 0,
                    maxWidth: "600px",
                    borderColor: "#F5F5F5",
                    borderRadius: "0px 0px 12px 12px",
                    height: "58px",
                }}
                >
                    <Typography style={{fontSize: 16, fontWeight: "bold"}}>Crop and Upload</Typography>
                </LoadingButton>
            </div>
        </div>
        </Modal>
    );
  };

export default CropModel