import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import { editorTutorialMetadata, explorerTutorialMetadata, managerTutorialMetadata } from './tutorialMetadata';

const initialState = {
    user: {
        email: null,
        email_verified: null,
        userId: null,
        tier: null,
        stripeCustomerId: null,
        userName: null,
        credits: null
    },
    explorePageResults: {
        exploreResults: [],
        lastUpdated: null
    },
    credentialDrawerState: false,
    dialogTutorialMetadata: {
        explorer: explorerTutorialMetadata,
        manager: managerTutorialMetadata,
        editor: editorTutorialMetadata,
        lastVisted: new Date().getTime()
    },
    cachedTemplate: null
}

const persistConfig = {
    key: "main-root",
    storage
}
  
const reducer = (state = initialState, action) => {
    if (action.type === 'SET_USER') {
        const { payload } = action
        return {
            ...state,
            user: {
              email: payload.email,
              email_verified: payload.email_verified,
              userId: payload.userId,
              tier: payload.tier,
              stripeCustomerId: payload.stripeCustomerId,
              userName: payload.userName,
              credits: payload.credits,
              api_key: payload.api_key,
              api_key_id: payload.api_key_id
            }
        }
    }
    if (action.type === "SET_EXPLORE_RESULTS") {
        const { payload } = action
        return {
            ...state,
            explorePageResults: {
                exploreResults: payload.exploreResults,
                lastUpdated: new Date().getTime(),
            }
        }
    }
    if (action.type === "SET_CREDENTIAL_DRAWER") {
        const { payload } = action
        return {
            ...state,
            credentialDrawerState: payload.credentialDrawerState,
        }
    }
    if (action.type === "SET_SERIES_SEEN") {
        const { payload } = action
        return {
            ...state,
            dialogTutorialMetadata: {
                ...state.dialogTutorialMetadata,
                [payload.page]: {
                    ...state.dialogTutorialMetadata[payload.page],
                     seen: true
                }
            }
        }
    }
    if (action.type === "RESET_TUTORIALS") {
        return {
            ...state,
            dialogTutorialMetadata: initialState.dialogTutorialMetadata
        }
    }
    if (action.type === "SAVE_TEMPLATE") {
        const { payload } = action
        return {
            ...state,
            cachedTemplate: payload.cachedTemplate
        }
    }
    if (action.type === "CLEAR_STORE") {
        return initialState
    }
    if (action.type === "DEDUCT_CREDIT") {
        return {
            ...state,
            user: {
                ...state.user,
                credits: parseInt(state.user.credits) - 1
            }
        }
    }
    return state
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer, applyMiddleware())

const persistor = persistStore(store)

export { persistor }
export default store