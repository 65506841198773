import * as React from 'react';
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    notchedOutline: { 
        borderWidth: "2px !important",
        borderColor: '#000 !important' 
    },
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

  const ColorToggleButton = styled(ToggleButton)({
    flex: 1,
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#fff",
        backgroundColor: '#819EF0'
    }
  });

export default function ConfirmDialog({onConfirm, handleClickClose, title, message, open, confirmText, selectOption}) {
  const classes = useStyles()
  const { primary, notchedOutline } = classes
  const defaultSelected = selectOption && selectOption.length > 2 ? selectOption[0] : null
  const [selectedOption, setSelectedOption] = useState(defaultSelected);
  
  const handleConfirmClick = () => {
    onConfirm(selectedOption)
    handleClickClose()
  }

  const handleOptionChange = (event, newOption) => {
    setSelectedOption(newOption);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle className={primary}>{title}</DialogTitle>
        <DialogContent>
          <Typography
            margin="dense"
            style={{maxWidth: 400, wordWrap: "break-word", fontWeigth: "bold", fontSize: 16, whiteSpace:"pre-line"}}
          >
            {message}
          </Typography>
          {selectedOption && selectedOption.length > 2 ? 
          <ToggleButtonGroup style={{display: "flex", height: "40px", marginTop: "24px", width:"100%", justifyContent: "center"}} value={selectedOption} exclusive onChange={handleOptionChange}>
            <ColorToggleButton value={selectOption[0]}>$1 for 10</ColorToggleButton>
            <ColorToggleButton value={selectOption[1]}>$2 for 25</ColorToggleButton>
            <ColorToggleButton value={selectOption[2]}>$3 for 50</ColorToggleButton>
          </ToggleButtonGroup>
          : null }
        </DialogContent>
        <DialogActions>
          <Button className={primary} onClick={handleClickClose}>Cancel</Button>
          <Button className={primary} onClick={handleConfirmClick}>{confirmText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}