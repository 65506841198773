import * as React from 'react';
import { Box, Grid } from "@material-ui/core";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState} from "react"
import LoadingIndicator from './LoadingIndicator';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important",
            marginRight: "8px"
        }
      },
    colorRemove: {
        color: "#000",
        backgroundColor: ({activeColor})=> activeColor
    },
    colorBlack: {
        color: "#000"
    },
    container: {
      borderColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: '-2px 2px 8px 1px #819EF0',
      '&::-webkit-scrollbar': {
        width: "12px",
      
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        margin: 12
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
    },
    boxStyles: {
      backgroundColor: "#000",
      color: "#000",
      borderColor: "#F5F5F5",
      borderRadius: "10px",
      boxShadow: "-0.5px 1.5px 1px 3px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        borderColor: "#F5F5F5",
        color: "#000",
      },
      "&.Mui-focused": {
        borderColor: "#F5F5F5",
        color: "#000",
      },
    }
      // <Box style={{border: "1px solid",boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.2)", borderColor: activeColor, borderRadius: "4px", margin: "0px 8px 0px 8px", padding:"8px 0px 0px 8px", display: "flex", flexDirection: "column", width: "100%"}}>
  }));

const getListItems = (templates, handleListItemClick, classes, selectedTemplates) => {
    const { colorRemove, colorBlack, boxStyles } = classes
    return templates.sort((a,b) => a.templateName.localeCompare(b.templateName)).map((template, index) => {
        const { templateId, templateName } = template
        const isSelected = selectedTemplates.findIndex(selectedTemplate => selectedTemplate.templateId === templateId) !== -1
        return <Box style={{  
                backgroundColor: "#fff",
                color: "#000",
                borderColor: "#F5F5F5",
                borderRadius: "10px",
                boxShadow: '-1px 1px 2px 1px #819EF0',
                borderColor: "#F5F5F5",
                color: "#000",
                margin: 8,
                }} key={`managed-${template.templateId}`}>
                <ListItemButton
                    key={templateId}
                    style={{}}
                    onClick={() => handleListItemClick(index, template)} 
                >
                    <ListItemText primary={templateName} style={{height: "100%", alignSelf: "center"}}/>
                    <ListItemIcon style={{justifyContent: "end"}} >
                        {isSelected ? <RemoveIcon style={{borderRadius: "4px", height: "20px", width: "20px"}} className={colorRemove}/> : <AddIcon style={{height: "20px", width: "20px"}} className={colorBlack}/>  }
                    </ListItemIcon>
                </ListItemButton>
              </Box> 
    })
}

export function ManagerSeriesContentList({templateSelectorRef, activeColor, onSelect, templates, selectedTemplates, appBarHeight, loadingSeriesManager}) {
  const navigate = useNavigate()
  const handleListItemClick = (index, template) => {
    onSelect(template)
  };

  const navigateDesignStudio = (series) => {
    navigate(`/editor`)
}

  const classes = useStyles({activeColor})
  const listItems = getListItems(templates, handleListItemClick, classes, selectedTemplates)
  return (
    <Box ref={templateSelectorRef} className={classes.container} style={{marginTop: 16, background: '#fff', height: 300, overflowY: "scroll"}}>
      { loadingSeriesManager ? <LoadingIndicator loading={loadingSeriesManager}/> : 
        <List style={{borderRadius: "4px", paddingTop: "4px", paddingBottom: 0}}>
          {templates && templates.length > 0 ? listItems : 
            <Box style={{
              background: "#fff",
              paddingTop: "4px",
              justifyContent: "center", 
              alignItems: "center", 
              display: "flex",
              flexDirection: "column",
              height: "275px"     
          }}>
              <MailOutlineIcon sx={{ color: "#819EF0", fontSize: 120 }}/>
              <Typography variant="h5" style={{fontWeight: "bold"}} gutterBottom component="div">
                  No Templates Created
              </Typography>
              <Chip style={{ marginTop: 10, backgroundColor: "#819EF0", alignSelf: "center", justifyContent: "center", fontSize: "16px", fontWeight: "bold", height: "32px"}} label="Create Template" onClick={navigateDesignStudio} />
          </Box>
          }
        </List>
      }
    </Box>
      );
    }