import { useEffect, useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { connect } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    primary: {
        color: "#819EF0 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
});  

const getArrow = (arrowPos) => {
    switch(arrowPos) {
        case "middle-up": 
            return <KeyboardArrowUpIcon style={{ color: "#819EF0", borderRadius: "6px 6px 0 0", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible",left: 0, right: 0, marginLeft: "auto", marginRight: "auto", top: -20}}/>
        case "right-up":
            return <KeyboardArrowUpIcon style={{ color: "#819EF0", borderRadius: "6px 6px 0 0", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", right:0, top: -20}}/>
        case "right-up-right":
            return <KeyboardArrowRightIcon style={{ color: "#819EF0", borderRadius: "0 6px 6px 0", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", right:-20, top: 0}}/>
        case "right-down":
            return <KeyboardArrowDownIcon style={{ color: "#819EF0", borderRadius: "0 0 6px 6px", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", right:0, bottom: -20}}/>
        case "right-down-right":
            return <KeyboardArrowRightIcon style={{ color: "#819EF0", borderRadius: "0 6px 6px 0", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", right:-20, bottom: 0}}/>
        case "middle-down": 
            return <KeyboardArrowDownIcon style={{ color: "#819EF0", borderRadius: "0 0 6px 6px", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible",left: 0, right: 0, marginLeft: "auto", marginRight: "auto", bottom: -20}}/>
        case "left-down":
            return <KeyboardArrowDownIcon style={{ color: "#819EF0", borderRadius: "0 0 6px 6px", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", left:0, bottom: -20}}/>    
        case "left-down-left":
            return <KeyboardArrowLeftIcon style={{ color: "#819EF0", borderRadius: "6px 0 0 6px", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", left:-20, bottom: 0}}/>    
        case "left-up":
            return <KeyboardArrowUpIcon style={{ color: "#819EF0", borderRadius: "6px 6px 0 0", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", left:0, top: -20}}/>    
        case "left-up-left":
            return <KeyboardArrowLeftIcon style={{ color: "#819EF0", borderRadius: "6px 0 0 6px", width: "24px", height: "24px", backgroundColor: "#fff", position: "absolute", overflow:"visible", left:-20, top: 0}}/>    
        default:
            return undefined
        }

}

const getBorderRadius = (arrowPos) => {
    switch(arrowPos) {
        case "middle-up": 
            return "8px 8px 8px 8px"
        case "right-up":
            return "8px 0px 8px 8px"
        case "right-up-right":
            return "8px 0px 8px 8px"
        case "right-down":
            return "8px 8px 0px 8px"
        case "right-down-right":
            return "8px 8px 0px 8px"
        case "middle-down": 
            return "8px 8px 8px 8px"
        case "left-down":
            return "8px 8px 8px 0px"
        case "left-down-left":
            return "8px 8px 8px 0px"
        case "left-up":
            return "0px 8px 8px 8px"
        case "left-up-left":
            return "0px 8px 8px 8px"
        default:
            return "8px 8px 8px 8px"
        }

}

export const TutorialDialog = ({ scrollListRef, page, refList, dialogTutorialMetadata, dispatch}) => {
    const classes = useStyles();
    const { primary } = classes
    const sequence = dialogTutorialMetadata[page] && dialogTutorialMetadata[page].sequence

    const [open, setOpen] = useState(null);
    const [seenTutorials, setSeenTutorials] = useState(true)

    const [index, setIndex] = useState(0);
    const [tooltipLayout, setTooltipLayout] = useState({
        leftOffset: 0,
        topOffset: 0,
        visible: "block"
    })
    const [layoutMetadata, setLayoutMetadata] = useState({
        layoutTitle: null,
        layoutContent: null,
        layoutPosition: null,
        layoutOffset: null,
        layoutArrowPos: null
    })

    const {
        dialogTitle,
        dialogContent,
        position,
        offset,
        arrowPos
    } = sequence ? sequence[index] : {}

    const {
        layoutTitle,
        layoutContent,
        layoutPosition,
        layoutOffset,
        layoutArrowPos
    } = layoutMetadata

    const handleClickNext = () => {
        if(index === sequence.length - 1){
            dispatch({
                type: 'SET_SERIES_SEEN',
                payload: { 
                    page
                }
            })
            return
        }
        setIndex(index+1)
    };

    const handleClickPrevious = () => {
        if(index === 0){
            return
        }
        setIndex(index-1)
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        } 
        dispatch({
            type: 'SET_SERIES_SEEN',
            payload: { 
                page
            }
        })
        scrollListRef && scrollListRef.current && scrollListRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    };

    useEffect(() => {
        const { seen } = dialogTutorialMetadata[page]
        setSeenTutorials(seen)
    }, [dialogTutorialMetadata]);

    useEffect(() => {
        if(!seenTutorials) {
            setIndex(0)
            setOpen(true) 
        } else {
            setOpen(false)
        }
    }, [seenTutorials]);

    useEffect(()=> {
        setLayoutMetadata({
            layoutTitle: dialogTitle,
            layoutContent: dialogContent,
            layoutPosition: position,
            layoutOffset: offset,
            layoutArrowPos: arrowPos
        })
    }, [dialogTitle, dialogContent, position, offset, arrowPos])

    useEffect(() => {
        if(seenTutorials) {
            return
        }
        setTooltipLayout({
            ...tooltipLayout,
            visible: "none"
        })
        if(refList && index <= refList.length - 1 && refList[index].current) {
            const topScrollViewOffset = refList[index].current.offsetTop - 128
            scrollListRef && scrollListRef.current && scrollListRef.current.scrollTo({top: topScrollViewOffset, left:0})
            console.log(offset)
            if(offset) {
                setTooltipLayout({
                    leftOffset: offset.left,
                    topOffset: offset.top,
                    visible: "block"
                })
            } else {
                const  rect = refList[index].current.getBoundingClientRect()
                setTooltipLayout({
                    leftOffset: rect.left - 24,
                    topOffset: rect.top + 12,
                    visible: "block"
                })
            }
        } else if (offset) {
            setTooltipLayout({
                leftOffset: offset.left,
                topOffset: offset.top,
                visible: "block"
            })
        } else {
            setTooltipLayout({
                ...tooltipLayout,
                visible: "block"
            })  
        }
    }, [index, seenTutorials])

    const arrow = getArrow(layoutArrowPos)
    const {
        leftOffset,
        topOffset,
        visible
    } = tooltipLayout
    return (
        (open === true || open === false) && 
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-container": layoutPosition
            }}
            PaperProps={{
                sx: {
                    left: leftOffset || 0,
                    top: topOffset || 0,
                    right: layoutOffset && layoutOffset.right,
                    maxWidth: "400px",
                    overflow: "visible",
                    display: visible,
                    borderRadius: getBorderRadius(arrowPos)
                }
            }}
        >  
            {arrow}
            {index !== sequence.length -1 ? 
                <DialogActions style={{padding: "8px 8px 0 0"}}>
                    <Box style={{display: "flex", justifyContent: "flex-end", width: "100%", padding: "0 0 0 16px"}}>
                        <Button className={primary} onClick={handleClose}>Skip</Button>
                    </Box>
                </DialogActions> :
                <Box style={{height: "16px"}}/>
            }
            <DialogTitle style={{padding: "0 16px 0 16px"}}>{layoutTitle}</DialogTitle>
            <DialogContent style={{padding: "4px 16px 4px 16px"}}>
                <DialogContentText style={{ fontSize: "16px", color: "#000"}} id="alert-dialog-slide-description">
                    {layoutContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: "center", padding: "0 0 8px 0"}}>
            {index !== 0 && <Box style={{display: "flex", justifyContent: "flex-start", width: "100%", padding: "0 0 0 8px"}}>
                    <Button className={primary} onClick={handleClickPrevious}>Previous</Button>
                </Box>
            }
            <Box style={{display: "flex", justifyContent: "flex-end", width: "100%", padding: "0 8px 0 0"}}>
                <Button className={primary} onClick={handleClickNext}>{index !== sequence.length -1 ? "NEXT" : "FINISH"}</Button>
            </Box>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return { dialogTutorialMetadata: state.dialogTutorialMetadata }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialDialog)
