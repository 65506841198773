export const editorTutorialMetadata = {
    seen: false,
    sequence: [
        {
            dialogTitle: "Geneartive AI Templates",
            dialogContent: `You can now generate templates using AI to facilitate the creative process and 
                            offer a streamlined starting point. Generated images within templates
                            are only valid for 1 hour, but you can swap them out for your own. Give it a try!`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-end",
            },
            offset: {
                top: -50,
                left: 0
            },
            arrowPos: "middle-down"
        }
    ]
}

export const explorerTutorialMetadata = {
    seen: false,
    sequence: [
        {
            dialogTitle: "Pikkaboo!",
            dialogContent: `Welcome to the number one newsletter platform on the
                            internet. Let's run through a quick tutorial to help you get started.`,
            position: {
                justifyContent: "center",
                alignItems: "center"
            },
            offset: {
                top: 0,
                left: 0
            },
        },
        {
            dialogTitle: "Explore Page",
            dialogContent: `This is the explore page. Here you can search, find, and
                            subscribe to any newsletter that was created on the platform.`,
            position: {
                justifyContent: "center",
                alignItems: "center"
            },
            offset: {
                top: 0,
                left: 0
            },
        },
        {
            dialogTitle: "Sign In and Profile",
            dialogContent: `Up here you can access the sign-in sheet, your profile page, and manage your subscription.`,
            position: {
                justifyContent: "flex-end",
                alignItems: "flex-start",
            },
            offset: {
                top: "32px",
                right: "12px",
            },
            arrowPos: "right-up"
        },
        {
            dialogTitle: "Navigation",
            dialogContent: `Here you can Navigate to other pages like the Design Studio, Series Builder, Dashboard, and more.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            offset: {
                top: "32px",
                left: "4px"
            },
            arrowPos: "left-up"
        },
        {
            dialogTitle: "Ready for Action",
            dialogContent: `You are all set here, continue to other pages to learn more!`,
            position: {
                justifyContent: "center",
                alignItems: "center"
            },
            offset: {
                top: 0,
                left: 0
            },

        }
    ]
}

export const managerTutorialMetadata = {
    seen: false,
    sequence: [
        {
            dialogTitle: "The Series Builder",
            dialogContent: `Awesome you made it to the Series Builder page. Let's explain
                            how this works.`,
            position: {
                justifyContent: "center",
                alignItems: "center"
            },
            offset: {
                top: 0,
                left: 0
            },
        },
        {
            dialogTitle: "Create a New Series",
            dialogContent: `This button quickly clears all the fields on this page and allows you to start building a new series.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Series Thumbnail",
            dialogContent: `Here you can add an image for your series.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Series Name",
            dialogContent: `Each newsletter series needs a great name. You can provide that info in this input box.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Category",
            dialogContent: `Specifying a category will help users determine what kind of content
                            you are delivering.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Frquency",
            dialogContent: `The frequency input tells us how often Pikkaboo needs to send out your newsletter.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Date and Time",
            dialogContent: `Schedule your newsletters at a date, time, and timezone that works for you and your subscribers.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Description",
            dialogContent: `You can also add a description for your series here.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Schedule Type",
            dialogContent: `Schedule type allows you to specify if you want to schedule templates that you create yourself, or let us automate your series for you (1 credit per template).
            If you choose to automate your series, you only need to provide tags for each section of the newsletter and let us take care of the rest.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start",
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Templates",
            dialogContent: `When you create a template on the Design Studio page it will show up here.
            Here you can select what order you want your templates to be sent out to your subscribers.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"

        },
        {
            dialogTitle: "Series Schedule Queue",
            dialogContent: `The order your templates will be emailed to your subscribers is shown here. Depending on the frequency/time you've selected,
                             we'll pull the first item from this queue and email it out to your subscribers for that schedule.`,
            position: {
                justifyContent: "flex-start",
                alignItems: "flex-start"
            },
            arrowPos: "middle-up"
        },
        {
            dialogTitle: "Congratulations!",
            dialogContent: `That's all for now! We can't wait to see what you create. If you haven't explored
                            the Design Studio page yet, hit the Navigation tab and go create your first template! Remember to save
                            after scheduling your templates :)`,
            position: {
                justifyContent: "center",
                alignItems: "center"
            },
            offset: {
                top: 0,
                left: 0
            },
        }
    ]
}