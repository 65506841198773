import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import LoadingIndicator from './LoadingIndicator';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;  

const PdfViewer = ({ pdfUrl, pdfId }) => {

    const [loading, setLoading] = useState(true)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setLoading(false)
    };

    return (
        <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
                <Box style={{
                    background: "#fff",
                    paddingTop: "4px",
                    alignItems: "center", 
                    justifycontent: "center",
                    display: "flex",
                    flexDirection: "column"  
                }}><LoadingIndicator loading={loading}/></Box> }
            >
            <Page pageNumber={1} renderTextLayer={false} width={260} />
        </Document>
    );
};

export default PdfViewer;