import React from 'react';
import { Grid, Box, Typography, Paper } from "@material-ui/core";
import { ResponsiveContainer, Line, LineChart, XAxis, YAxis, Tooltip, Legend } from "recharts";
import LoadingIndicator from "./LoadingIndicator";
 
const getLines = (lineMap) => {
  return lineMap.map((line, index) => {
    return <Line key={index} type="natural" dataKey={line.key} stroke={line.fill} />;
  });
};

const MetricsGraph = ({ activeColor, lineMap, data, loadingData, title }) => {
  
  const lines = getLines(lineMap);
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={12} style={{ marginBottom: "8px" }}>
            <Box style={{display:"flex", textAlign:"center", flexDirection: "column", height: "340px", boxShadow: '-2px 2px 8px 1px #819EF0', borderRadius: "12px", paddingTop: 12, paddingBottom: "40px"}}>
                <Typography variant="h6" style={{ width: "100%", fontSize: 20, fontWeight: "bold", color: "#000" }}>{title}</Typography>
                {loadingData ? (
                    <Box
                    style={{
                        background: "#fff",
                        paddingTop: "4px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        height: "250px",
                    }}
                    >
                    <LoadingIndicator loading={loadingData} />
                    </Box>
                ) : (
                    <ResponsiveContainer>
                    <LineChart margin={{ top: 24, right: 32, left: 0, bottom: 16 }} data={data} style={{ background: "#fff", borderRadius: "4px" }}>
                        <XAxis dataKey="name" stroke="#333" />
                        <YAxis stroke="#333" />
                        <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} />
                        <Legend layout="vertical" align="right" verticalAlign="middle" />
                        {lines}
                    </LineChart>
                    </ResponsiveContainer>
                )}
              </Box>
      </Grid>
    </Grid>
  );
};

export default MetricsGraph;
