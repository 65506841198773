import * as React from 'react';
import { useState, useEffect } from "react";
import PikkabooGreyScaleTransparent from "../images/pikkaboo-cropped.png"
import styles from "../App.css"

export default function ProgressiveImage({ src, width, height }) {
  const img = new Image()
  const [imgSrc, setSrc] = useState(PikkabooGreyScaleTransparent)
  const [removeLoad, setRemoveLoad] = useState(false)
 
  const onLoad = React.useCallback(() => {
    setSrc(src)
    setRemoveLoad(true)
  }, [src])

  useEffect(() => {
      img.crossOrigin = "anonymous";
      img.src = src
      img.addEventListener("load", onLoad)
      return () => {
        img.removeEventListener("load", onLoad)
      }
  }, [src, onLoad])

  let styles = {
    "padding": "50px",
    "color":"grey",
    "display":"inline-block",
    "WebkitMask":"linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%",
    "backgroundRepeat":"no-repeat",
    "animation": !removeLoad && "shimmer 2.5s infinite"
  }
  if(removeLoad) {
      styles = {}
  }

  return (
      <img width={width} crossOrigin="anonymous" height={height} style={styles} src={imgSrc}/>
    )
  }