import { Box, Button } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import WhiteBackground from '../../images/white-image.jpg';

const UploadCroppedImage = ({seriesImageRef, handleUploadClick, imageData, croppedImageData}) => {
    return (
        <Button ref={seriesImageRef} style={{
            marginTop: "20px", 
            borderRadius: "20px",
            }}>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{
                display: "none",
              }}
              onChange={handleUploadClick}
            />
            <label  htmlFor="contained-button-file">
                  { imageData ? 
                        <>
                        {croppedImageData ?
                              <img
                                style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "420px",
                                borderRadius: "20px",
                                boxShadow: '-2px 2px 8px 2px #819EF0',
                                cursor: 'pointer'
                              }}
                              src={croppedImageData}
                              alt="Cropped Image"
                              crossOrigin="anonymous"
                              /> :
                                <img
                                  style={{
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: "420px",
                                  borderRadius: "20px",
                                  boxShadow: '-2px 2px 8px 2px #819EF0',
                                  cursor: 'pointer'
                                }}
                                src={imageData}
                                alt="Cropped Image"
                                crossOrigin="anonymous"
                                />
                            }
                      </>

                    : <Box style={{ position: "relative", maxWidth: "420px" }}>
                        <img
                          style={{
                            maxWidth: "100%",
                            borderRadius: "20px",
                            boxShadow: "-2px 2px 8px 2px #819EF0",
                            cursor: 'pointer'
                          }}
                          src={WhiteBackground}
                          alt="Image"
                        />
                        <AddAPhotoIcon
                          style={{
                            color: "#819EF0",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            cursor: 'pointer',
                            transform: "translate(-50%, -50%)",
                            fontSize: "60px",
                            zIndex: 10,
                          }}
                        />
                      </Box>
                  }
            </label>
            </Button>
    );
  };

export default UploadCroppedImage