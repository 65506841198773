import { useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export default function SearchWidget({onClickSearch, onClickClear}) {
    const [searchTerm, setSearchTerm] = useState("")

    const onChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value)
    }

    const onKeyPress = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            onClickSearch(searchTerm)
        } 
     }

    const onClear = () => {
        setSearchTerm("")
        onClickClear()
    }

    const onSearch = () => {
        onClickSearch(searchTerm)
    }

    return (
        <Paper
        component="form"
        sx={{ height: "58px", boxShadow: '0px 0px 12px 2px #819EF0', borderRadius: "20px", p: '2px 4px', display: 'flex', alignItems: 'center', width: "75%", maxWidth: "400px" }}
        >
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Newsletter Series"
            value={searchTerm}
            onChange={onChangeSearchTerm}
            onKeyDown={onKeyPress}
        />
        {searchTerm.length > 0 && 
        <>
            <IconButton onClick={onClear} type="button" sx={{ color: "#000", p: '10px' }} aria-label="search">
                <ClearIcon />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />
        </>
        }
        <IconButton onClick={onSearch} type="button" sx={{ color: "#000", p: '10px' }} aria-label="search">
            <SearchIcon />
        </IconButton>
        </Paper>
    );
}
