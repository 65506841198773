import { Grid, Box, Card, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SquareSpace1 from '../../images/tutorials/squarespace/square-space-1.png';
import SquareSpace2 from '../../images/tutorials/squarespace/square-space-2.png';
import SquareSpace3 from '../../images/tutorials/squarespace/square-space-3.png';
import SquareSpace4 from '../../images/tutorials/squarespace/square-space-4.png';
import SquareSpace5 from '../../images/tutorials/squarespace/square-space-5.png';
import SquareSpace6 from '../../images/tutorials/squarespace/square-space-6.png';
import SquareSpace7 from '../../images/tutorials/squarespace/square-space-7.png';
import SquareSpace8 from '../../images/tutorials/squarespace/square-space-8.png';
import SquareSpace9 from '../../images/tutorials/squarespace/square-space-9.png';
import SquareSpace10 from '../../images/tutorials/squarespace/square-space-10.png';
import SquareSpace11 from '../../images/tutorials/squarespace/square-space-11.png';
import SquareSpace12 from '../../images/tutorials/squarespace/square-space-12.png';
import SquareSpace13 from '../../images/tutorials/squarespace/square-space-13.png';
import SquareSpace14 from '../../images/tutorials/squarespace/square-space-14.png';
import SquareSpace15 from '../../images/tutorials/squarespace/square-space-15.png';
import SquareSpace16 from '../../images/tutorials/squarespace/square-space-16.png';
import SquareSpace17 from '../../images/tutorials/squarespace/square-space-17.png';
import SquareSpace18 from '../../images/tutorials/squarespace/square-space-18.png';
import SquareSpace19 from '../../images/tutorials/squarespace/square-space-19.png';
import SquareSpace20 from '../../images/tutorials/squarespace/square-space-20.png';
import SquareSpace21 from '../../images/tutorials/squarespace/square-space-21.png';
import SquareSpace22 from '../../images/tutorials/squarespace/square-space-22.png';
import SquareSpace23 from '../../images/tutorials/squarespace/square-space-23.png';
import Footer from '../Footer';
import SEO from '../SEO';
import { connect } from "react-redux";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "75%", 
      justifyContent: "center",
      alignItems: "center",
      "&:hover": { 
          borderColor: "#000",
          transform: "scale3d(1, 1, 1)"
      },
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#819EF0",
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "4px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

export const CodeTutorial = ({ user, isLoggedIn, appBarHeight, dispatch}) => {
    const classes = useStyles()
    const seriesCard = classes.cardSelect
    const url = "https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/subscribe-series-public";

    return (
        <Grid container style={{ overflowX: "hidden",  display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
            <Grid container style={{background: 'linear-gradient(180deg, #fff 10%, #819EF0 90%)', padding: "0px 48px 24px 48px", backgroundColor: "#fff",flexDirection:"row", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                <Grid item xs={12} style={{marginTop: "48px", display: "flex", justifyContent: "flex-start"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "24px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>Welcome to the Pikkaboo Code Integration API documentation. This API allows you to seamlessly integrate newsletter subscriptions from your applications, enabling you to manage email and newsletter creation and scheduling on mypikkaboo.com. By using this API, you can streamline the process of subscribing users to specific series and enhance the overall experience of your users.</Typography><br/>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{marginTop: "24px", display: "flex", justifyContent: "flex-start"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                        <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "28px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>Endpoint</Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>The base endpoint for Pikkaboo Code Integration is:</Typography><br/>
                    </Box>
                </Grid>
                {getCodeBlock(url)}

                {getTextSection("Subscription", "This endpoint is a POST method")}
                {getCodeBlock("POST /subscribe-series-public")}

                {getTextSection("Authentication", "To access the Pikkaboo Code Integration API, you'll need to include the following header in your requests:")}
                {getCodeBlock("x-api-key: Your unique API key provided by Pikkaboo.")}
                {getTextSectionSingle("You can find you API key on the settings page of your account (Available to Pikkaboo Silver members)")}
                {getImageSection(seriesCard, SquareSpace17, "750px")}

                {getTextSection("Request Parameters", "There are only two request parameters required for this API")}
                {getCodeBlock("email (string, required): The email address of the user you want to subscribe \nseriesId (string, required): The unique identifier of the series to which the user should be subscribed.")}
                {getTextSectionSingle("To find the series id. Go to your series detail page on mypikkaboo.com and grab the last part of the url in the search bar (056193c5-6b0e-46a1-95b8-b9936e708947)")}
                {getImageSection(seriesCard, SquareSpace18, "500px")}

                {getTextSection("Example Request", "Below is a simple Node.js example using the axios library to make a POST request to the Pikkaboo Newsletter Subscription API:")}
                {getCodeBlock(`const axios = require('axios');

const subscribeToPikkaboo = async () => {
  const apiUrl = 'https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/subscribe-series-public';
  const apiKey = 'your-api-key';

  const email = 'user@example.com';
  const seriesId = 'series123';

  try {
    const response = await axios.post(apiUrl, {
      email: email,
      seriesId: seriesId,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    });

    console.log('Subscription successful:', response.data.message);
    // Handle success, e.g., log success message or further actions
  } catch (error) {
    console.error('Subscription failed:', error.response.data.message);
    // Handle error, e.g., log error message or implement error handling logic
  }
};`)}
                
                
                {getTextSection("Successful Response", "Json Repsonse:")}
                {getCodeBlock(`{
    "message": "Successfully subscribed userId example@gmail.com to seriesId 056193c5-6b0e-46a1-95b8-b9936e708947 with requestId 5bf71701-dafe-4314-932f-d53bb2ca2628"
}`)}
                {getTextSection("Unsuccessful Response", "Json Repsonse:")}
                {getCodeBlock(`{
    "message": "Forbidden"
}`)}
     

            </Grid>
        
    </Grid>
    )
  }

  const getCodeBlock = (code) => {
    return (
        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start"}}>
            <Box
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                alignItems: "flex-start",
                padding: "0px 12px 0px 12px",
                borderRadius: "10px", // Adjust the border radius as needed
                overflow: "hidden", // Ensures rounded corners are visible
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add a shadow for a card-like effect
              }}
            >
              <SyntaxHighlighter language="javascript" style={docco}>
                {code}
              </SyntaxHighlighter>
            </Box>
        </Grid>
    )
  }

  const getImageSection = (seriesCardClass, imageSrc, maximumWidth) => {
    return (
        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start"}}>
            <Card className={seriesCardClass} style={{borderRadius: "8px", maxWidth: maximumWidth}}>
                <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                    <img src={imageSrc} width="100%" height="100%"   />
                </CardContent>
            </Card>
        </Grid>
    )
  }

  const getTextSection = (stepText, descriptionText) => {
    return (
        <Grid item xs={12} style={{marginTop: "48px", display: "flex", justifyContent: "flex-start"}}>
            <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "28px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>{stepText}</Typography><br/>
                <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>{descriptionText}</Typography><br/>
            </Box>
        </Grid>
    )
  }

  const getTextSectionSingle = (descriptionText) => {
    return (
        <Grid item xs={12} style={{marginTop: "24px", display: "flex", justifyContent: "flex-start"}}>
            <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"flex-start", width: "100%"}}>
                <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", width: "85%", minWidth: "200px",}}>{descriptionText}</Typography><br/>
            </Box>
        </Grid>
    )
  }

  const mapStateToProps = state => {
    return { 
        user: state.user
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CodeTutorial)