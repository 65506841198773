import { useEffect, useState }from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { ButtonBase, Box } from '@material-ui/core';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { connect } from 'react-redux';
import { Login } from './Login';

export const CredentialDrawer = ({dispatch, credentialDrawerState}) => {

  const [drawerState, setDrawerState] = useState(false)

  useEffect(async () => {
    setDrawerState(credentialDrawerState)
  }, [credentialDrawerState]);

  const toggleDrawer = (state) => () => {
    dispatch({
      type: 'SET_CREDENTIAL_DRAWER',
      payload: { 
        credentialDrawerState: state
      }
    })
  };

  return (     
      <SwipeableDrawer
        anchor="bottom"
        open={drawerState}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{style: { boxShadow: "none", backgroundColor: "transparent", display: "flex", justifyContent: "center", alignItems: "center"}}}
      >
        <Box style={{borderRadius: "4px 4px 0 0", flexDirection:"column", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "inline-block", backgroundColor: "#819EF0"}}>
          <ButtonBase onClick={toggleDrawer(false)} style={{width: "100%", height: 56}}>
              <KeyboardDoubleArrowDownIcon style={{ marginTop: "4px"}} />
          </ButtonBase>
          <Login/>
        </Box>
    </SwipeableDrawer>
  );
}

const mapStateToProps = state => {
  return { credentialDrawerState: state.credentialDrawerState }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CredentialDrawer)