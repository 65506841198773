import { useState, useEffect } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Grid } from '@mui/material';
import { TemplateActions } from './TemplateActions';
import { EditorTemplateList } from './EditorTemplateList';
import { Box, ButtonBase } from '@material-ui/core';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {isMobile} from 'react-device-detect';

export function ActionDrawerMyTemplates({
    onSaveClick, 
    onLoadClick, 
    onExportClick, 
    onCopyClick, 
    onDeleteClick,
    onEditClick, 
    loadingSave, 
    loadingLoad, 
    loadingExport, 
    loadingCopy, 
    loadingDelete, 
    loadingEdit, 
    templates, 
    onSelect, 
    loadedTemplate,
    templatePreview,
    loadingTemplatPreview,
    loadingTemplates,
    toggleDrawerMyTemplates,
    openDrawerMyTemplates,
    appBarHeight,
    selectedTemplate
  }) {

    const [drawerWidth, setDrawerWidth] = useState("100vw")

    useEffect(() => {
      if(isMobile) {
        setDrawerWidth(`calc(100vw - 24px)`);
          
      } else {
        setDrawerWidth("50vw" );
      }
  }, [isMobile]);

    const handleOnEditClick = (template) => {
      onEditClick(template)
    }

    return (
          <Grid container style={{backgroundColor: "#819EF0"}}>      
            <SwipeableDrawer
              anchor="right"
              open={openDrawerMyTemplates}
              onClose={() => toggleDrawerMyTemplates(false)}
              onOpen={() => toggleDrawerMyTemplates(true)}
              disableSwipeToOpen={true}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{style: {marginTop: appBarHeight, height: `calc(100vh - ${appBarHeight+86}px)`, overflow: "visible", borderRadius: "12px 0px 0px 12px", }}}
            >
              <Box style={{height: `calc(100vh - ${appBarHeight}px)`, display: "flex", borderRadius: "12px 0px 0px 12px", boxShadow: '-2px 2px 8px 1px #819EF0', backgroundColor: "#fff", overflow:"visible"}}>
                <ButtonBase onClick={() => toggleDrawerMyTemplates(false)} style={{width: 54}}>
                    <DoubleArrowIcon style={{alignItems: "center", fontSize: "42px", width: '100%', marginTop: "4px"}} />
                </ButtonBase>
                <Grid container spacing={0} direction="column" style={{width: `calc(${drawerWidth} - ${32}px)`, padding: "12px 24px 24px 0px"}}>
                  <TemplateActions
                      onSaveClick={onSaveClick} 
                      onLoadClick={onLoadClick} 
                      onExportClick={onExportClick}
                      onCopyClick={onCopyClick} 
                      onDeleteClick={onDeleteClick}
                      onEditClick={handleOnEditClick}
                      loadingSave={loadingSave}
                      loadingLoad={loadingLoad}
                      loadingExport={loadingExport}
                      loadingCopy={loadingCopy}
                      loadingDelete={loadingDelete}
                      loadingEdit={loadingEdit}
                      selectedTemplate={selectedTemplate}
                      templates={templates}
                      showStudioButtons={false}
                  />
                  <Box style={{display: "flex", height: `calc(100vh - ${290}px)`, padding: "12px 0px 0px 16px"}}>
                    <EditorTemplateList 
                      showInputSelector={true}
                      selectedTemplate={selectedTemplate}
                      appBarHeight={appBarHeight}
                      templates={templates} 
                      onSelect={onSelect} 
                      loadedTemplate={loadedTemplate} 
                      templatePreview={templatePreview} 
                      loadingTemplatPreview={loadingTemplatPreview}
                      loadingTemplates={loadingTemplates}
                    />
                  </Box>
                  </Grid> 
              </Box>
          </SwipeableDrawer>
        </Grid>
      );
}