import { Grid, Box, Typography, Paper } from "@material-ui/core";
import LoadingIndicator from "./LoadingIndicator";

export default function SeriesStatsWidget({activeColor, loadingData, title, value, valueColor, icon}) {
	return (
            <Paper elevation={6} style={{backgroundColor: `${activeColor}`, borderRadius: "12px", width: "100%", height: "340px", boxShadow: '-2px 2px 8px 1px #819EF0',}}>
                <Box style={{display: "flex", flexDirection: "column", justifyContent: "flex-end",  height: "100%" }}> 
                        { loadingData ? 
                            <Box style={{
                                background: "#fff",
                                paddingTop: "4px",
                                justifyContent: "center", 
                                alignItems: "center", 
                                display: "flex",
                                flexDirection: "column",
                                height: "100%"  ,
                                borderRadius: 12  
                            }}>
                                <LoadingIndicator loading={loadingData} overrideHeight={"25px"} overrideWidth={"25px"}/> 
                            </Box> : 
                            <Box style={{height: "100%"}}>
                                <Box style={{
                                    background: "transparent",
                                    paddingTop: "4px",
                                    display: "flex",
                                    justifyContent: "center", 
                                    alignItems: "center", 
                                    flexDirection: "column",
                                    height: "50%",
                                    borderRadius: "12px 12px 0px 0px", 
                                }}>
                                    {icon}
                                </Box>
                                <Box style={{
                                    background: "#fff",
                                    paddingTop: "4px",
                                    display: "flex",
                                    justifyContent: "center", 
                                    alignItems: "center", 
                                    flexDirection: "column",
                                    height: "50%",
                                    borderRadius: "0px 0px 12px 12px", 
                                    textAlign: "center"
                                }}>

                                    <Typography style={{padding: "0px 12px 0px 12px", fontSize: "24px", fontWeight: "bold"}}>{title}</Typography>
                                    <Typography variant="h5" style={{marginTop: 8, fontSize: "24px", color: valueColor, fontWeight: "bold"}}>{value}</Typography>
                                </Box>
                            </Box>
                        }
                </Box>
            </Paper>
    )
}