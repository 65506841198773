import { Typography, Button } from "@material-ui/core";
import { Box } from "@mui/system";
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Grid } from "@mui/material";

const Footer = (props) => {

    const navigate = useNavigate()

    const onRedirect = () => {
        navigate('/privacy-policy')
    }
    const { excludeHeight } = props
    return (
        <Box style={{
            display: "flex",
            height: excludeHeight ? null : "100%",
            flexDirection: "column",
            width: "100%",
            postion: "absolute",
            justifyContent: "flex-end"
        }}>
            <Grid style={{
                backgroundColor: "#819EF0",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                padding: "8px"
            }} container> 
                <Grid item style={{justifyContent: "flex-start", display: "flex", width: "50%"}}>
                    <button
                        onClick={onRedirect} 
                        style={{
                            background: "transparent",
                            border: "none",
                            textAlign: "center",
                            alignSelf: "center",
                            fontSize: "16px", 
                            fontWeight: "bold",
                            cursor: "pointer"
                        }}>
                        Privacy Policy
                    </button>
                </Grid>
                <Grid item style={{justifyContent: "flex-end", display: "flex", width: "50%"}}>
                    <a target="_blank" style={{color: "#000"}} href="https://www.instagram.com/pikkaboosocial"><InstagramIcon style={{fontSize:"32px", height: "100%", marginRight: "8px"}}/></a>
                    <a target="_blank" style={{color: "#000"}} href="https://www.linkedin.com/company/pikkaboo-social"><LinkedInIcon style={{fontSize:"32px", height: "100%", marginRight: "8px"}}/></a>
                    <a target="_blank" style={{color: "#000"}} href="https://mobile.twitter.com/PikkabooSocial"><TwitterIcon style={{fontSize:"32px", height: "100%", marginRight: "8px"}}/></a>
                    <a target="_blank" style={{color: "#000"}} href="https://www.youtube.com/@pikkaboo9573"><YouTubeIcon style={{fontSize:"32px", height: "100%", marginRight: "8px"}}/></a>
                </Grid>       
            </Grid>
        </Box>
    ) 
}

export default Footer