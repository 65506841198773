import * as React from 'react';
import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const useStyles = makeStyles((theme) => ({
    notchedOutline: { 
        borderWidth: "2px !important",
        borderColor: '#000 !important' 
    },
    primary: {
        color: "#000 !important"
    },
    secondary: {
        color: "#8AFF8A !important"
    },
  }));

export default function EditDialog({selectedTemplate, openEdit, onEditClick, handleClickClose, templates}) {
  
  const [template, setTemplate] = useState(selectedTemplate)
  const [openError, setOpenError] = useState(false)
  const classes = useStyles()
  const { primary, notchedOutline } = classes
  const onChange = (e) => {
    const newTemplateEntry = {
      ...template,
      templateName: e.target.value
    }
    setTemplate(newTemplateEntry)
  }
  const handleEditClick = () => {
    const index = templates.findIndex(curTemplate => curTemplate.templateName === template.templateName)
    if(index != -1) {
      setOpenError(true)
      return
    }
    handleClickClose()
    onEditClick(template)
 
  }
  useEffect(() => {
    setTemplate(selectedTemplate)
  }, [selectedTemplate]);
  
  const handleErrorClose = () => {
    setOpenError(false);
  };
  return (
    <div>      
      <Dialog open={openEdit} onClose={handleClickClose}>
        <Snackbar
              sx={{ height: "25%" }} 
              anchorOrigin={{ vertical: 'top', horizontal: 'center', }} 
              open={openError}
              onClose={handleErrorClose}
              autoHideDuration={6000}
          >
            <Alert severity="error">Sorry a template with that name already exists, please try a different name.</Alert>
        </Snackbar>
        <DialogTitle className={primary}>Edit Template Name</DialogTitle>
        <DialogContent>
          <TextField
            onChange={onChange}
            value={template && template.templateName}
            autoFocus
            margin="dense"
            id="name"
            label="Template Name"
            fullWidth
            InputProps={{
                classes: {
                    notchedOutline: notchedOutline,
                    input: primary,
                    label: primary
                },
            }}
            InputLabelProps={{
                style: {
                    color:"#000"
                }
            }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button className={primary} onClick={handleClickClose}>Cancel</Button>
          <Button className={primary} onClick={handleEditClick}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}