import {
  Authenticator,
  Flex,
  Grid,
  ThemeProvider
} from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const components = {
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
}};

const theme = {
  name: 'Login Theme',
  tokens: {
    colors: {
      background: {
        primary: {
          value: "#fff",
        },
        secondary: {
          value: "#fff",
        },
      },
      font: {
        interactive: {
          value: "#000",
        },
        hover: {
          value: "#819EF0"
        }
      }
    },
    components: {
      authenticator: {
        container: {
          widthMax: {
              value: "100%"
          }
        },
        modal: {
          height: {
            backgroundColor: "#444"
          }
        }
      },
      tabs: {
        item: {
          _focus: {
            color: {
              value: "#819EF0",
            },
          },
          _hover: {
            color: {
              value: "#819EF0",
            },
          },
          _active: {
            color: {
              value: "#000",
            },
          },
        },
      },
      button: {
        primary: { 
          color: {
            value: "#000"
          },
          backgroundColor: {
            value: "#819EF0"
          },
          _focus: {
            color: {
              value: "#819EF0",
            },
            backgroundColor: {
              value: "#819EF0",
            },
          },
          _hover: {
            color: {
              value: "#819EF0",
            },
            backgroundColor: {
              value: "#000",
            },
          },
          _active: {
            color: {
              value: "#819EF0",
            },
            backgroundColor: {
              value: "#819EF0",
            },
          },
          _loading: {
            color: {
              value: "#819EF0",
            },
            backgroundColor: {
              value: "#000",
            },
          },
          _disabled: {
            color: {
              value: "#819EF0",
            },
            backgroundColor: {
              value: "#819EF0",
            },
          }
        },
      },
    },
  },
};

const formFields = {
  signUp: {
    password: {
      order: 2,
      isRequired: true,
    },
    confirm_password: {
      order: 3,
      isRequired: true,
    },
    username: {
      order: 1,
      placeholder: 'Email',
      isRequired: true,
    },
    preferred_username: {
      order: 4,
      placeholder: 'UserName',
      isRequired: true,
    },
  },
  signIn: {
    username: {
      placeholder: 'Email',
      isRequired: true,
    },
  },
 }

export function Login() {
  return (
    <Grid>
      <Flex
        justifyContent="center"
      >
        <ThemeProvider theme={theme}>
          <Authenticator formFields={formFields} components={components} socialProviders={['google']}>
          </Authenticator>
        </ThemeProvider>
      </Flex>
    </Grid>
  );
}
