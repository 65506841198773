import * as React from 'react';
import { Box, Grid} from "@material-ui/core";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState} from "react"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingIndicator from './LoadingIndicator';
import moment from 'moment-timezone';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';


const useStyles = makeStyles((theme) => ({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important",
            marginRight: "8px"
        }
      },
    colorIconAdd: {
      borderRadius: "8px", 
      color: "#000",
      backgroundColor: ({activeColor})=> activeColor
    },
    colorIconSwap: {
      display:"flex",
      justifyContent: "center",
      alignItems:"center",
      fontSize: "24px",
      borderRadius: "12px",
      color: "#000",
      backgroundColor: ({activeColor})=> activeColor,
      marginRight: "8px"
    },
    container: {
      borderColor: "#F5F5F5",
      borderRadius: "20px",
      boxShadow: '-2px 2px 8px 1px #819EF0',
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        margin: 12
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: ({activeColor})=> activeColor,
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
    }
  }));

  const styles = `
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const getListItems = (isAnimated, activeColor, selectedDate, selectedFrequency, selectedTimeZone, classes, selectedTemplates, swapIndex, hideSwap) => {
    
  const { colorIconAdd, colorIconSwap, root } = classes
    if(selectedTemplates) {
      let date = moment();
      const dateTime = moment(selectedDate);
      const today = moment().set({
        hour: dateTime.hour(),
        minute: dateTime.minute(),
        second: dateTime.second(),
        millisecond: dateTime.millisecond()
      });

      return selectedTemplates.map((template, index) => {
        const { templateName, templateId } = template

        if (selectedFrequency === 'weekly') {
          date = today.clone().add(index+1, 'weeks').day(2); // weekly
        } else if (selectedFrequency === 'monthly') {
          date = today.clone().add(index+1, 'months').date(2); // monthly
        } else {
          date = today.clone().add(index+1, 'days'); // daily
        }  
        let formattedDateTime = date.tz(selectedTimeZone)
        if(formattedDateTime) {
          formattedDateTime = formattedDateTime.format('YYYY/MM/DD HH:mm A');
        }

        return <ListItem
                    key={templateId}
                    style={{
                      padding: "8px 0px 4px 12px",
                      borderRadius: "4px",
                      animation: isAnimated ? 'slideIn 0.5s forwards' : 'none',}}
                >
                    <Icon className={colorIconSwap}>
                      <Typography style={{fontSize: "12px", fontWeight: "bold"}}>{index + 1}</Typography>
                    </Icon>
                    <Box style={{borderRadius: "10px", boxShadow: '-1px 1px 2px 1px #819EF0', borderColor: activeColor, margin: "0px 8px 0px 8px", padding:"8px 0px 0px 8px", display: "flex", flexDirection: "column", width: "100%"}}>
                      <Typography style={{fontWeight: "bold"}}>{`Deliver Date: ${formattedDateTime}`}</Typography>
                      <ListItemText primary={templateName} style={{marginRight: "6px"}}/>
                    </Box>
                    {!hideSwap ? 
                    <Stack direction="column">
                      { index !== 0 &&
                        <IconButton style={{height: "24px", width:"24px"}} onClick={() => swapIndex(index, index - 1)}>
                          <KeyboardArrowUpIcon className={colorIconAdd} style={{height: "24px", width:"24px", marginBottom: "4px"}} />
                        </IconButton>
                      }
                      { index !== selectedTemplates.length - 1 &&
                        <IconButton style={{height: "24px", width:"24px"}} onClick={() => swapIndex(index, index + 1)}>
                          <KeyboardArrowDownIcon className={colorIconAdd} style={{height: "24px", width:"24px", marginTop: "4px"}}/>
                        </IconButton>
                      }
                    </Stack> :
                    null }
                </ListItem>
      })     
    }
}

export function SeriesContentQueue({seriesScheduleQueueRef, selectedDate, selectedFrequency, selectedTimeZone, activeColor, selectedTemplates, onSwapIndex, appBarHeight, loadingSeriesManager, hideSwap}) {
  const classes = useStyles({activeColor})
  const swapIndex = (indexA, indexB) => {
    if(indexB < 0 || indexB > selectedTemplates.length -1) {
      return
    }
    onSwapIndex(indexA, indexB)
  }

  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    setIsAnimated(true);
  }, []);

  const listItems = getListItems(isAnimated, activeColor, selectedDate, selectedFrequency, selectedTimeZone, classes, selectedTemplates, swapIndex, hideSwap)
  return (
    <Box ref={seriesScheduleQueueRef} className={classes.container} style={{marginTop: 16, background: "#fff", height: 300, overflowY: "scroll"}}>
      <style>{styles}</style>
      { loadingSeriesManager ? <LoadingIndicator loading={loadingSeriesManager}/> : 
        <List style={{padding: "4px 4px 8px 0px"}}>
          {selectedTemplates && selectedTemplates.length > 0 ? listItems : 
            <Box style={{
              background: "#fff",
              paddingTop: "4px",
              justifyContent: "center", 
              alignItems: "center", 
              display: "flex",
              flexDirection: "column",
              height: "275px"     
          }}>
              <ScheduleSendIcon sx={{ color: "#819EF0", fontSize: 120 }}/>
              <Typography variant="h5" style={{fontWeight: "bold"}} gutterBottom component="div">
                  No Scheduled Templates
              </Typography>
          </Box>
          }
        </List>
      }
    </Box>
      );
    }