import * as React from 'react';
import { Box, Grid, CardContent, Typography, FormControl, InputLabel } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@aws-amplify/ui-react';
import { ColorExtractor } from 'react-color-extractor'
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import LoadingIndicator from './LoadingIndicator';
import ConstructionIcon from '@mui/icons-material/Construction';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles((theme) => ({
    cardSelect: {
        width: "150px",
        height: "150px",
        transition: "transform 0.15s ease-in-out",
        transform: "scale(0.9)",
        boxShadow: "0 8px 8px rgba(0,0,0,0.25)",
        "&:hover": { 
            borderColor: "#000",
            transform: "scale3d(0.98, 0.98, 0.98)"
        }
    },
    root: {
        "&.Mui-selected": {
            backgroundColor: "#000 !important",
            color: "#fff !important",
            marginRight: "8px"
        }
    },
    container: {
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "4px",
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: ({activeColor})=> activeColor,
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "12px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    },
    formControl: {
        margin: theme.spacing(1),
        width: "100%",
      },
      selectOptions: {
        "& .MuiOutlinedInput-input": {
          backgroundColor: "#fff",
          color: "#000",
        },
        "& .MuiInputLabel-root": {
          color: "#000"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5",
          borderRadius: "20px",
          boxShadow: '-2px 2px 8px 1px #819EF0',
        },
        "&:hover .MuiOutlinedInput-input": {
          color: "#000"
        },
        "&:hover .MuiInputLabel-root": {
          color: "#000"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#000"
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#F5F5F5",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        '& .MuiMenu-paper': {
            borderRadius: '20px',
          },
      },
      menuItem: {
        display: 'flex',
        alignItems: 'center',
      },
      image: {
        height: 64,
        width: 64,
        borderRadius: "14px",
        position: "absolute",
        left: 8
      },
  }));

  const getBuilderItem = (loadingSeriesManager, allSeries) => {
    return ( 
      <MenuItem
      key={"builder item"}
      value={"builder item"}
      style={{ paddingLeft: 0, paddingRight: 0, padding: 12, justifyContent: "center", alignItems: "center", textAlign: "center"}} // Reset padding
      >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "20px",
          textAlign: "center",
          padding: "8px 0px 8px 0px"
      }}
      >
        <div style={{
            height: 66,
            width: 66,
            borderRadius: "20px",
            position: "absolute",
            alignItems: "center",
            display:"block",
            left: 8,
            background: '#819EF0'
            }}>
          { loadingSeriesManager ?
          <RefreshIcon
              crossOrigin="anonymous"
              style={{color: "#fff", height: "100%"}}
          /> : !allSeries || !allSeries.length === 0 ?
          <MarkAsUnreadIcon
              crossOrigin="anonymous"
              style={{color: "#fff", height: "100%"}}
          /> :
          <ConstructionIcon
              crossOrigin="anonymous"
              style={{color: "#fff", height: "100%"}}
          /> 
          }
          </div>
          {
            loadingSeriesManager ?
            <Box style={{ width: "100%", height: "100%", fontSize: "20px"}}><LoadingIndicator overrideHeight={30} overrideWidth={30} loading={loadingSeriesManager}/></Box> : !allSeries || !allSeries.length === 0 ?
            <Typography style={{ width: "100%", height: "100%", fontSize: "20px"}}>{"No Saved Series"}</Typography> :
            <Typography style={{ width: "100%", height: "100%", fontSize: "20px"}}>{"New Series"}</Typography>
          }
        </Box>
    </MenuItem>
    )
    }

    const seriesList = (allSeries, handleSeriesClick, classes, selectedSeries, handleCreateClick, loadingSeriesManager) => {
        if(loadingSeriesManager || !allSeries || !allSeries.length === 0) {
          return (<TextField
                      className={classes.selectOptions}
                      value={"builder item"}
                      fullWidth
                      onChange={handleSeriesClick}
                      select
                      style={{margin: "16px 0px 16px 0px", borderRadius: "20px"}}
                      SelectProps={{
                          MenuProps: { sx: { maxHeight: 250, display: "flex",} }
                      }}>
                        {getBuilderItem(loadingSeriesManager, allSeries)}
              </TextField>)
        }
        
        const seriesList = allSeries.map((seriesItem, index) => {
            const { imageUrl, seriesId, seriesName, backgroundColors } = seriesItem
            const shorthandSeriesName = seriesName.length > 17 ? seriesName.substring(0,17)+ "..." : seriesName
            return (
            <MenuItem
                key={seriesId}
                value={seriesId}
                style={{ paddingLeft: 0, paddingRight: 0, padding: 12, justifyContent: "center", alignItems: "center", textAlign: "center"}} // Reset padding
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "8px 0px 8px 0px"
                    
                  }}
                >
                    <img
                        src={imageUrl}
                        crossOrigin="anonymous"
                        className={classes.image}
                    />
                <Typography style={{ width: "100%", height: "100%", fontSize: "20px"}}>{shorthandSeriesName}</Typography>
              </Box>
            </MenuItem>)
          })
          console.log()
        if(!selectedSeries){
          seriesList.unshift(getBuilderItem(loadingSeriesManager, allSeries))
        }
        return (
            <TextField
                className={classes.selectOptions}
                value={selectedSeries && selectedSeries.seriesId ? selectedSeries.seriesId : "builder item"}
                fullWidth
                onChange={handleSeriesClick}
                select
                style={{margin: "16px 0px 16px 0px", borderRadius: "20px"}}
                SelectProps={{
                     MenuProps: { sx: { maxHeight: 250, display: "flex",} }
                }}
              >
              {seriesList}
            </TextField>
        );
    }

export function SeriesSelector({activeColor, onCreateClick, onSeriesSelect, allSeries, selectedSeries, loadingSeriesManager}) {
    const handleSeriesClick = (e) => {
        const selectedItem = allSeries && allSeries.find((value) => value.seriesId === e.target.value)
        onSeriesSelect(selectedItem)
    };
    const handleCreateClick = () => {
        onCreateClick()
    };
    const classes = useStyles({activeColor})

    const listItems = seriesList(allSeries, handleSeriesClick, classes, selectedSeries, handleCreateClick,loadingSeriesManager )
    return (
        <Box style={{ backgroundColor: "#fff", maxWidth: "420px"}}>
            { 
              listItems
            }
        </Box>
        );
    }