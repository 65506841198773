import { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PikkabooTransparent from '../images/pikkaboo-transparent-blue.png';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { connect } from 'react-redux';
import { styled } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import Footer from './Footer';

const stripe = window.Stripe('pk_live_51LxNcoAGDMeRymuHOy4jU4c26DnverioAja7Hht5OicoFXsWJcKIKUypqnBxiZf6j88I6FVNcMrGeKW2yycEFIiU00euYFTbAi');

const TEST_MONTHLY_PRICE = 'price_1LxOI2AGDMeRymuHFKdWnbKm'
const TEST_YEARLY_PRICE = 'price_1M8emqAGDMeRymuHzPxM9bA4'
const PROD_MONTHLY_PRICE = 'price_1MiuyyAGDMeRymuHVWMJhgh0'
const PROD_YEARLY_PRICE = 'price_1MiuyJAGDMeRymuH44Xm9VYR'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "90%",
        minWidth: 300,
        backgroundColor: 'primary',
    },
    cardSelect: {
        borderRadius: "16px",
        maxWidth: "400px",
        borderColor: "#979797",
        boxShadow: "0 12px 40px -12px rgba(0,0,0,0.3)",
        transition: "transform 0.15s ease-in-out",
        transform: "scale(1)",
        "&:hover": { 
        borderColor: "#000",                   
        boxShadow: "0 16px 50px -12.125px rgba(1,1,1,1.3)",
        },
    },
    cardFocus: {
        borderColor: "#000",
        boxShadow: "0 16px 50px -12.125px rgba(1,1,1,1.3)",
        transform: "scale3d(1, 1, 1)"
    },
    icon: {
        minWidth: '40px',
    },
    listItemText:{
        fontSize:'1em',//Insert your required size
    },
    ribbon: {
        backgroundColor: '#819EF0',
        position: "absolute",
        color: 'white',
        width: 150,
        zIndex: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: 5,
        right:-36, 
        top: 24,
        '&::before': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        '&::after': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        transform: 'rotate(45deg)',
    },
    container: {
        '&::-webkit-scrollbar': {
          width: "12px"
        },
        '&::-webkit-scrollbar-track':{
          borderRadius: "8px",
          marginLeft: "40px",
          marginRight: "40px"
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "#819EF0",
          boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
          borderRadius: "4px",
          border: "3px white solid"
        },
        /* Opera doesn't support this in the shorthand */
        backgroundAttachment: "local, local, scroll, scroll" 
    }
}));

const freeTier = [
    { key: 1, text: "Limited access to Pikkaboo's Create Content tool." },
    { key: 2, text: "Limited access to Pikkaboo's Series Builder tool." },
    { key: 3, text: "No daily daily emails." },
    { key: 4, text: "No access to tips (Get paid support from your subscribers)." },
  ];

const silverTier = [
    { key: 5, text: "No adds." },
    { key: 6, text: "Full access to Pikkaboo's Design Studio tool." },
    { key: 7, text: "Full access to Pikkaboo's Series Builder tool." },
    { key: 8, text: "Full access to Pikkaboo's Campaign Builder tool." },
    { key: 9, text: "Access to Pikkaboo's Generative AI tools." },
    { key: 10, text: "10 free generative email templates a month." },
    { key: 11, text: "10000 daily emails, but can be increased by request." },
    { key: 12, text: "Dashboard and metrics." }
];

const ColorButtonPrimary = styled(LoadingButton)(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#819EF0",
    borderRadius: 12,
    '&:hover': {
      backgroundColor: "#fff",
      color: "#819EF0",
    },
  }));

const ColorButtonSecondary = styled(LoadingButton)(({ theme }) => ({
    color: "#819EF0",
    backgroundColor: "#fff",
    borderRadius: 12,
    '&:hover': {
      backgroundColor: "#819EF0",
      color: "#fff",
    },
  }));  

const ColorToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#fff",
        backgroundColor: '#819EF0'
    }
});

export function Subscriber({appBarHeight, user, isLoggedIn}) {
    const { userId, email, tier, stripeCustomerId } = user
    const classes = useStyles()
    const [alignment, setAlignment] = useState('yearly');
    const [loadingSession, setLoadingSession] = useState(false);
    const navigate = useNavigate()

    useEffect(async () => {
    if(!isLoggedIn) {
        navigate("/explorer")
    }
    }, [isLoggedIn]);

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };

    const openMonthly = async () => {
        setLoadingSession(true)
        try {
            const monthlySession = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/stripe-checkout-session?priceId=${PROD_MONTHLY_PRICE}&userId=${userId}&email=${email}&mode=subscription`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            stripe.redirectToCheckout({
              sessionId: monthlySession.data.sessionId
             })
             setLoadingSession(false)
        } catch(e) {
            console.log(e)
            setLoadingSession(false)
        }
    }

    const openYearly = async () => {
        setLoadingSession(true)
        try {
            const yearlySession = await axios.get(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/stripe-checkout-session?priceId=${PROD_YEARLY_PRICE}&userId=${userId}&email=${email}&mode=subscription`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            stripe.redirectToCheckout({
                sessionId: yearlySession.data.sessionId
            })
            setLoadingSession(false)
        } catch(e) {
            console.log(e)
            setLoadingSession(false)
        }
    }

    const openPortal = () => {
        setLoadingSession(true)
        window.open(`https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/stripe-portal-session?stripeCustomerId=${stripeCustomerId}`,"_self")
        setLoadingSession(false)
    }

    const basicCard = classes.cardSelect
    const premiumCard = classes.cardSelect

    return (
        <Grid
            style={{ 
                height: `calc(100vh - ${appBarHeight}px)`,
                padding: "24px 0 0 0",
                overflowY: "auto",
                overflowX: "hidden",
                justifyContent: "center", 
                alignItems:"center"
            }}
            container 
            direction="row"
            className={classes.container}
            >
            {!stripeCustomerId ?
                <Grid item xs={10} style={{paddingBottom: "24px"}}>
                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        style={{marginBottom: "24px", height: "40px", width:"100%", justifyContent: "center"}}
                        >
                        <ColorToggleButton style={{  boxShadow: '-2px 2px 8px 1px #819EF0', fontSize: "20px", borderRadius: "12px 0px 0px 12px"}} value="monthly">Monthly</ColorToggleButton>
                        <ColorToggleButton style={{  boxShadow: '-2px 2px 8px 1px #819EF0', fontSize: "20px", borderRadius: "0px 12px 12px 0px"}} value="yearly">Yearly</ColorToggleButton>
                    </ToggleButtonGroup>
                    { alignment === "monthly" ? 
                        <Box style={{display: "flex", width: "100%", justifyContent: "center"}}>
                            <Card className={basicCard} style={{ background: "white"}}>
                                <Box className={classes.ribbon}>
                                    <Typography style={{fontSize: 12, fontWight: "bold"}}>
                                        Most Popular
                                    </Typography>
                                </Box>
                                <CardContent>
                                    <Typography variant="h5" color="textPrimary" component="p">
                                        Pikkaboo Silver
                                    </Typography>
                                    <Box style={{marginTop: 12}}>
                                        <Typography display="inline"variant="h4" color="textPrimary" component="p">
                                            $11.99
                                        </Typography>
                                        <Typography style={{marginLeft: 4}} display="inline" variant="body1" color="textPrimary" component="p">
                                            / month
                                        </Typography>
                                    </Box>
                                    <List>
                                        {silverTier.map(f => (
                                        <ListItem key={f.key} style={{paddingLeft: 0, paddingRight: 0}}>
                                            <ListItemIcon className={classes.icon}>
                                                <CheckBoxIcon style={{fill:"#819EF0" }}/>
                                            </ListItemIcon>
                                            <ListItemText classes={{primary: classes.listItemText}} primary={f.text} />
                                        </ListItem>
                                        ))}
                                    </List>
                                    <Box style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                                        <ColorButtonPrimary onClick={openMonthly} loading={loadingSession} variant="contained">Subscribe</ColorButtonPrimary>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box> :
                        <Box style={{display: "flex", width: "100%", justifyContent: "center"}}>
                            <Card  className={premiumCard} style={{ height: "100%", background: '#819EF0'}}>
                                <Box className={classes.ribbon} style={{color: '#819EF0', backgroundColor: "white"}}>
                                    <Typography style={{fontSize: 12, fontWight: "bold"}}>
                                        Best Value
                                    </Typography>
                                </Box>
                                <CardContent>
                                    <Typography variant="h5" color="textPrimary" component="p">
                                        Pikkaboo Silver
                                    </Typography>
                                    <Box style={{marginTop: 12}}>
                                        <Typography display="inline" variant="h4" color="textPrimary" component="p">
                                            $8.99
                                        </Typography>
                                        <Typography style={{marginLeft: 4}} display="inline" variant="body1" color="textPrimary" component="p">
                                            / month
                                        </Typography>
                                    </Box>
                                    <List>
                                        {silverTier.map(f => (
                                        <ListItem key={f.key} style={{paddingLeft: 0, paddingRight: 0}}>
                                            <ListItemIcon className={classes.icon}>
                                                <CheckBoxIcon style={{fill:"#fff" }}/>
                                            </ListItemIcon>
                                            <ListItemText classes={{primary: classes.listItemText}} primary={f.text} />
                                        </ListItem>
                                        ))}
                                    </List>
                                    <Box style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                                        <ColorButtonSecondary onClick={openYearly} loading={loadingSession} variant="contained">Subscribe</ColorButtonSecondary>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    }
                </Grid> :
                <Grid item xs={10} style={{paddingBottom: "24px"}}>
                    <Box style={{display: "flex", width: "100%", justifyContent: "center"}}>
                        <Card className={basicCard} style={{ background: "white"}}>
                            <Box className={classes.ribbon} style={{transform: 'rotate(0deg)', top: 12, right: -48, color: '#819EF0', backgroundColor: "white"}}>
                                <Typography style={{fontSize: 12, fontWight: "bold"}}>
                                    <img src={PikkabooTransparent} width="25px" height="25px" />
                                </Typography>
                            </Box>
                            <CardContent>
                                <Typography variant="h6" color="textPrimary" component="p">
                                    Manage Subscription
                                </Typography>
                                <Box style={{margin: "12px 0 0 0", display: "flex"}}>
                                    <Typography style={{fontWeight: "bold"}} display="inline" variant="body1" color="textPrimary" component="p">
                                    { tier === "FREE" ? 
                                    "You are currently subscribed to Pikkaboo's free tier subscription. Pikkaboo Silver gives you access to all our premium features including:" :
                                    "You are currently subscribed to Pikkaboo's Silver tier subscription. Pikkaboo Silver gives you access to all our premium features including:"
                                    }
                                    </Typography>
                                </Box>
                                <List>
                                    {silverTier.map(f => (
                                        <ListItem key={f.key} style={{paddingLeft: 0, paddingRight: 0}}>
                                            <ListItemIcon className={classes.icon}>
                                                <CheckBoxIcon style={{fill:"#819EF0" }}/>
                                            </ListItemIcon>
                                            <ListItemText classes={{primary: classes.listItemText}} primary={f.text} />
                                        </ListItem>))
                                    }
                                </List>
                                <Box style={{margin: "0px 0 24px 0", display: "flex"}}>
                                    <Typography style={{fontWeight: "bold"}} display="inline" variant="body1" color="textPrimary" component="p">
                                        { tier === "FREE" ? 
                                        "To upgrade to Pikkaboo Silver click the button below." :
                                        "To cancle your subscription click the button below."
                                        }
                                    </Typography>
                                </Box>
                                <Box style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                                    <ColorButtonPrimary onClick={openPortal} loading={loadingSession} variant="contained">Update Subscription</ColorButtonPrimary>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            }
            <Grid container style={{ alignSelf: "flex-end"}}>
                <Footer excludeHeight={true}/>
            </Grid>
        </Grid>
    )
    }

    const mapStateToProps = state => {
        return {}
        }
        
    const mapDispatchToProps = dispatch => {
        return {
            dispatch
        }
    }
    
    export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(Subscriber)