import { Grid, Box, Card, CardContent, Typography, Chip } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Footer';
import SEO from '../SEO';
import { connect } from "react-redux";
import { SquareSpaceTutorial } from "./SquareSpaceTutorial";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TerminalIcon from '@mui/icons-material/Terminal';
import { useState } from "react";
import SquareSpaceIcon from '../../images/tutorials/squarespace/squarespace-logo-png-transparent.png';
import CodeTutorial from "./CodeTutorial";

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "75%", 
      justifyContent: "center",
      alignItems: "center",
      "&:hover": { 
          borderColor: "#000",
          transform: "scale3d(1, 1, 1)"
      },
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
        width: "12px",
        height: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#819EF0",
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
    },
    selectedListItem: {
        backgroundColor: '#819EF0', // Background color for selected item
        color: '#fff', // Text color for selected item
        fontWeight: 'bold', // Bold text for selected item
        // Add any other styles you want for the selected item
      },
    listItem: {
    transition: 'background-color 0.1s', // Smooth transition for background color
        '&:hover': {
            backgroundColor: '#f0f0f0', // Background color on hover
            cursor: 'pointer'
        },
    },
    borderRight: {
        borderRight: '2px solid #ccc', // Right border style
    },
    selectOptions: {
        "& .MuiOutlinedInput-input": {
          backgroundColor: "#fff",
          color: "#000",
          justifyContent: "flex-start", 
          alignItems: "flex-start", 
          textAlign: "flex-start",
        display: "flex"
        },
        "& .MuiInputLabel-root": {
          color: "#000",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5",
          borderRadius: "20px",
          boxShadow: '-2px 2px 8px 1px #819EF0',
        },
        "&:hover .MuiOutlinedInput-input": {
          color: "#000"
        },
        "&:hover .MuiInputLabel-root": {
          color: "#000",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#000"
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#F5F5F5",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F5F5F5"
        },
        '& .MuiMenu-paper': {
            borderRadius: '20px',
          },
          
      },
}));

const integrationsList = (onSelectIntegration, classes, selectedTutorialValue) => {
    const integrations = [{
        text: 'Squarespace (Zapier)',
        icon: SquareSpaceIcon 
    }, {
        text: 'Public API (Code)',
        icon: <TerminalIcon style={{ width: '28px', height: '28px', color: "#000" }}/>
    }]

    const integrationsList = integrations.map((integrationItem, index) => {
        const { text, icon } = integrationItem
        return (
        <MenuItem
            key={text}
            value={text}
            style={{ paddingLeft: 0, paddingRight: 0, padding: 12}} // Reset padding
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "20px",
                textAlign: "center",
                padding: "8px 0px 8px 0px"
                
              }}
            >
                {index === 0 ? (
                    <img src={icon} alt="Squarespace" style={{ marginLeft: 1, width: '24px', height: '24px' }} />
                ) : index % 2 === 1 ? (
                    icon
                ) : (
                    null
                )}
            <Typography style={{ marginLeft: 12, width: "100%", height: "100%", fontSize: "20px"}}>{text}</Typography>
          </Box>
        </MenuItem>)
      })
    return (
        <TextField
            className={classes.selectOptions}
            value={selectedTutorialValue}
            fullWidth
            onChange={onSelectIntegration}
            select
            style={{ margin: "16px 0px 16px 0px", borderRadius: "20px"}}
            SelectProps={{
                 MenuProps: { sx: { maxHeight: 250,} }
            }}
          >
          {integrationsList}
        </TextField>
    );
}

export const IntegrationHome = ({ user, isLoggedIn, appBarHeight, dispatch}) => {
    const classes = useStyles()
    const [selectedTutorial, setSelectedTutorial] = useState('Squarespace (Zapier)')

    const onSelectIntegration = (e) => {
        setSelectedTutorial(e.target.value)
    }

    const integrationsButton = integrationsList(onSelectIntegration, classes, selectedTutorial )

    return (
        <Grid container className={classes.gridList} style={{ right: 0, left: 0, bottom: 0}}>
            <SEO
                title={`Pikkaboo Public API integegrations with Pikkaboo`}
                description={`Effortlessly integrate and accept your subscriptions though third party websites.`}
                name={`Pikkaboo`}
                type={`article`} 
            />
            <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
                {/* <Box style={{width: 350}} className={`${classes.borderRight}`}>
                    <List>
                        {['Squarespace (Zapier)', 'Public API (Code)'].map((text, index) => (
                            <ListItem key={text} disablePadding className={`${classes.listItem} ${
                                index === selectedTutorial ? classes.selectedListItem : ''
                            }`} onClick={() => onSelect(index)}>
                        
                                    <ListItemIcon>
                                        {index === 0 ? (
                                            <img src={SquareSpaceIcon} alt="Squarespace" style={{ marginLeft: 1, width: '24px', height: '24px' }} />
                                        ) : index % 2 === 1 ? (
                                            <TerminalIcon style={{ width: '28px', height: '28px', color: "#000" }}/>
                                        ) : (
                                            null
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                </Box> */}
                <Typography component="div" style={{margin: "48px 48px 24px 48px", fontSize: "32px", fontWeight: "bold"}}>
                    { "Integration Documentation - Subscriptions" }
                </Typography>
                <Box style={{ margin: "0px 48px 0px 48px", backgroundColor: "#fff", maxWidth: "420px"}}>
                    {integrationsButton}
                </Box>
                <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Box>
                        {selectedTutorial === 'Squarespace (Zapier)' ? <SquareSpaceTutorial/> : <CodeTutorial/>}
                    </Box>
                    <Footer excludeHeight={true}/>
                </Box>
            </Box>
    </Grid>
    )
  }

  const mapStateToProps = state => {
    return { 
        user: state.user
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(IntegrationHome)