import { Grid, Box, Card, CardContent, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import PikkabooTransparent from '../images/pikkaboo-transparent.png';
import PikkabooMail from '../images/pikkaboo-mail.png';
import PikkabooDesign from '../images/pikkaboo-design.png';
import PikkabooDashboard from '../images/pikkaboo-dashboard.png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import SEO from './SEO';
import { connect } from "react-redux";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  cardSelect: {
      borderRadius: "12px",
      width: "75%", 
      maxWidth: "450px",
      maxHeight: "450px",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": { 
          borderColor: "#000",
          transform: "scale3d(1, 1, 1)"
      },
      boxShadow: "0 8px 8px rgba(0,0,0,0.25)"
  },
  container: {
      '&::-webkit-scrollbar': {
        width: "12px"
      },
      '&::-webkit-scrollbar-track':{
        borderRadius: "4px",
        marginLeft: "40px",
        marginRight: "40px"
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#819EF0",
        boxShadow: "inset 2px 0 6px rgba(0, 0, 0, 0.3)",
        borderRadius: "4px",
        border: "3px white solid"
      },
      /* Opera doesn't support this in the shorthand */
      backgroundAttachment: "local, local, scroll, scroll" 
  }
}));

export const LandingPage = ({ user, isLoggedIn, appBarHeight, dispatch}) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const [loggedIn, setLoggedIn] = useState(isLoggedIn)

    let styles = {
        "color":"grey",
        "display":"inline-block",
        "WebkitMask":"linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/400% 100%",
        "backgroundRepeat":"no-repeat",
        "animation": "shimmer 4s infinite",
      }

    const onClickNavigate = (path) => {
        navigate(path)
    }

    const onSignInClick = () => {
        if(isLoggedIn) {
            navigate('/explorer')
            return
        }
        dispatch({
            type: 'SET_CREDENTIAL_DRAWER',
            payload: { 
                credentialDrawerState: true
            }
        })
    }

    useEffect(() => {
        // User sucessfully logged in, we should redirect user
        if(loggedIn === false && isLoggedIn) {
            navigate("/explorer")
            setLoggedIn(true)
        }
    }, [isLoggedIn])

    const seriesCard = classes.cardSelect
    return (
        <Grid container style={{ overflowX: "hidden",  display: "flex", flexDirection: "column", right: 0, left: 0, bottom: 0}}>
            <SEO
                title={`Your All-In-One Newsletter Platform for the Creator Economy`}
                description={`Effortlessly find, subscribe, or create beautiful engaging newsletters. Stand out in the crowded creator economy with Pikkaboo's comprehensive newsletter solution.`}
                name={`Pikkaboo`}
                type={`article`} 
            />
            <Chip style={{position: "absolute", right: 0, backgroundColor: "#819EF0", margin: "16px 16px 0px 0px", fontFamily: 'sans-serif', alignSelf: "center", justifyContent: "center", fontSize: "16px", fontWeight: "bold", height: "30px", minWidth: "80px", borderRadius: "24px", padding: "16px 0px 16px 0px"}} label="Sign In" onClick={() => {onSignInClick()}} />
            <Grid container style={{background: 'linear-gradient(180deg, #fff 10%, #819EF0 90%)', marginTop: "72px", backgroundColor: "#fff",flexDirection:"row", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                <img src={PikkabooTransparent} width="50%" style={{...styles, margin: "16px 0px 24px 0px", minWidth: "200px", maxWidth: "350px"}} />
                <Box style={{textAlign: "center", flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                    <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "75%", minWidth: "200px", maxWidth: "500px"}}>The all-in-one newsletter platform for creators, individuals, and businesses alike.</Typography>
                </Box>
                <Grid item xs={12} style={{  textAlign: "center", flexDirection:"row", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                    <Chip style={{backgroundColor: "#819EF0", margin: "24px 0px 0px 0px", fontFamily: 'sans-serif', alignSelf: "center", justifyContent: "center", fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "24px", padding: "16px 0px 16px 0px"}} label="Explore" onClick={() => {onClickNavigate("/explorer")}} />
                </Grid>

                <Grid item xs={12} md={6} style={{marginTop:"62px", display: "flex", justifyContent: "center", width: "100%"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                        <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "36px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>Create Email Templates with Our Design Studio.</Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>Create stunning email templates and connect with your subscribers using Pikkaboo's user-friendly email design studio. Customize templates to fit your brand, access a variety of design elements, and captivate your audience with engaging emails that drive traffic and increase sales. </Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>
                            <Chip style={{backgroundColor: "#fff", alignSelf: "flex-start", fontFamily: 'sans-serif', fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "20px"}} label="Design Studio" onClick={() => {onClickNavigate("/editor")}} />
                        </Typography><br/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{maxWidth: "500px",marginTop:"62px", display: "flex", justifyContent: "center", textAlign: "center"}}>
                    <Card className={seriesCard} style={{borderRadius: "8px"}}>
                        <CardContent style={{ padding: "0px", width: "100%", height: "100%"}}>
                            <img src={PikkabooDesign} width="100%" height="100%"  />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} style={{marginTop:"62px", display: "flex", justifyContent: "center", width: "100%"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                        <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "36px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>Maximize Your Reach and Engagement with Pikkaboo's Newsletter and Marketing Tools.</Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>Unlock your marketing potential and take your business to the next level with Pikkaboo's powerful newsletter and campaign builders. Create personalized newsletters that engage your subscribers and execute targeted campaigns that maximize your reach and drive conversions. </Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>
                            <Chip style={{backgroundColor: "#fff", alignSelf: "flex-start", fontFamily: 'sans-serif', fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "20px"}} label="Newsletter Builder" onClick={() => {onClickNavigate("/series-builder")}} />
                            <Chip style={{marginLeft: "16px", backgroundColor: "#fff", alignSelf: "flex-start", fontFamily: 'sans-serif', fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "20px"}} label="Campaign Builder" onClick={() => {onClickNavigate("/campaign-builder")}} />
                        </Typography><br/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{maxWidth: "500px",marginTop:"62px", display: "flex", justifyContent: "center", textAlign: "center", width: "100%"}}>
                    <Card className={seriesCard} style={{borderRadius: "8px"}}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                            <img src={PikkabooMail} width="100%" height="100%"   />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6} style={{marginTop:"62px", display: "flex", justifyContent: "center", width: "100%"}}>
                    <Box style={{flexDirection:"column", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                        <Typography variant="h3" style={{fontFamily: 'sans-serif',fontSize: "36px", fontWeight: "bold", width: "85%", minWidth: "200px"}}>Track Newsletter Engagement and Subscriber Activity with Pikkaboo's Dashboards.</Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>Whether you're a small business owner or a marketing professional, Pikkaboo's dashboards provide the data-driven insights you need to make informed decisions and achieve your business goals. </Typography><br/>
                        <Typography style={{fontFamily: 'sans-serif',fontSize: "20px", fontWeight: "bold", width: "85%", minWidth: "200px",}}>
                            <Chip style={{backgroundColor: "#fff", alignSelf: "flex-start", fontFamily: 'sans-serif', fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "20px"}} label="Dashboard" onClick={() => {onClickNavigate("/dashboard")}} />
                        </Typography><br/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} style={{maxWidth: "500px",marginTop:"62px", marginBottom:"62px", display: "flex", justifyContent: "center", textAlign: "center", width: "100%"}}>
                    <Card className={seriesCard} style={{borderRadius: "8px"}}>
                        <CardContent style={{padding: "0px", width: "100%", height: "100%"}}>
                            <img src={PikkabooDashboard} width="100%" height="100%"  />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} style={{  textAlign: "center", flexDirection:"row", display:"flex", justifyContent: "center", alignItems:"center", width: "100%"}}>
                    <Chip style={{backgroundColor: "#fff", margin: "24px 0px 0px 0px", fontFamily: 'sans-serif', alignSelf: "center", justifyContent: "center", fontSize: "16px", fontWeight: "bold", height: "40px", minWidth: "100px", borderRadius: "24px", padding: "16px 0px 16px 0px"}} label="Explore" onClick={() => {onClickNavigate("/explorer")}} />
                </Grid>
            </Grid>
            <Footer excludeHeight={true}/>
        
    </Grid>
    )
  }

  const mapStateToProps = state => {
    return { 
        user: state.user
     }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandingPage)