import Home from "./components/Home";
import "./index.css";

import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { connect } from 'react-redux'
import { Box } from "@material-ui/core";
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

export function App(props) {
  useUserStatus(props);
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Home/>
    </HelmetProvider>  
  )
}

/**
 * userUserStatus is a react hook that tracks the user's login status and provides a "isLoggedIn" flag that can be checked in code.
 */

function useUserStatus(props) {
  useEffect(() => {
    const updateUser = async () => {
      try {
        await Auth.currentAuthenticatedUser() //Determine if user logged in, will throw if not
        let userInfo = await Auth.currentUserInfo();
        const { attributes, username } = userInfo
        let apiKey = attributes["custom:ApiKey"]
        let apiKeyId = attributes["custom:ApiKeyId"]
        if(!apiKey) {
          const body = {
            userId: username
          }
          const result = await axios.post('https://0mtgtg4vrg.execute-api.us-east-1.amazonaws.com/prod/create-api-key', body, {
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
              }
          })
          apiKey = result.data.api_key
          apiKeyId = result.data.api_key_id
        }
        props.dispatch({
          type: 'SET_USER',
          payload: { 
            email: attributes.email,
            email_verified: attributes.email_verified,
            userId: username ? username : attributes.sub,
            tier: attributes["custom:Tier"] || "FREE",
            stripeCustomerId: attributes["custom:StripeCustomerId"],
            userName: attributes.preferred_username ? attributes.preferred_username : attributes.name,
            credits: attributes["custom:GenerateCount"],
            api_key: apiKey,
            api_key_id: apiKeyId
          }
        })
        props.dispatch({
          type: 'SET_CREDENTIAL_DRAWER',
          payload: { 
            credentialDrawerState: false
          }
        })
      } catch (error) {
        console.log(error)
        props.dispatch({
          type: 'SET_USER',
          payload: { 
            email: null,
            email_verified: null,
            userId: null,
            tier: null,
            userName: null,
            credits: null,
            api_key: null,
            api_key_id: null
          }
        })
      }
    }

    Hub.listen('auth', updateUser) // listen for login/signup events

   // We are not using async to wait for updateUser, so there will be a flash of page where the user is assumed not to be logged in. If we use a flag 
    updateUser() // check manually the first time because we won't get a Hub event
    return () => {
      Hub.remove('auth', updateUser) // cleanup
    }
  }, []);
}

const mapStateToProps = state => {
  return { user: state.user }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)